import Icon from "@ant-design/icons";

const LogoutIcon = (props: any) => {
  return (
    <Icon
      {...props}
      style={{
        ...props.style,
        height: props.height ?? 24,
        width: props.width ?? 24,
      }}
      component={() => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            fill={props.color ? props.color : "rgb(220, 38, 38)"}
            d="M13.24 2c.47 0 .86.38.86.86v18.29c0 .47-.38.86-.86.86-5.89 0-10-4.11-10-10S7.36 2 13.24 2z"
            opacity=".4"
          ></path>
          <path
            fill={props.color ? props.color : "rgb(220, 38, 38)"}
            d="M20.54 11.54L17.7 8.69a.754.754 0 00-1.06 0c-.29.29-.29.77 0 1.06l1.56 1.56H8.63c-.41 0-.75.34-.75.75s.34.75.75.75h9.57l-1.56 1.56c-.29.29-.29.77 0 1.06.15.15.34.22.53.22s.38-.07.53-.22l2.84-2.85a.73.73 0 000-1.04z"
          ></path>
        </svg>
      )}
    />
  );
};

export default LogoutIcon;
