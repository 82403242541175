import InnerHeader from "../../../components/layouts/InnerHeader";
import { Button, Row, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { colors } from "../../../theme";
import { useForm } from "antd/es/form/Form";
import {
  getFormValue,
  isUploadDone,
} from "../../../components/common/ChunkUpload";
import { ProductCrudApi } from "../../../client/EntityApi";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ProductForm from "../../../components/forms/ProductForm";

const ProductAddPage = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <InnerHeader
        firstPageLink="/"
        secondPageName="Ürünler"
        secondPageLink="/urunler"
        thirdPageName="Ürün Ekle"
      />
      <ProductForm form={form} />
      <div
        style={{
          width: "100%",
          paddingRight: 24,
          height: 70,
          position: "fixed",
          bottom: 0,
          right: 0,
          backgroundColor: colors.white,
          alignContent: "center",
          borderTop: "1px solid #eee",
        }}
      >
        <Row justify="end">
          <Button
            type="primary"
            className="customPrimaryFormButton"
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            loading={loading}
            onClick={() => {
              form.validateFields().then((values) => {
                if (!isUploadDone(values.media)) {
                  message.warning(
                    "Lütfen yüklemelerin tamamlanmasını bekleyin!"
                  );
                  return;
                }

                values.media = getFormValue(values.media);
                setLoading(true);
                ProductCrudApi.create(values)
                  .then(() => {
                    message.success("Ürün oluşturuldu.");
                    navigate("/urunler");
                  })
                  .finally(() => setLoading(false));
              });
            }}
          >
            KAYDET
          </Button>
        </Row>
      </div>
    </>
  );
};

export default ProductAddPage;
