import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import { colors } from "../../theme";
import ChunkUpload, {
  getFormValue,
  getValueFromEvent,
  isUploadDone,
} from "../common/ChunkUpload";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import { XmlApi } from "../../client/Api";
import { required } from "../../helpers/FormRuleHelper";

const { Text } = Typography;

const XmlImportReservation = () => {
  const [mediaForm] = useForm();
  const [matchForm] = useForm();

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  return (
    <Col span={24} style={{ backgroundColor: colors.white, padding: 24 }}>
      <Space direction="vertical">
        <div style={{ display: step === 0 ? "block" : "none" }}>
          <Text style={{ margin: "16px 0px" }}>Adım 1: Dosyayı Seç</Text>
          <Form form={mediaForm}>
            <Form.Item
              name="media"
              valuePropName="fileList"
              getValueFromEvent={getValueFromEvent}
              rules={[required]}
            >
              <ChunkUpload accept=".xml, .XML" style={{ marginBottom: 32 }} />
            </Form.Item>
          </Form>
          <Button
            loading={loading}
            onClick={() => {
              mediaForm.validateFields().then((values) => {
                if (!isUploadDone(values.media)) {
                  message.warning("Yüklemelerin tamamlanmasını bekleyin!");
                  return;
                }
                const mediaIri = getFormValue(values?.media);
                const mediaId = mediaIri[0].replace("/api/media/", "");

                setLoading(true);
                XmlApi.getXmlPattern(mediaId)
                  .then((response) => {
                    setOptions(response.options);
                    matchForm.setFieldValue("mediaId", mediaId);
                    setStep(step + 1);
                  })
                  .finally(() => setLoading(false));
              });
            }}
          >
            Sonraki
          </Button>
        </div>
        <div style={{ display: step === 1 ? "block" : "none" }}>
          <Text style={{ margin: "16px 0px" }}>Adım 2: Alanları Eşle</Text>
          <Form form={matchForm} layout="vertical">
            <Row gutter={[8, 8]}>
              <Form.Item hidden name="mediaId" rules={[required]}>
                <Input />
              </Form.Item>
              <Col span={6}>
                <Form.Item
                  label="Ürün Ana Dizini"
                  name="rootPath"
                  rules={[required]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Ürün Adı Karşılığı"
                  name="name"
                  rules={[required]}
                >
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Stok Kodu Karşılığı"
                  name="stockCode"
                  rules={[required]}
                >
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Kategori Karşılığı" name="category">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Marka Karşılığı" name="brand">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Barkod Karşılığı" name="barcode">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Açıklama Karşılığı" name="description">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Desi Karşılığı" name="desi">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Stok Karşılığı" name="stock">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Alış Fiyatı Karşılığı" name="buyPrice">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Satış Fiyatı Karşılığı" name="price">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Liste Fiyatı Karşılığı" name="listPrice">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="KDV Karşılığı" name="kdv">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Ürün Kapak Resmi" name="thumbnail">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Ürün Resimleri" name="media">
                  <Select showSearch options={options} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Button
            onClick={() => {
              matchForm.validateFields().then((values) => {
                setLoading(true);
                XmlApi.xmlImport(values)
                  .then((response) => {
                    message.success(response.message);
                    setOptions([]);
                    mediaForm.resetFields();
                    matchForm.resetFields();
                    setStep(0);
                  })
                  .finally(() => setLoading(false));
              });
            }}
          >
            İşlemi Başlat
          </Button>
        </div>
      </Space>
    </Col>
  );
};

export default XmlImportReservation;
