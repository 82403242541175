import {
  Button,
  Col,
  Row,
  TableColumnsType,
  Table,
  Typography,
  Input,
  Select,
  Space,
  Image,
} from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import DefaultCard from "../../components/common/DefaultCard";

import CrudTableButton from "../../components/common/crud/CrudTableButton";
import { SearchOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";

import { colors } from "../../theme";

const { Text } = Typography;

interface DataType {
  key: React.Key;
  marketplace: any;
  date: any;
  orderNo: any;
  price: any;
  status: any;
}

const columns: TableColumnsType<DataType> = [
  {
    title: "PAZARYERİ",
    dataIndex: "marketplace",
    render: (marketplace: any) => (
      <div style={{ paddingLeft: marketplace === "dinazors" ? 20 : 10 }}>
        <Image
          preview={false}
          src={`assets/innerPageLogos/${marketplace}Logo.png`}
        />
      </div>
    ),
  },
  {
    title: "MÜŞTERİ VE FATURA TARİHİ",
    dataIndex: "date",
  },
  {
    title: "SİPARİŞ NO",
    dataIndex: "orderNo",
  },
  {
    title: "TUTAR",
    dataIndex: "price",
  },
  {
    title: "DURUM",
    dataIndex: "status",
    render: (status: any) => (
      <Row>
        <Col
          style={{
            padding: 10,
            minWidth: 200,
            borderRadius: 8,
            color: colors.white,
            textAlign: "center",
            backgroundColor:
              status === "ok"
                ? "green"
                : status === "wait"
                ? "orange"
                : status === "cargo"
                ? "orange"
                : "red",
          }}
        >
          {status === "ok"
            ? "Onaylandı"
            : status === "wait"
            ? "Onay Bekliyor"
            : status === "cargo"
            ? "Kargoya Verildi"
            : "İptal Edildi"}
        </Col>
      </Row>
    ),
  },
  {
    title: <div style={{ display: "flex", justifyContent: "end" }}>İŞLEM</div>,
    dataIndex: "",
    render: () => (
      <Space style={{ display: "flex", justifyContent: "end" }}>
        <CrudTableButton type="primary" icon={<EyeOutlined />} />
        <CrudTableButton type="primary" danger icon={<DeleteOutlined />} />
      </Space>
    ),
  },
];

// const data: DataType[] = [
//   {
//     key: "1",
//     marketplace: "dinazors",
//     date: "T*** K*** 31.05.2024",
//     orderNo: "#123456789",
//     price: "123 TL",
//     status: "ok",
//   },
//   {
//     key: "2",
//     marketplace: "dinazors",
//     date: "M*** E*** 30.05.2024",
//     orderNo: "#123456789",
//     price: "250 TL",
//     status: "wait",
//   },
//   {
//     key: "3",
//     marketplace: "trendyol",
//     date: "T*** E*** 29.05.2024",
//     orderNo: "#123456789",
//     price: "320 TL",
//     status: "cargo",
//   },
//   {
//     key: "4",
//     marketplace: "hepsiburada",
//     date: "G*** B*** 29.05.2024",
//     orderNo: "#123456789",
//     price: "412 TL",
//     status: "cancel",
//   },
// ];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record: DataType) => ({
    name: record.marketplace,
  }),
};

const OrderCrudPage = () => {
  // const onChange = (key: string) => {};

  // const items: TabsProps["items"] = [
  //   {
  //     key: "1",
  //     label: "Tüm İşlem Görmemiş Siparişler",
  //     children: <></>,
  //   },
  // ];

  return (
    <>
      <InnerHeader thirdPageName="Siparişler" firstPageLink="/" />
      <DefaultCard style={{ margin: 24 }}>
        {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
        <Row justify="center" gutter={[0, 24]} style={{ paddingTop: 20 }}>
          <Col span={24}>
            <Row gutter={[24, 12]}>
              <Col lg={5} md={8} xs={12}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Text>PLATFORM DURUMU</Text>
                  </Col>
                  <Col span={24}>
                    <Select
                      style={{ width: "100%" }}
                      defaultValue="Tüm Platformlar"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={5} md={8} xs={12}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Text>MÜŞTERİ ADI SOYADI</Text>
                  </Col>
                  <Col span={24}>
                    <Input placeholder="Müşteri Adı" />
                  </Col>
                </Row>
              </Col>
              <Col lg={5} md={8} xs={12}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Text>SİPARİŞ NUMARASI</Text>
                  </Col>
                  <Col span={24}>
                    <Input placeholder="Sipariş Numarası" />
                  </Col>
                </Row>
              </Col>
              <Col lg={5} md={8} xs={12}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Text>SİPARİŞ DURUMU</Text>
                  </Col>
                  <Col span={24}>
                    <Select
                      style={{ width: "100%" }}
                      defaultValue="Tüm Durumlar"
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={4} md={8} xs={12}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Text style={{ color: "transparent" }}>a</Text>
                  </Col>
                  <Col span={24}>
                    <Button
                      type="primary"
                      icon={<SearchOutlined />}
                      style={{ width: "100%" }}
                    >
                      FİLTRELE
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              rowSelection={{
                ...rowSelection,
              }}
              columns={columns}
              dataSource={[]}
              pagination={false}
              scroll={{ x: 1000 }}
            />
          </Col>
        </Row>
      </DefaultCard>
    </>
  );
};

export default OrderCrudPage;
