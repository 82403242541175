import { colors } from "../../theme";
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Typography,
} from "antd";
import { email, max, min, required } from "../../helpers/FormRuleHelper";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

interface IRegisterFormProps {
  loading: boolean;
  form: FormInstance;
  handleRegister: () => void;
}

const RegisterForm = (props: IRegisterFormProps) => {
  return (
    <div style={{ padding: "1.5rem" }}>
      <Row justify="center">
        <Title level={3} style={{ color: colors.primary, marginTop: 0 }}>
          Entekas'a Hoşgeldin
        </Title>
      </Row>
      <Row justify="center">
        <Text style={{ textAlign: "center" }}>
          Ücretsiz hesap oluşturmak için lütfen bilgilerinizi giriniz
        </Text>
      </Row>
      <Divider />
      <Form
        form={props.form}
        style={{ width: "100%", paddingBottom: 20 }}
        layout="vertical"
        size="large"
        autoComplete="off"
      >
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Row gutter={[12, 0]}>
              <Col xs={24}>
                <Form.Item
                  name="fullName"
                  label="YETKİLİ AD SOYAD"
                  rules={[required, min(2), max(255)]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[12, 0]}>
              <Col xs={24}>
                <Form.Item
                  name="email"
                  label="E-POSTA"
                  rules={[required, email, max(255)]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="plainPassword"
              label="ŞİFRE"
              rules={[required, min(2), max(255)]}
            >
              <Input type="password" />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="againPassword"
              label="ŞİFRE (Yeniden)"
              rules={[required, min(2), max(255)]}
            >
              <Input type="password" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          block
          type="primary"
          loading={props.loading}
          onClick={() => props.handleRegister()}
        >
          Hesap Oluştur
        </Button>
      </Form>
      <Row justify="center">
        <Text style={{ textAlign: "center" }}>
          Hesabınız zaten var m? <Link to={"/giris"}>Giriş Yap</Link>
        </Text>
      </Row>
    </div>
  );
};

export default RegisterForm;
