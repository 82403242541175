import { Grid } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const AmazonIntegration = () => {
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);

    // Videoyu yeniden başlat ve sayfa yüklendiğinde otomatik oynat
    const handleReload = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    };

    handleReload(); // Sayfa yüklendiğinde videoyu başlat
    window.addEventListener("load", handleReload); // Sayfa yüklendiğinde videoyu başlat
    return () => window.removeEventListener("load", handleReload); // Temizle
  }, []);

  return (
    <>
      <section
        className={
          screens.xs
            ? "page-banner-area rel z-1 bgs-cover"
            : "page-banner-area py-50 rel z-1 bgs-cover"
        }
      >
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div
              className="col-md-6 col-xs-12"
              style={{
                alignContent: "center",
                paddingTop: screens.xs ? 50 : 0,
              }}
            >
              <div>
                <h6
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{ paddingBottom: 20 }}
                >
                  PAZARYERİ VE E-TİCARET ENTEGRASYONU
                </h6>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{ paddingBottom: 30 }}
                >
                  <b style={{ fontWeight: "lighter", position: "relative" }}>
                    Amazon{" "}
                    <img
                      style={{ position: "absolute", left: 0, top: 36 }}
                      src="/assets/landingPage/bannerBg/bottomLine.png"
                    />
                  </b>{" "}
                  Entegrasyonu
                </h3>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{ display: "flex", paddingBottom: 20 }}
                >
                  <p>
                    <b>Amazon entegrasyonu </b> ile ürün, sipariş, stok, fatura
                    ve kargo süreçlerinizi yönetin.
                  </p>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <a
                    onClick={() => navigate("/kayit-ol")}
                    className="theme-btn"
                    style={{
                      display: screens.xs ? "flex" : "inline-block",
                      justifyContent: screens.xs ? "center" : "start",
                    }}
                  >
                    ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                  </a>
                  <p style={{ fontSize: 10 }}>
                    İlk kayıtta kredi kartına gerek yok. İstediğiniz zaman iptal
                    edebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xs-12"
              style={{ backgroundColor: "transparent" }}
            >
              <video
                ref={videoRef}
                autoPlay
                muted
                style={{ backgroundColor: "transparent" }}
                src="/assets/landingPage/integrators/amazon-entegrasyon.mp4"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Project Details Area Start --> */}
      <section className="project-details-area pt-130 rpt-100 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div
                className="project-details-content pb-30"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    Amazon Entegrasyonu
                  </span>
                  <h2>Ne İşe Yarar?</h2>
                </div>
                <ul className="icon-list mt-20 mb-15">
                  <li>
                    <i className="fas fa-cloud-upload-alt"></i>
                    <p>
                      <b>Otomatik Ürün Yükleme ve Güncelleme: </b>
                      <br />
                      Ürün bilgilerini, stok durumunu ve fiyatları otomatik
                      olarak Amazon'a yükler ve günceller. Bu sayede manuel veri
                      girişine gerek kalmaz ve hatalar minimize edilir.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-tasks"></i>
                    <p>
                      <b>Sipariş Yönetimi: </b> <br /> Amazon üzerinden gelen
                      siparişleri otomatik olarak entegre eder, yönetir ve
                      güncellemeler sağlar. Siparişlerin takibi, işlenmesi ve
                      müşterilere zamanında teslimatı kolaylaşır.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-warehouse"></i>
                    <p>
                      <b>Stok Yönetimi: </b>
                      <br />
                      Farklı platformlardaki stok bilgilerini senkronize eder.
                      Amazon'da stokların tükenmesini veya fazla stoğun
                      birikmesini önler.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-tags"></i>
                    <p>
                      <b>Fiyat Yönetimi: </b>
                      <br /> Amazon'daki fiyatları merkezi bir yerden yönetme
                      imkanı sunar. Fiyat değişikliklerini kolayca uygulayarak
                      rekabetçi kalmanızı sağlar.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-sync-alt"></i>
                    <p>
                      <b>Envanter Senkronizasyonu:</b> <br /> Diğer
                      pazaryerlerinde ve kendi web sitenizde bulunan ürünlerin
                      envanterini Amazon ile senkronize eder. Böylece tüm satış
                      kanallarında tutarlı stok bilgileri sunulur.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-chart-line"></i>
                    <p>
                      <b>Raporlama ve Analiz:</b> <br /> Satış performansını,
                      stok durumunu ve diğer önemli verileri analiz eden
                      raporlar sunar. Bu veriler, iş stratejilerini geliştirmek
                      için kullanılabilir.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-headset"></i>
                    <p>
                      <b>Müşteri Hizmetleri Entegrasyonu:</b> <br /> Amazon
                      müşteri hizmetleri ile entegre çalışarak, müşteri
                      sorularına hızlı ve etkili yanıtlar verilmesini sağlar. Bu
                      sayede müşteri memnuniyeti artırılır.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-undo-alt"></i>
                    <p>
                      <b>İade ve Değişim Yönetimi:</b> <br /> Amazon üzerinden
                      yapılan iade ve değişim süreçlerini yönetir. Bu işlemlerin
                      hızlı ve düzenli bir şekilde yapılmasını sağlar.
                    </p>
                  </li>
                </ul>
                <div className="row">
                  <div className="col-xl-12">
                    <b>
                      Bu entegrasyonlar sayesinde Entekas e-ticaret platformu,
                      Amazon'daki operasyonlarınızı daha verimli ve etkili bir
                      şekilde yönetmenize yardımcı olur.
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="project-details-info rmb-55"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <table>
                  <tr>
                    <td style={{ fontSize: 15, paddingRight: 10 }}>
                      ENTEGRASYON KATEGORİSİ
                    </td>
                    <td style={{ fontSize: 17 }}>
                      <b>Pazaryeri</b>
                    </td>
                  </tr>
                  <hr className="mb-30" style={{ width: "200%" }} />
                  <tr>
                    <td style={{ fontSize: 15, paddingRight: 10 }}>
                      {" "}
                      DESTEKLEDİĞİ PLATFORMLAR
                    </td>
                    <td style={{ fontSize: 17 }}>
                      <b>
                        Dinazors.com, N11, Hepsiburada, Trendyol, Çiçeksepeti
                      </b>
                    </td>
                  </tr>
                  <hr className="mb-30" style={{ width: "200%" }} />
                </table>
                <div className="project-share mt-20">
                  <a
                    onClick={() => navigate("/kayit-ol")}
                    className="theme-btn"
                    style={{ width: "100%" }}
                  >
                    ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Project Details Area End --> */}

      {/* <!-- Features Area Start --> */}
      <section className="about-features-area pt-130 rpt-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-11">
              <div
                className="section-title text-center mb-45"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    Entekas & Amazon Entegrasyonu
                  </span>
                  <h2>Nasıl Çalışır?</h2>
                </div>

                <div className="row justify-content-center">
                  <div className="col-xl-9 col-lg-11"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              // className="col-xl-4 col-md-6"
              className="col-12"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="far fa-cloud-upload"></i>
                  <h5>
                    <a href="">Amazon Ürün Aktarımı</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Amazon’da bulunan ürünlerinizi Entekas’a aktarabilirsiniz.
                    Bu aktarım esnasında hem tekil hem de seçenekli (varyantlı)
                    ürünlerinizin aktarımını gerçekleştirebilirsiniz. Amazon
                    tarafına ürün yüklerken sadece tekil ürün gönderimi
                    yapabilirsiniz.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-truck"></i>
                  <h5>
                    <a href="">Amazon Sipariş Aktarımı</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Amazon’da gelen siparişleriniz birkaç dakika içerisinde
                    Entekas’a aktarılır. Siparişlerinizin yönetimini, kargo fişi
                    çıktılarını ve kargo barkodlarını yazdırabilirsiniz.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="far fa-layer-group"></i>
                  <h5>
                    <a href="">Amazon Stok Aktarımı</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Otomatik stok senkronizasyonu sayesinde tüm satışlarınızın
                    stok değişimi anlık olarak diğer satış kanallarına da
                    aktarılır.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-file-invoice"></i>
                  <h5>
                    <a href="">Amazon Fatura Aktarımı</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Aktarılan siparişlerinizin matbu veya e-fatura süreçlerini
                    birkaç tık ile Entekas üzerinden yazdırabilirsiniz.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Features Area End --> */}

      {/* <!-- Ekran Görüntüleri Start --> */}
      {/* <section className="related-project-area py-85 rpy-55">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 col-lg-11">
              <div
                className="section-title mb-60"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    Amazon Entegrasyonu
                  </span>
                  <h2>Ekran Görüntüleri</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 item design">
              <div
                className="project-item"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/projects/project-grid7.jpg"
                    alt="Project Grid"
                  />
                </div>
                <div className="content">
                  <h5>
                    <a href="project-details.html">Digital Product Design</a>
                  </h5>
                  <span className="category">Design, Branding</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 item marketing seo">
              <div
                className="project-item"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/projects/project-grid2.jpg"
                    alt="Project Grid"
                  />
                </div>
                <div className="content">
                  <h5>
                    <a href="project-details.html">Website Development</a>
                  </h5>
                  <span className="category">Design, Branding</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 item seo design">
              <div
                className="project-item"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/projects/project-grid9.jpg"
                    alt="Project Grid"
                  />
                </div>
                <div className="content">
                  <h5>
                    <a href="project-details.html">Digital Product Design</a>
                  </h5>
                  <span className="category">Design, Branding</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Ekran Görüntüleri End --> */}

      {/* <!-- About Area Five Start --> */}
      <section className="about-area-five pt-80 pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-5">
              <div
                className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/integrators/entegrasyon-1.png"
                  alt="About"
                />
                <img
                  src="/assets/landingPage/integrators/amazon-entegrasyon.png"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket"></i> Amazon Entegrasyonunun
                  </span>
                  <h2>Avantajları Nelerdir?</h2>
                </div>
                <p>
                  Entekas ile Amazon entegrasyonu, e-ticaret operasyonlarınızı
                  daha verimli, etkili ve rekabetçi hale getirerek, işinizi bir
                  üst seviyeye taşımanızı sağlar.
                </p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-clock"></i> Zaman Tasarrufu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas, Amazon üzerindeki ürün yükleme, güncelleme ve
                    sipariş yönetimi gibi süreçleri otomatikleştirir. Bu sayede
                    manuel işlemlerle zaman kaybetmeden, işlerinizi daha hızlı
                    ve verimli bir şekilde yönetebilirsiniz.
                  </li>
                  <li>
                    <i className="fas fa-exclamation-triangle"></i> Hata Azaltma
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Manuel veri girişinden kaynaklanan hataları en aza indirir.
                    Ürün bilgileri, stok durumları ve fiyatlar otomatik olarak
                    senkronize edildiği için insan hatası olasılığı düşer.
                  </li>
                  <li>
                    <i className="fas fa-sync-alt"></i> Gerçek Zamanlı Stok ve
                    Fiyat Güncellemeleri
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Stok seviyeleri ve fiyatlar gerçek zamanlı olarak
                    güncellenir. Bu sayede hem Amazon'da hem de diğer satış
                    kanallarınızda tutarlı bilgiler sunarak müşteri
                    memnuniyetini artırırsınız.
                  </li>
                  <li>
                    <i className="fas fa-cogs"></i> Merkezi Yönetim
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Tüm pazaryerlerindeki işlemleri tek bir platformdan yönetme
                    imkanı sunar. Bu, iş süreçlerini daha kolay ve etkili bir
                    şekilde takip etmenizi sağlar.
                  </li>
                  <li>
                    <i className="fas fa-chart-line"></i> Satış Performansının
                    Artırılması
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas, satış verilerinizi analiz ederek stratejik kararlar
                    almanıza yardımcı olur. Hangi ürünlerin daha çok
                    satıldığını, hangi fiyatlandırma stratejilerinin işe
                    yaradığını görerek satış performansınızı artırabilirsiniz.
                  </li>
                  <li>
                    <i className="fas fa-smile"></i> Müşteri Memnuniyeti
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Siparişlerin hızlı ve doğru bir şekilde işlenmesi, müşteri
                    sorularına hızlı yanıt verilmesi ve sorunsuz iade/değişim
                    süreçleri, müşteri memnuniyetini önemli ölçüde artırır.
                  </li>
                  <li>
                    <i className="fas fa-balance-scale"></i> Rekabet Avantajı
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Hızlı ve doğru fiyat güncellemeleri, stok yönetimi ve
                    kampanya oluşturma yetenekleri ile rekabet avantajı elde
                    edersiniz. Amazon'da rekabetçi fiyatlarla ve zamanında
                    teslimatlarla öne çıkarsınız.
                  </li>
                  <li>
                    <i className="fas fa-plug"></i> Kolay Entegrasyon
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas, Amazon ile kolay ve hızlı bir entegrasyon süreci
                    sunar. Teknik bilgi gerektirmeden entegrasyonu tamamlayarak
                    hemen kullanmaya başlayabilirsiniz.
                  </li>
                  <li>
                    <i className="fas fa-chart-pie"></i> Raporlama ve Analiz
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Detaylı raporlama ve analiz araçları sayesinde iş
                    performansınızı takip edebilir, stratejik kararlar
                    alabilirsiniz. Bu, işletmenizin büyümesine ve gelişmesine
                    katkı sağlar.
                  </li>
                  <li>
                    <i className="fas fa-cogs"></i> Operasyonel Verimlilik
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Tüm e-ticaret operasyonlarınızı tek bir platformdan
                    yöneterek, operasyonel verimliliğinizi artırırsınız. Daha az
                    kaynakla daha fazla iş yaparak, maliyetleri düşürüp
                    karlılığı artırabilirsiniz.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- About Area End --> */}

      {/* <!-- FeedbackArea Start --> */}
      <section className="feedback-area-five bgc-lighter pt-60 rpt-105 pb-80 rpb-60 rel z-1">
        <div className="container">
          <div
            className="section-title text-center mb-55"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="50"
          ></div>
          <div className="row gap-50">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="management-images float-lg-end my-40"
                style={{ paddingLeft: 0 }}
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/featurePageLogos/toplu-urun-ekleme.png"
                  alt="Management"
                />
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="service-mamagement-content mt-40 rmb-20"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div style={{ display: "flex", paddingBottom: 20 }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      height: screens.xs ? 38 : 47,
                      width: screens.xs ? 40 : 47,
                      padding: screens.xs
                        ? "4px 6px 8px 4px"
                        : "7px 12px 10px 10px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      marginRight: 4,
                    }}
                  >
                    <img src="/assets/innerPageLogos/dinazorsLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/trendyolLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/hepsiburadaLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/n11Logo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/ciceksepetiLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/amazonLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/bizimhesapLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/parasutLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/elogoLogo.png" />
                  </div>
                </div>
                <div className="section-title mb-30">
                  <h2>Toplu Ürün Aktarımı</h2>
                </div>
                <p>
                  Aktarım sihirbazı ile dilediğiniz pazaryerlerine toplu ürün
                  aktarımını başlatabilirsiniz.
                </p>
                <div className="row pt-25">
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="content">
                        <h5 style={{ paddingBottom: 20 }}>
                          Aktarım sihirbazı ile dilediğiniz pazaryerlerine toplu
                          ürün aktarımını başlatabilirsiniz.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="content">
                        <h5 style={{ paddingBottom: 20 }}>
                          Bir kaç kolay adımda tüm ürünlerinizi inceleyip akıllı
                          raporlar ile aktarım süreçlerinizi hızlandırıyoruz.
                        </h5>
                        <a
                          onClick={() => navigate("/kayit-ol")}
                          className="theme-btn"
                        >
                          ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- FeedbackArea End --> */}

      {/* <!-- Team Area Start --> */}
      <section className="team-area pt-120 pb-80 rpb-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div
                className="section-title text-center mb-55"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    Amazon Mağazanızı
                  </span>
                  <h2>Entegre Edebileceğiniz Diğer Platformlar</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#B7D463",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/dinazorsBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/dinazors")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Dinazors</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#ff9900",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/amazonBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                    <div className="social-media">
                      <h5>
                        <a onClick={() => navigate("/entegrasyonlar/amazon")}>
                          Detayları İncele
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="description">
                  <h5>Amazon</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div> */}

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#f26725",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/trendyolBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/trendyol")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Trendyol</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#ff963f",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/hepsiBuradaBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a
                        onClick={() => navigate("/entegrasyonlar/hepsiburada")}
                      >
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Hepsiburada</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#6849c5",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/n11Beyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/n11")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>N11</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#51B449",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/ciceksepetiBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a
                        onClick={() => navigate("/entegrasyonlar/ciceksepeti")}
                      >
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Çiçeksepeti</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#28B29A",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/accountingLogos/bizimHesapBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/bizimhesap")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Bizim Hesap</h5>
                  <span className="designation">Muhasebe</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#fd5c4a",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/accountingLogos/parasutBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/parasut")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Paraşüt</h5>
                  <span className="designation">Muhasebe</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#2B378A",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/eInvoice/eLogoEfaturaBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/elogo")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Logo E-Fatura</h5>
                  <span className="designation">E Fatura</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Team Area End --> */}
    </>
  );
};

export default AmazonIntegration;
