import api from "./AxiosConfig";
import { getCrudApi } from "./CrudApi";

export const ProductCrudApi = getCrudApi("products");
export const CategoryCrudApi = getCrudApi("product-categories");
export const BrandCrudApi = getCrudApi("product-brands");
export const AttributeGroupCrudApi = getCrudApi("attribute-groups");
export const BatchRequestCrudApi = getCrudApi("batch-requests");

export const CategoryApi = {
  searchCategories: (searchKey: string, serviceName: string) => {
    return api
      .get("/search-categories/" + serviceName + "?searchKey=" + searchKey)
      .then((response: any) => {
        return response.data;
      });
  },
  matchCategory: (values: any) => {
    return api.post("/match-category", values).then((response: any) => {
      return response.data;
    });
  },
};

export const BrandApi = {
  searchBrands: (searchKey: string, serviceName: string) => {
    return api
      .get("/search-brands/" + serviceName + "?searchKey=" + searchKey)
      .then((response: any) => {
        return response.data;
      });
  },
  matchBrand: (values: any) => {
    return api.post("/match-brand", values).then((response: any) => {
      return response.data;
    });
  },
};

export const ProductApi = {
  matchProduct: (values: any) => {
    return api.post("/match-product", values).then((response: any) => {
      return response.data;
    });
  },
  fastEdit: (id: number, values: any) => {
    return api
      .put("/products/" + id + "/fast-edit", values)
      .then((response: any) => response.data);
  },
};

export const AttributeApi = {
  searchAttributes: (serviceName: string, categoryId: any) => {
    return api
      .get("/search-attributes/" + serviceName + "/" + categoryId)
      .then((response: any) => {
        return response.data;
      });
  },
  matchAttribute: (values: any) => {
    return api.post("/match-attribute", values).then((response: any) => {
      return response.data;
    });
  },
};
