import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../helpers/AuthHelper";
import { Grid } from "antd";

interface ILandingLayoutProps {
  children?: any;
}
const { useBreakpoint } = Grid;

const LandingLayout = (props: ILandingLayoutProps) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  return (
    <div className="home-five">
      <div className="page-wrapper">
        {/* <!-- Preloader --> */}
        {/* <div className="preloader">
          <div className="custom-loader"></div>
        </div> */}

        {/* <!-- main header --> */}
        <header className="main-header no-border">
          {/* <!--Header-Top--> */}
          <div className="header-top-wrap bgc-black">
            <div className="container container-1520">
              <div className="header-top">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-5">
                    <div className="top-left text-center text-lg-start">
                      <ul>
                        <li>
                          <i className="flaticon-star"></i>{" "}
                          <span>
                            Entekas'a Hoşgeldiniz, Herhangi Bir Sorunuz Mu Var?
                          </span>{" "}
                          <a onClick={() => navigate("/iletisim")}>İletişim</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-7">
                    <div className="top-right text-center text-lg-end">
                      <ul>
                        <li>
                          <i className="fas fa-envelope"></i>{" "}
                          <a href="mailto:support@gmail.com">
                            info@entekas.com
                          </a>
                        </li>
                        <li>
                          <i className="fas fa-phone"></i>{" "}
                          <a href="callto:+000(123)45688">+00 (123) 456 78</a>
                        </li>
                        {/* <li>
                          <select name="language" id="language">
                            <option value="English">Türkçe</option>
                            <option value="Bengali">Bengali</option>
                            <option value="Arabic">Arabic</option>
                          </select>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Start Header-Upper--> */}
          <div className="header-upper">
            <div className="container container-1520 clearfix">
              <div className="header-inner py-20 rel d-flex align-items-center">
                <div className="logo-outer">
                  <div className="logo">
                    <a onClick={() => navigate("/")}>
                      <img
                        src="/assets/landingPage/images/logos/logo5.png"
                        alt="Logo"
                        title="Logo"
                      />
                    </a>
                  </div>
                </div>

                <div className="nav-outer ms-lg-auto clearfix">
                  {/* <!-- Main Menu --> */}
                  <nav className="main-menu navbar-expand-lg">
                    <div className="navbar-header py-10">
                      <div className="mobile-logo">
                        <a onClick={() => navigate("/")}>
                          <img
                            src="/assets/landingPage/images/logos/logo5.png"
                            alt="Logo"
                            title="Logo"
                          />
                        </a>
                      </div>

                      {/* <!-- Toggle Button --> */}
                      <button
                        type="button"
                        className="navbar-toggle"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse"
                      >
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                      </button>
                    </div>

                    <div className="navbar-collapse collapse clearfix">
                      <ul className="navigation clearfix">
                        {/* <li className="home">
                          <a href="#">Ana Sayfa</a>
                        </li> */}

                        <li className="dropdown">
                          <a onClick={() => navigate("/ozellikler")}>
                            Özellikler
                          </a>
                          <ul>
                            <li>
                              <a
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                }}
                                onClick={() =>
                                  navigate("/ozellikler/akilli-urun-katalogu")
                                }
                              >
                                <i
                                  className="flaticon-online-registration"
                                  style={{ fontSize: 18, paddingRight: 10 }}
                                />
                                Akıllı Ürün Kataloğu
                              </a>
                            </li>
                            <li>
                              <a
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                }}
                                onClick={() =>
                                  navigate("ozellikler/siparis-yonetimi")
                                }
                              >
                                <i
                                  className="fal fa-laptop-code"
                                  style={{ fontSize: 18, paddingRight: 10 }}
                                ></i>
                                Sipariş Yönetimi
                              </a>
                            </li>
                            <li>
                              <a
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                }}
                                onClick={() =>
                                  navigate("ozellikler/stok-yonetimi")
                                }
                              >
                                <i
                                  className="flaticon-layers-1"
                                  style={{ fontSize: 18, paddingRight: 10 }}
                                />
                                Stok Yönetimi
                              </a>
                            </li>

                            <li>
                              <a
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                }}
                                onClick={() =>
                                  navigate("ozellikler/esnek-raporlar")
                                }
                              >
                                <i
                                  className="flaticon-review"
                                  style={{ fontSize: 18, paddingRight: 10 }}
                                />
                                Esnek Raporlar
                              </a>
                            </li>

                            <li>
                              <a
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                }}
                                onClick={() => navigate("/ozellikler")}
                              >
                                <i
                                  className="far fa-arrow-right"
                                  style={{ fontSize: 18, paddingRight: 10 }}
                                ></i>{" "}
                                Tüm Özellikler
                              </a>
                            </li>
                          </ul>
                        </li>
                        {/* <li className="dropdown">
                          <a href="#">Entegrasyonlar</a>
                          <ul>
                            <li>
                              <a href="services.html">Our Services</a>
                            </li>
                            <li>
                              <a href="service-details.html">Service Details</a>
                            </li>
                          </ul>
                        </li> */}
                        <li className="home">
                          <a onClick={() => navigate("/entegrasyonlar")}>
                            Entegrasyonlar
                          </a>
                        </li>
                        {/* <li className="home">
                          <a onClick={() => navigate("/fiyatlandirma")}>
                            Fiyatlandırma
                          </a>
                        </li> */}
                        <li className="home">
                          <a onClick={() => navigate("/hakkimizda")}>
                            Hakkımızda
                          </a>
                        </li>
                        <li className="home">
                          <a onClick={() => navigate("/iletisim")}>İletişim</a>
                        </li>
                        <li className="home">
                          <a onClick={() => navigate("/blog")}>Blog</a>
                        </li>
                        {isLoggedIn() ? (
                          screens.lg ? (
                            <></>
                          ) : (
                            <li className="home">
                              <a
                                onClick={() => navigate("/dashboard")}
                                className="light-btn"
                              >
                                Yönetim Paneli
                              </a>
                            </li>
                          )
                        ) : (
                          <li className="home">
                            <a
                              onClick={() => navigate("/giris")}
                              className="light-btn"
                            >
                              Giriş Yap
                            </a>
                          </li>
                        )}

                        {/* <li className="dropdown">
                          <a href="pricing.html">Fiyatlandırma</a>
                          <ul>
                            <li>
                              <a href="shop.html">our Products</a>
                            </li>
                            <li>
                              <a href="product-details.html">Product Details</a>
                            </li>
                            <li>
                              <a href="cart.html">Shopping Cart</a>
                            </li>
                            <li>
                              <a href="checkout.html">Checkout</a>
                            </li>
                          </ul>
                        </li> */}
                        {/* <li className="dropdown">
                          <a href="faqs.html">S.S.S</a>
                          <ul>
                            <li>
                              <a href="projects.html">Project Grid</a>
                            </li>
                            <li>
                              <a href="project-list.html">Project List</a>
                            </li>
                            <li>
                              <a href="project-masonry.html">Project Masonry</a>
                            </li>
                            <li>
                              <a href="project-details.html">Project Details</a>
                            </li>
                          </ul>
                        </li> */}

                        {/* <li className="dropdown">
                          <a href="#">blog</a>
                          <ul>
                            <li>
                              <a href="blog.html">blog standard</a>
                            </li>
                            <li>
                              <a href="blog-details.html">blog details</a>
                            </li>
                          </ul>
                        </li> */}
                      </ul>
                    </div>
                  </nav>
                  {/* <!-- Main Menu End--> */}
                </div>

                {/* <!-- Nav Search --> */}
                {/* <div className="nav-search ms-xl-2 ms-4 me-lg-auto py-10">
                  <button className="far fa-search"></button>
                  <form action="#" className="hide">
                    <input
                      type="text"
                      placeholder="Search"
                      className="searchbox"
                    />
                    <button
                      type="submit"
                      className="searchbutton far fa-search"
                    ></button>
                  </form>
                </div> */}

                {/* <!-- Menu Button --> */}
                <div className="menu-btns ms-lg-auto">
                  {/* <a onClick={() => navigate("/giris")} className="light-btn">
                    Giriş Yap
                  </a> */}
                  {isLoggedIn() ? (
                    <a
                      onClick={() => navigate("/dashboard")}
                      className="theme-btn"
                    >
                      YÖNETİM PANELİ <i className="far fa-arrow-right"></i>
                    </a>
                  ) : (
                    <a
                      onClick={() => navigate("/kayit-ol")}
                      className="theme-btn"
                    >
                      ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="bg-lines">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          {/* <!--End Header Upper--> */}
        </header>

        <div>{props.children}</div>

        {/* <!-- Footer Top CTA Area Start --> */}
        <section className="footer-top-cta-area">
          <div className="container container-1500">
            <div
              className="footer-top-cta-wrap bgc-primary"
              style={{
                backgroundImage:
                  "url(/assets/landingPage/images/backgrounds/cta-bg.png)",
              }}
            >
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-6">
                  <div
                    className="image rmb-55"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  >
                    <img
                      src="/assets/landingPage/dashboardFooterSS.png"
                      alt="CTA"
                      style={{ borderRadius: 10 }}
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <div
                    className="footer-top-cta-content text-white"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  >
                    <div className="section-title mb-30">
                      <span className="subtitle-one style-three mb-20">
                        <i className="fas fa-rocket-launch"></i> Ücretsiz Sürüm
                      </span>
                      <h2>Ücretsiz Sürümümüzü Denemeye Hazır Mısınız ?</h2>
                    </div>
                    <p>
                      Sizin için hazırladığımız ücretsiz sürümü şimdi deneyin,
                      satışlarınızı nasıl arttıracağınızı keşfedin!
                    </p>
                    <a
                      onClick={() =>
                        isLoggedIn() ? navigate("/") : navigate("/kayit-ol")
                      }
                      className="theme-btn mt-25"
                    >
                      Hemen Başla <i className="far fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Footer Top CTA Area End --> */}

        {/* <!-- footer area start --> */}
        {/* <footer className="main-footer footer-five rel z-1 bgc-black">
          <div className="container">
            <div className="footer-top-newsletter pt-120 rpt-100 mb-120 rmb-100">
              <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-7 mx-xl-3">
                  <div
                    className="section-title mb-40 text-white text-center"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  >
                    <h2>Yeniliklerden Haberdar Olmak İçin Hemen Üye Olun!</h2>
                    <p>
                      Satışlarınızı Yönetmek Artık Daha Kolay, Entekas'la Her
                      Şey Kontrol Altında!
                    </p>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-8">
                  <form
                    className="newsletter-form style-three"
                    action="#"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  >
                    <input type="email" placeholder="E-mail Adresi" />
                    <button type="submit">
                      Hesap Oluştur <i className="far fa-arrow-right"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="footer-bottom py-15">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-6">
                  <div
                    className="copyright-text pt-10 text-lg-start text-center"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  >
                    <p>
                      Copyright @2024, <a href="index.html">Akpager </a> All
                      Rights Reserved
                    </p>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-6">
                  <div
                    className="footer-bottom-menu text-lg-end text-center"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  >
                    <ul>
                      <li>
                        <a href="faqs.html">SSS</a>
                      </li>
                      <li>
                        <a href="about.html">Ayarlar</a>
                      </li>
                      <li>
                        <a href="about.html">Gizlilik</a>
                      </li>
                      <li>
                        <a href="contact.html">İletişim</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <button
                className="scroll-top scroll-to-target"
                data-target="html"
              >
                <span className="far fa-angle-double-up"></span>
              </button>
            </div>
          </div>

          <div className="bg-lines line-white">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </footer> */}

        <footer
          className="main-footer footer-five rel z-1 bgc-black"
          style={{ paddingTop: 100 }}
        >
          {/* <div className="container">
            <div className="footer-newsletter text-white">
              <div className="row align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div
                    className="logo-part"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  >
                    <div className="logo mb-10">
                      <a href="index.html">
                        <img
                          src="assets/images/logos/logo-white.png"
                          alt="Logo"
                        />
                      </a>
                    </div>
                    <p>Digital Business Consulting Agency</p>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7">
                  <div
                    className="form-part"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  >
                    <h5>Subscribe Our Newsletter</h5>
                    <form className="newsletter-form mt-15" action="#">
                      <input type="email" placeholder="Email Address" />
                      <button type="submit">
                        Sign Up <i className="far fa-arrow-right"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div
                  className="footer-widget widget-about"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-offset="50"
                >
                  <h6 className="footer-title">Entekas Hakkında</h6>
                  <p>
                    Entekas olarak, e-ticaretin karmaşık dünyasını
                    sadeleştirerek, işinizi büyütmenize yardımcı olan bulut
                    tabanlı bir pazaryeri entegrasyon platformu sunuyoruz.
                  </p>
                  <div className="social-style-one">
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 order-xl-2">
                <div
                  className="footer-widget widget-contact"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                  data-aos-offset="50"
                >
                  <h6 className="footer-title">İletişim</h6>
                  <ul>
                    <li>
                      <i className="fal fa-map-marker-alt"></i> 55 Main Street,
                      2nd block Melbourne, Australia
                    </li>
                    <li>
                      <i className="fal fa-envelope"></i>{" "}
                      <a href="mailto:support@gmail.com">info@entekas.com</a>
                    </li>
                    <li>
                      <i className="fal fa-phone"></i>{" "}
                      <a href="callto:+0001234455">+00 (123) 456 78</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="row">
                  <div className="col-sm-6 col-6">
                    <div
                      className="footer-widget widget-links"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-duration="1000"
                      data-aos-offset="50"
                    >
                      <h6 className="footer-title">Hızlı Erişim</h6>
                      <ul>
                        <li>
                          <a href="" onClick={() => navigate("/ozellikler")}>
                            Özellikler
                          </a>
                        </li>
                        <li>
                          <a
                            href=""
                            onClick={() => navigate("/entegrasyonlar")}
                          >
                            Entegrasyonlar
                          </a>
                        </li>
                        <li>
                          <a href="" onClick={() => navigate("/hakkimizda")}>
                            Hakkımızda
                          </a>
                        </li>

                        {/* <li>
                          <a href="services.html">Benefits</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-6">
                    <div
                      className="footer-widget widget-links"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                      data-aos-offset="50"
                    >
                      <h6
                        className="footer-title"
                        style={{ color: "transparent" }}
                      >
                        Quick Link
                      </h6>
                      <ul>
                        {/* <li>
                          <a href="" onClick={() => navigate("/fiyatlandirma")}>
                            Fiyatlandırma
                          </a>
                        </li> */}
                        <li>
                          <a href="" onClick={() => navigate("/iletisim")}>
                            İletişim
                          </a>
                        </li>
                        <li>
                          <a href="" onClick={() => navigate("/blog")}>
                            Blog
                          </a>
                        </li>
                        {/* <li>
                          <a href="contact.html">Press Kit</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-sm-4 col-6">
                    <div
                      className="footer-widget widget-links"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      data-aos-duration="1000"
                      data-aos-offset="50"
                    >
                      <h6
                        className="footer-title"
                        style={{ color: "transparent" }}
                      >
                        Company
                      </h6>
                      <ul>
                        <li>
                          <a href="about.html">About</a>
                        </li>
                        <li>
                          <a href="team.html">Team Member</a>
                        </li>
                        <li>
                          <a href="about.html">Reviews</a>
                        </li>
                        <li>
                          <a href="blog.html">Latest News</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="footer-bottom mt-30 py-15">
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-6">
                  <div className="copyright-text pt-10 text-lg-start text-center wow fadeInLeft delay-0-2s">
                    <p>
                      Copyright @2024, <a href="">Entekas </a> Tüm Hakları
                      Saklıdır
                    </p>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-6">
                  <div className="footer-bottom-menu text-lg-end text-center wow fadeInRight delay-0-2s">
                    <ul>
                      {/* <li>
                        <a href="faqs.html">Faqs</a>
                      </li> */}
                      {/* <li>
                        <a href="about.html">Setting</a>
                      </li> */}
                      <li>
                        <a href="">Gizlilik Politikası</a>
                      </li>
                      {/* <li>
                        <a href="contact.html">Contact</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <button
                className="scroll-top scroll-to-target"
                data-target="html"
              >
                <span className="far fa-angle-double-up"></span>
              </button>
            </div>
          </div>
        </footer>
      </div>
      {/* <!--End pagewrapper--> */}
    </div>
  );
};

export default LandingLayout;
