import { Form, Input, Select, Checkbox } from "antd";
import { useState } from "react";
import { required } from "../../helpers/FormRuleHelper";

const matchServiceOptions = [
  {
    label: "Trendyol",
    value: "trendyol",
    matchLabel: "BARKOD BİLGİSİ",
  },
  {
    label: "Dinazors",
    value: "dinazors",
    matchLabel: "ÜRÜN KODU",
  },
  {
    label: "Hepsiburada",
    value: "hepsiburada",
    matchLabel: "HEPSİBURADA SKU BİLGİSİNİ GİRİNİZ",
  },
  {
    label: "N11",
    value: "n11",
    matchLabel: "N11 MAĞAZA ÜRÜN KODU",
  },
  {
    label: "Çiçeksepeti",
    value: "ciceksepeti",
    matchLabel: "TEDARİKÇİ ÜRÜN KODU",
  },
];

interface IProductMatchFormProps {
  form: any;
  serviceName: string;
  setServiceName: (serviceName: any) => void;
}

const ProductMatchForm = (props: IProductMatchFormProps) => {
  const [isVisibleMatchField, setIsVisibleMatchField] = useState(true);

  return (
    <Form
      form={props.form}
      layout="vertical"
      initialValues={{ removeMatch: false }}
      onValuesChange={(_, values) => {
        if (values.removeMatch) {
          values.matchId = null;
          props.form.setFieldsValue(values);
          setIsVisibleMatchField(false);
        } else {
          setIsVisibleMatchField(true);
        }
      }}
    >
      <Form.Item hidden name="id" rules={[required]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Eşitlenecek Servis"
        name="serviceName"
        rules={[required]}
      >
        <Select
          options={matchServiceOptions}
          onSelect={(value) => {
            props.setServiceName(value);
            props.form.resetFields(["matchId"]);
          }}
        />
      </Form.Item>
      <Form.Item name="removeMatch" valuePropName="checked">
        <Checkbox>Eşlemeyi Kaldır</Checkbox>
      </Form.Item>
      {isVisibleMatchField && props.serviceName !== "" && (
        <Form.Item
          label={
            matchServiceOptions.filter(
              (option) => option.value === props.serviceName
            )[0]?.matchLabel
          }
          name="matchId"
          rules={[required]}
        >
          <Input />
        </Form.Item>
      )}
    </Form>
  );
};

export default ProductMatchForm;
