import { Grid } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { useBreakpoint } = Grid;

const AboutPage = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);

  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className="page-banner-area py-250 rpy-120 rel z-1 bgs-cover"
        style={{ backgroundColor: "#eee" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-xs-12"
              style={{ alignContent: "start" }}
            >
              <div>
                <h4>AMACIMIZ</h4>
                <h2
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{ paddingBottom: 30 }}
                >
                  <b style={{ fontWeight: "lighter", position: "relative" }}>
                    E-ticareti
                    <img
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 44,
                        width: "100%",
                      }}
                      src="/assets/landingPage/bannerBg/bottomLine.png"
                    />{" "}
                  </b>
                  herkes için erişilebilir ve kolay kılmak!
                </h2>
                <h3 style={{ fontWeight: "lighter" }}>Bizi Yakından Tanı.</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Work Step Area Five Start --> */}
      <section className="work-step-area-five pt-120 rpt-100 pb-100 rpb-80 rel z-1">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div
                className="about-content-five mt-10 mb-20 rmb-55"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Entekas
                  </span>
                  <h2 style={{ position: "relative" }}>
                    Değerlerimiz{" "}
                    <img
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 50,
                        width: screens.xs ? "60%" : "",
                      }}
                      src="/assets/landingPage/bannerBg/bottomLine.png"
                    />
                  </h2>
                </div>
                <ul className="icon-list mt-40 mb-40">
                  <li>
                    <i className="fal fa-check"></i>
                    Entekas ile e-ticaret yolculuğunuzda yanınızda olmaktan
                    gurur duyuyoruz.
                  </li>
                  <li>
                    <i className="fal fa-check"></i>
                    İşinizi bir sonraki seviyeye taşımak ve rekabetin bir adım
                    önünde olmak için bize katılın.
                  </li>
                </ul>
                <a onClick={() => navigate("/iletisim")} className="theme-btn">
                  Bize Ulaş <i className="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="numbered-boxes"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="numbered-box style-two">
                  <div className="number">1</div>
                  <div className="content">
                    <h5>Yenilikçilik </h5>
                    <p>
                      Sürekli olarak yenilikçi çözümler geliştirerek,
                      müşterilerimize en son teknolojileri sunarız.
                    </p>
                  </div>
                </div>
                <div className="numbered-box style-two">
                  <div className="number">2</div>
                  <div className="content">
                    <h5>Müşteri Memnuniyeti</h5>
                    <p>
                      Müşterilerimizin ihtiyaçlarını ön planda tutar ve onların
                      başarıları için çalışırız.
                    </p>
                  </div>
                </div>
                <div className="numbered-box style-two">
                  <div className="number">3</div>
                  <div className="content">
                    <h5>Güvenilirlik</h5>
                    <p>
                      Güvenilir ve sürdürülebilir çözümler sunarak, uzun vadeli
                      iş ortaklıkları kurarız.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Work Step Area End --> */}

      {/* <!-- Data Analytics Area Start --> */}
      <section className="data-analytics-area pt-30 rpt-30 pb-90 rpb-60">
        <div className="container">
          <div style={{ width: "100%" }}>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="section-title mb-30">
                <h2 style={{ position: "relative" }}>
                  Hakkımızda{" "}
                  <img
                    style={{
                      position: "absolute",
                      left: 0,
                      top: screens.xs ? 40 : 48,
                      width: screens.xs ? "60%" : "",
                    }}
                    src="/assets/landingPage/bannerBg/bottomLine.png"
                  />
                </h2>
              </div>
              <p>
                Entekas olarak, e-ticaretin karmaşık dünyasını sadeleştirerek,
                işinizi büyütmenize yardımcı olan bulut tabanlı bir pazaryeri
                entegrasyon platformu sunuyoruz. Misyonumuz, e-ticaret
                işletmelerine tüm pazaryeri platformlarını tek bir yerden
                yönetme kolaylığı sağlayarak, operasyonel verimliliği artırmak
                ve satışları maksimize etmektir.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="data-analytics-area rpt-30 pb-90 rpb-60">
        <div className="container">
          <div style={{ width: "100%" }}>
            <div
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="section-title mb-30">
                <h2 style={{ position: "relative" }}>
                  Vizyonumuz{" "}
                  <img
                    style={{
                      position: "absolute",
                      left: 0,
                      top: screens.xs ? 40 : 50,
                      width: screens.xs ? "60%" : "",
                    }}
                    src="/assets/landingPage/bannerBg/bottomLine.png"
                  />
                </h2>
              </div>
              <p>
                Dijital ticaretin her geçen gün daha da karmaşık hale geldiği
                günümüzde, işletmelerin ihtiyaç duyduğu tüm entegrasyon
                çözümlerini sunarak, onların iş süreçlerini optimize etmek ve
                büyümelerini desteklemektir.
              </p>

              <a
                onClick={() => navigate("/iletisim")}
                className="theme-btn style-four mt-50"
              >
                Bize Ulaş <i className="far fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Data Analytics Area End --> */}

      {/* <!-- Features Area Start --> */}
      <section className="about-features-area pt-60 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 col-md-11">
              <div
                className="section-title text-center mb-60"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <span className="subtitle-one style-two mb-20">
                  <i className="fas fa-rocket-launch"></i> Neden Entekas
                </span>
                {/* <h4 style={{ width: "100%" }}>
                  Entekas ile e-ticaret yolculuğunuzda yanınızda olmaktan gurur
                  duyuyoruz. İşinizi bir sonraki seviyeye taşımak ve rekabetin
                  bir adım önünde olmak için bize katılın.
                </h4> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="800"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-plug"></i>
                  <h5>
                    <a href="">Kolay Entegrasyon</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Entekas, popüler e-ticaret platformları ve pazaryerleri ile
                    sorunsuz entegrasyon sağlayarak, ürün yönetiminden sipariş
                    takibine kadar tüm süreçleri tek bir platformdan yönetmenizi
                    mümkün kılar.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="800"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-sync-alt"></i>
                  <h5>
                    <a href="">Gerçek Zamanlı Senkronizasyon</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Stok ve fiyat bilgileriniz anlık olarak güncellenir, böylece
                    hatalı veya eksik bilgi paylaşımının önüne geçilir.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="800"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-desktop"></i>
                  <h5>
                    <a href="">Kullanıcı Dostu Arayüz</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Basit ve sezgisel arayüzümüz sayesinde, teknik bilgiye
                    ihtiyaç duymadan tüm işlemlerinizi hızlı ve kolay bir
                    şekilde gerçekleştirebilirsiniz.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="800"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-chart-bar"></i>
                  <h5>
                    <a href="">Güçlü Analiz Araçları</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Satış performansınızı analiz edebilmenizi ve stratejik
                    kararlar alabilmenizi sağlayan kapsamlı raporlama araçları
                    sunarız.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="800"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-headset"></i>
                  <h5>
                    <a href="">7/24 Destek</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Her türlü soru ve sorununuza anında çözüm bulabilmeniz için
                    profesyonel destek ekibimiz her zaman yanınızda.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Features Area End --> */}

      {/* <!-- Team Area Start --> */}
      {/* <section className="team-area rpt-100 pb-85 rpb-55">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div
                className="section-title text-center mb-55"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <h2>Ekibimiz</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/team/member1.png"
                    alt="Team"
                  />
                  <div className="social-media">
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                  </div>
                </div>
                <div className="description">
                  <h5>Jerome C. Ramirez</h5>
                  <span className="designation">CEO & Founder</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/team/member2.png"
                    alt="Team"
                  />
                  <div className="social-media">
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                  </div>
                </div>
                <div className="description">
                  <h5>Reginald F. Richardson</h5>
                  <span className="designation">Senior Manager</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/team/member3.png"
                    alt="Team"
                  />
                  <div className="social-media">
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                  </div>
                </div>
                <div className="description">
                  <h5>Patrick D. Kozlowski</h5>
                  <span className="designation">Web Designer</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/team/member4.png"
                    alt="Team"
                  />
                  <div className="social-media">
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                  </div>
                </div>
                <div className="description">
                  <h5>Patrick M. Piazza</h5>
                  <span className="designation">Junior manager</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Team Area End --> */}

      {/* <!-- Counter Area Start --> */}
      {/* <div className="counter-area-ten pb-100 rpb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="counter-item style-six counter-text-wrap"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <span
                  className="count-text plus"
                  data-speed="3000"
                  data-stop="1253"
                >
                  1253
                </span>
                <hr />
                <span className="counter-title">Entegrasyon</span>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="counter-item style-six counter-text-wrap"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <span
                  className="count-text k-plus"
                  data-speed="3000"
                  data-stop="85"
                >
                  85
                </span>
                <hr />
                <span className="counter-title">Project Complete</span>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="counter-item style-six counter-text-wrap"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <span
                  className="count-text m-plus"
                  data-speed="3000"
                  data-stop="3"
                >
                  3
                </span>
                <hr />
                <span className="counter-title">Aktif Kullanıcı</span>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="counter-item style-six counter-text-wrap"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <span
                  className="count-text percent"
                  data-speed="3000"
                  data-stop="83"
                >
                  83
                </span>
                <hr />
                <span className="counter-title">Müşteri Memnuniyeti</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Counter Area End --> */}

      {/* <!-- Client Logos Area Start --> */}
      {/* <section className="client-logo-area-two pt-130 rpt-100 pb-95 rpb-65 bgc-navyblue">
        <div
          className="section-title text-center text-white mb-40"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-offset="50"
        >
          <h4>I’ve 1253+ Global Clients & lot’s of Project Complete</h4>
        </div>
        <div className="container">
          <div className="row logo-white justify-content-center row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2">
            <div
              className="col"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two1.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two2.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two3.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two4.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two9.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two5.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two10.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two7.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two6.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="1000"
              data-aos-offset="50"
            >
              <div className="client-logo-item style-three">
                <a href="#">
                  <img
                    src="/assets/landingPage/images/client-logos/client-logo-two8.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Client Logos Area End --> */}

      {/* <!-- Testimonials Area Start --> */}
      {/* <section className="testimonials-area py-130 rpy-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 col-md-11">
              <div
                className="section-title text-center mb-60"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <h2>Müşteri Yorumları</h2>
              </div>
            </div>
          </div>
          <div className="testi-slider-nine">
            <div className="testimonial-item">
              <div className="author">
                <div className="image">
                  <img
                    src="/assets/landingPage/images/testimonials/author11.png"
                    alt="Author"
                  />
                </div>
                <div className="title">
                  <b>Kathryn Murphy /</b> System Analyst
                </div>
              </div>
              <div className="author-text">
                Highly recommended! This we product exceeded expectation in
                every way. It has become an essential part of my daily lifes and
                I'm grateful impact
              </div>
              <div className="testi-footer">
                <div className="ratting mt-10">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <span className="text mt-10">
                  <span>4.7/5</span> on Trustpilot
                </span>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="author">
                <div className="image">
                  <img
                    src="/assets/landingPage/images/testimonials/author12.png"
                    alt="Author"
                  />
                </div>
                <div className="title">
                  <b>Cody Fisher /</b> Chief Technology Officer
                </div>
              </div>
              <div className="author-text">
                Prompt delivery & top-notch quality. Impressed with to the speed
                and accuracy. The ways efficiency and speed at which they
                delivered the product is were impressive.
              </div>
              <div className="testi-footer">
                <div className="ratting mt-10">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <span className="text mt-10">
                  <span>4.7/5</span> on Trustpilot
                </span>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="author">
                <div className="image">
                  <img
                    src="/assets/landingPage/images/testimonials/author13.png"
                    alt="Author"
                  />
                </div>
                <div className="title">
                  <b>Devon Lane /</b> Delivery Head
                </div>
              </div>
              <div className="author-text">
                Life-changing experience! I learned so much from their program.
                Participating in their program was a transformative experience
                for me.
              </div>
              <div className="testi-footer">
                <div className="ratting mt-10">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <span className="text mt-10">
                  <span>4.7/5</span> on Trustpilot
                </span>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="author">
                <div className="image">
                  <img
                    src="/assets/landingPage/images/testimonials/author5.png"
                    alt="Author"
                  />
                </div>
                <div className="title">
                  <b>Cody Fisher /</b> Chief Technology Officer
                </div>
              </div>
              <div className="author-text">
                Prompt delivery & top-notch quality. Impressed with to the speed
                and accuracy. The ways efficiency and speed at which they
                delivered the product is were impressive.
              </div>
              <div className="testi-footer">
                <div className="ratting mt-10">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <span className="text mt-10">
                  <span>4.7/5</span> on Trustpilot
                </span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Testimonials Area End --> */}
    </>
  );
};

export default AboutPage;
