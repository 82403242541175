import {Button, Col, Row, Form, Modal, Input, Select, message} from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import {useRef, useState} from "react";
import CrudTable from "../../components/common/crud/CrudTable";
import {AttributeApi, AttributeGroupCrudApi} from "../../client/EntityApi";
import {
    getAttributeCPColumns,
    getAttributeCPExpanded,
} from "../../components/hook/AttributeCPComponents";
import CrudTableHeader from "../../components/common/crud/CrudTableHeader";
import {FileAddOutlined, CloudSyncOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {max, min, required} from "../../helpers/FormRuleHelper";
import CrudTableButton from "../../components/common/crud/CrudTableButton";
import AttributeMatchForm from "../../components/forms/AttributeMatchForm";

const orangeButtonStyle = {
    color: "#FF7C3F",
    border: "1px solid #FF7C3F",
    backgroundColor: "#FFF2EB",
};

const AttributeCrudPage = () => {
    const [addform] = useForm();
    const [editform] = useForm();
    const [matchForm] = useForm();
    const crudTableRef = useRef<any>(null);

    const [search, setSearch] = useState("");
    const [actionLoading, setActionLoading] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [matchModalOpen, setMatchModalOpen] = useState(false);

    const modalCancel = () => {
        setActionLoading(false);
        setAddModalOpen(false);
        setEditModalOpen(false);
        setMatchModalOpen(false);
        addform.resetFields();
        editform.resetFields();
        matchForm.resetFields();
    };

    return (
        <>
            <InnerHeader
                firstPageLink="/"
                secondPageName="Ürünler"
                thirdPageName="Seçenek Listesi"
                thirdPageLink="/secenekler"
            />
            <Row justify="center" style={{padding: "16px 32px 32px 32px"}}>
                <Col span={24}>
                    <CrudTableHeader
                        searchLabel="Seçenek Grubu Arama"
                        searchPlaceholder="Seçenek grubu ismi ile ara..."
                        onSearch={(value) => setSearch(value)}
                    >
                        <Button
                            icon={<FileAddOutlined/>}
                            size="large"
                            onClick={() => setAddModalOpen(true)}
                        >
                            EKLE
                        </Button>
                    </CrudTableHeader>
                </Col>
                <Col span={24}>
                    <CrudTable
                        ref={crudTableRef}
                        api={AttributeGroupCrudApi}
                        searchKey={search}
                        columns={getAttributeCPColumns()}
                        processColumnWidth={270}
                        expandable={{
                            expandedRowRender: (record: any) =>
                                getAttributeCPExpanded(record),
                        }}
                        editProcess={(row) => {
                            row.attributeValuesBackup = row.attributeValues.map(
                                (attributeValue: any) => ({
                                    id: attributeValue.id,
                                    name: attributeValue.name,
                                    label: attributeValue.name,
                                    value: attributeValue.name,
                                })
                            );
                            row.attributeValues = row.attributeValuesBackup.map((val: any) => val.name);
                            editform.setFieldsValue(row);
                            setEditModalOpen(true);
                        }}
                        extraRowProcess={(row, actionLoading) => {
                            return (
                                <>
                                    <CrudTableButton
                                        loading={actionLoading}
                                        tooltipText="Seçenek Eşitle"
                                        icon={<CloudSyncOutlined/>}
                                        style={orangeButtonStyle}
                                        onClick={() => {
                                            setMatchModalOpen(true);
                                            matchForm.setFieldsValue(row);
                                        }}
                                    />
                                </>
                            );
                        }}
                    />
                </Col>
            </Row>
            <Modal
                title="Seçenek Ekle"
                open={addModalOpen}
                confirmLoading={actionLoading}
                onCancel={() => modalCancel()}
                onOk={() => {
                    setActionLoading(true);
                    addform.validateFields().then((values: any) => {
                        values.attributeValues = values.attributeValues.map(
                            (attributeValue: string) => ({
                                name: attributeValue,
                            })
                        );
                        AttributeGroupCrudApi.create(values)
                            .then(() => {
                                modalCancel();
                                crudTableRef.current.refreshData();
                            })
                            .finally(() => setActionLoading(false));
                    });
                }}
            >
                <Form form={addform} layout="vertical">
                    <Form.Item
                        label="Seçenek Grubu"
                        name="name"
                        rules={[required, min(2), max(255)]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Seçenekler"
                        name="attributeValues"
                        rules={[required]}
                    >
                        <Select mode="tags" allowClear open={false}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Seçenek Düzenle"
                open={editModalOpen}
                confirmLoading={actionLoading}
                onCancel={() => modalCancel()}
                onOk={() => {
                    setActionLoading(true);
                    editform.validateFields().then((values: any) => {
                        values.attributeValues = values.attributeValues.map((attributeValue: string) => {
                                return {
                                    id: values.attributeValuesBackup.find(
                                        (attributeValueBackup: any) =>
                                            attributeValueBackup.name === attributeValue
                                    )?.id,
                                    name: attributeValue,
                                };
                            }
                        );
                        AttributeGroupCrudApi.edit(values.id, {
                            id: values.id,
                            name: values.name,
                            attributeValues: values.attributeValues,
                        })
                            .then(() => {
                                modalCancel();
                                crudTableRef.current.refreshData();
                            })
                            .finally(() => setActionLoading(false));
                    });
                }}
            >
                <Form form={editform} layout="vertical">
                    <Form.Item hidden name="id" rules={[required]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item hidden name="attributeValuesBackup" rules={[required]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Seçenek Grubu"
                        name="name"
                        rules={[required, min(2), max(255)]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Seçenekler"
                        name="attributeValues"
                        rules={[required]}
                    >
                        <Select mode="tags" allowClear open={false}/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                style={{minWidth: 300}}
                title="Seçenek Eşitle"
                open={matchModalOpen}
                confirmLoading={actionLoading}
                onCancel={() => modalCancel()}
                onOk={() => {
                    matchForm.validateFields().then((values: any) => {
                        setActionLoading(true);
                        AttributeApi.matchAttribute(values)
                            .then((response) => {
                                modalCancel();
                                crudTableRef.current.refreshData();
                                message.success(response.message);
                            })
                            .finally(() => setActionLoading(false));
                    });
                }}
            >
                <AttributeMatchForm form={matchForm}/>
            </Modal>
        </>
    );
};

export default AttributeCrudPage;
