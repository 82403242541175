import { UploadOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import api from "../../client/AxiosConfig";

export const getValueFromEvent = (e: any) =>
  Array.isArray(e) ? e : e?.fileList;

export const getFormValue = (media?: any) => {
  if (media === undefined) return [];

  return getValueFromEvent(media).map(
    (item: any) => "/api/media/" + (item?.response?.id || item?.id)
  );
};

export const isUploadDone = (media?: any) => {
  return (
    getFormValue(media).filter((item: any) => item === "/api/media/undefined")
      .length === 0
  );
};

export const getChunksFromFile = (file: Blob, chunkSize: number) => {
  let chunks = [];
  if (file.size < chunkSize) {
    chunks.push({
      index: "0",
      file: file.slice(0, file.size),
      isLast: "1",
    });
  } else {
    const fullChunkCount = Math.ceil(file.size / chunkSize);
    const remainderSize = file.size % chunkSize;
    let index = 0;
    for (; index < fullChunkCount; index++) {
      const isLastPart = index === fullChunkCount - 1;
      const startPoint = index * chunkSize;
      const endPoint = startPoint + (isLastPart ? remainderSize : chunkSize);
      const filePart = file.slice(startPoint, endPoint);
      chunks.push({
        index: index.toString(),
        file: filePart,
        isLast: isLastPart ? "1" : "0",
      });
    }
  }

  return chunks;
};

const ChunkUpload = (props: any) => {
  const customRequest = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const chunkSize = process.env.REACT_APP_CHUNK_SIZE
      ? parseInt(process.env.REACT_APP_CHUNK_SIZE)
      : 1048576;
    const chunks = getChunksFromFile(file, chunkSize);
    for (let i = 0; i < chunks.length; i++) {
      let formData = new FormData();
      formData.append("file", chunks[i].file);
      formData.append("index", chunks[i].index);
      formData.append("last", chunks[i].isLast);
      formData.append("uuid", file.uid);
      formData.append("orig", file.name);
      await api
        .post("upload", formData)
        .then((response) => {
          if (props.afterUpload) props.afterUpload(response.status);
          if (response.status === 201) {
            onSuccess(response.data, file);
          } else {
            const percent = Math.floor(
              (chunkSize * 100 * (parseInt(chunks[i].index) + 1)) / file.size
            );
            onProgress({ percent: percent }, file);
          }
        })
        .catch((error) => onError(error, file));
    }
  };

  const uploadProps = {
    customRequest: customRequest,
    accept: props.accept ?? ".pdf , .doc , .docx , .jpeg , .png",
    listType: props.listType ?? "text",
  };

  return (
    <Dragger {...props} {...uploadProps}>
      <p className="ant-upload-drag-icon">{<UploadOutlined />}</p>
      <p className="ant-upload-text">Dosya seçin veya sürükleyin.</p>
    </Dragger>
  );
};

export default ChunkUpload;
