import { Rule } from "antd/es/form";

export const required = { required: true, message: "Boş bırakılamaz!" };

export const email: Rule = {
  type: "email",
  message: "E-posta adresi geçersiz.",
};

export const min = (length: number) => {
  return {
    min: length,
    message: "En az " + length + " karakter olmalıdır!",
  };
};

export const max = (length: number) => {
  return {
    max: length,
    message: "En fazla " + length + " karakter olmalıdır!",
  };
};
