import { Row, Col, Modal, Alert, message, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { formattedDate, getConstantValue } from "../../helpers/UtilHelper";
import { heights } from "../../theme";
import DefaultCard from "../common/DefaultCard";
import ProductTransferCard from "./ProductTransferCard";
import TransferDetailCard from "./TransferDetailCard";
import { GeneralApi, TransferApi, ServiceSettingsApi } from "../../client/Api";
import { useForm } from "antd/es/form/Form";

interface IImportProcessCardProps {
  serviceName: string;
  logo: string;
  importForm: any;
}

const ImportProcessCard = (props: IImportProcessCardProps) => {
  const [importForm] = useForm();
  const [loading, setLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importTracks, setImportTracks] = useState<any[]>([]);
  const [importTrackLogs, setImportTrackLogs] = useState<any[]>([]);
  const [importTrackStatus, setImportTrackStatus] = useState([]);

  const getServiceSettings = useCallback(() => {
    setLoading(true);
    ServiceSettingsApi.getServiceSettings(props.serviceName)
      .then((response) => setImportTracks(response.importProcessTracks ?? []))
      .finally(() => setLoading(false));
  }, [props.serviceName]);

  useEffect(() => {
    getServiceSettings();
    GeneralApi.getConstants().then((response) =>
      setImportTrackStatus(response.importProcessTrackStatus)
    );
  }, [getServiceSettings]);

  const importModalOnOk = () => {
    importForm.validateFields().then((values) => {
      setImportLoading(true);
      TransferApi.importReservation(props.serviceName, values)
        .then((response: any) => {
          message.success(response.message);
          importModalOnCancel();
        })
        .finally(() => setImportLoading(false));
    });
  };

  const importModalOnCancel = () => {
    importForm.resetFields();
    setImportModalOpen(false);
    setImportLoading(false);
  };

  return loading ? (
    <Row justify="center">
      <Spin />
    </Row>
  ) : (
    <Row gutter={[24, 24]} style={{ backgroundColor: "transparent" }}>
      <Col xs={24} md={12}>
        <DefaultCard>
          <ProductTransferCard
            logo={props.logo}
            onClick={() => setImportModalOpen(true)}
            refreshData={getServiceSettings}
          />
          <Modal
            title="Aktarım Kuralları"
            okText="Başlat"
            cancelText="Vazgeç"
            width={600}
            open={importModalOpen}
            confirmLoading={importLoading}
            onOk={() => importModalOnOk()}
            onCancel={importModalOnCancel}
          >
            <props.importForm form={importForm} />
          </Modal>
        </DefaultCard>
        <div
          style={{
            marginTop: 16,
            cursor: "pointer",
            maxHeight:
              "calc(100vh - " +
              (heights.headerHeight + heights.innerHeaderHeight + +297) +
              "px)",
            overflow: "auto",
          }}
        >
          {importTracks?.map((importTrack, index) => (
            <DefaultCard
              key={index}
              style={{ marginTop: 16, cursor: "pointer" }}
              onClick={() => setImportTrackLogs(importTrack.logs)}
            >
              <TransferDetailCard
                matching={importTrack.matching}
                mismatch={importTrack.mismatch}
                transferDate={
                  importTrack.finishDate
                    ? formattedDate(importTrack.finishDate, "DD.MM.YYYY HH:mm")
                    : "-"
                }
                status={getConstantValue(importTrack.status, importTrackStatus)}
              />
            </DefaultCard>
          ))}
        </div>
      </Col>
      <Col xs={24} md={12}>
        <DefaultCard>
          <div
            style={{
              maxHeight:
                "calc(100vh - " +
                (heights.headerHeight + heights.innerHeaderHeight + 160) +
                "px)",
              overflow: "auto",
            }}
          >
            {importTrackLogs.map((importTrackLog) => (
              <Alert
                style={{ marginBottom: 8 }}
                message={importTrackLog.message}
                type={importTrackLog.type}
                showIcon
              />
            ))}
          </div>
        </DefaultCard>
      </Col>
    </Row>
  );
};

export default ImportProcessCard;
