import { Grid } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const TrendyolIntegration = () => {
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [userInteracted, setUserInteracted] = useState(false);

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);

    // Videoyu yeniden başlat ve sayfa yüklendiğinde otomatik oynat
    const handleReload = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
    };

    handleReload(); // Sayfa yüklendiğinde videoyu başlat
    window.addEventListener("load", handleReload); // Sayfa yüklendiğinde videoyu başlat
    return () => window.removeEventListener("load", handleReload); // Temizle
  }, []);

  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className={
          screens.xs
            ? "page-banner-area rel z-1 bgs-cover"
            : "page-banner-area py-50 rel z-1 bgs-cover"
        }
      >
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div
              className="col-md-6 col-xs-12"
              style={{
                alignContent: "center",
                paddingTop: screens.xs ? 50 : 0,
              }}
            >
              <div>
                <h6
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{ paddingBottom: 20 }}
                >
                  PAZARYERİ VE E-TİCARET ENTEGRASYONU
                </h6>
                <h3
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{ paddingBottom: 30 }}
                >
                  <b style={{ fontWeight: "lighter", position: "relative" }}>
                    Trendyol{" "}
                    <img
                      style={{ position: "absolute", left: 0, top: 36 }}
                      src="/assets/landingPage/bannerBg/bottomLine.png"
                    />
                  </b>{" "}
                  Entegrasyonu
                </h3>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{ display: "flex", paddingBottom: 20 }}
                >
                  <p>
                    <b>Trendyol entegrasyonu </b> ile ürün, sipariş, stok,
                    fatura ve kargo süreçlerinizi yönetin.
                  </p>
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <a
                    onClick={() => navigate("/kayit-ol")}
                    className="theme-btn"
                    style={{
                      display: screens.xs ? "flex" : "inline-block",
                      justifyContent: screens.xs ? "center" : "start",
                    }}
                  >
                    ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                  </a>
                  <p style={{ fontSize: 10 }}>
                    İlk kayıtta kredi kartına gerek yok. İstediğiniz zaman iptal
                    edebilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xs-12"
              style={{ backgroundColor: "transparent" }}
            >
              <video
                ref={videoRef}
                autoPlay
                muted
                style={{ backgroundColor: "transparent" }}
                src="/assets/landingPage/integrators/trendyol-entegrasyon.mp4"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Project Details Area Start --> */}
      <section className="project-details-area pt-130 rpt-100 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div
                className="project-details-content pb-30"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    Trendyol Entegrasyonu
                  </span>
                  <h2>Ne İşe Yarar?</h2>
                </div>
                <ul className="icon-list mt-20 mb-15">
                  <li>
                    <i className="fas fa-box"></i>
                    <p>
                      <b>Stok Yönetimi: </b>
                      <br />
                      Entekas, Trendyol mağazanızdaki stok miktarlarını otomatik
                      olarak günceller. Bu sayede, ürünlerinizin stoğu
                      tükendiğinde veya azaldığında anında haberdar olursunuz ve
                      satış kayıplarının önüne geçersiniz.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-edit"></i>
                    <p>
                      <b>Ürün Yönetimi: </b> <br /> Trendyol mağazanızdaki
                      ürünlerin fiyatlarını, açıklamalarını ve görsellerini
                      Entekas üzerinden güncelleyebilirsiniz. Bu sayede, tüm
                      ürün bilgilerinizi merkezi bir yerden yönetmek kolaylaşır.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-chart-pie"></i>
                    <p>
                      <b>Raporlama ve Analiz: </b>
                      <br />
                      Entekas, Trendyol satışlarınıza dair detaylı raporlar
                      sunar. Satış performansınızı, en çok satan ürünleri ve
                      stok durumlarını analiz ederek stratejik kararlar almanıza
                      yardımcı olur.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-file-invoice"></i>
                    <p>
                      <b>Fatura Entegrasyonu: </b>
                      <br />
                      Trendyol üzerinden gerçekleştirilen satışlar için otomatik
                      olarak fatura oluşturma ve gönderme işlemlerini sağlar. Bu
                      sayede, muhasebe süreçleriniz otomatikleşir ve manuel iş
                      yükü azalır.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-percent"></i>
                    <p>
                      <b>Promosyon ve Kampanya Yönetimi:</b> <br /> Trendyol
                      üzerinde yapacağınız kampanyaları ve indirimleri Entekas
                      üzerinden planlayabilir ve yönetebilirsiniz. Bu sayede,
                      müşteri çekme stratejilerinizi daha etkili bir şekilde
                      uygulayabilirsiniz.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-sync"></i>
                    <p>
                      <b>Gerçek Zamanlı Güncellemeler:</b> <br /> Entekas,
                      Trendyol ile gerçek zamanlı veri alışverişi sağlar.
                      Böylece, ürün stokları, fiyat değişiklikleri ve sipariş
                      durumu gibi bilgiler her zaman güncel kalır.
                    </p>
                  </li>
                </ul>
                <div className="row">
                  <div className="col-xl-12">
                    <b>
                      Bu entegrasyonlar sayesinde Entekas e-ticaret platformu,
                      Trendyol'daki operasyonlarınızı daha verimli ve etkili bir
                      şekilde yönetmenize yardımcı olur.
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div
                className="project-details-info rmb-55"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <table>
                  <tr>
                    <td style={{ fontSize: 15, paddingRight: 10 }}>
                      ENTEGRASYON KATEGORİSİ
                    </td>
                    <td style={{ fontSize: 17 }}>
                      <b>Pazaryeri</b>
                    </td>
                  </tr>
                  <hr className="mb-30" style={{ width: "200%" }} />
                  <tr>
                    <td style={{ fontSize: 15, paddingRight: 10 }}>
                      {" "}
                      DESTEKLEDİĞİ PLATFORMLAR
                    </td>
                    <td style={{ fontSize: 17 }}>
                      <b>Dinazors.com, N11, Hepsiburada, Amazon, Çiçeksepeti</b>
                    </td>
                  </tr>
                  <hr className="mb-30" style={{ width: "200%" }} />
                </table>
                <div className="project-share mt-20">
                  <a
                    onClick={() => navigate("/kayit-ol")}
                    className="theme-btn"
                    style={{ width: "100%" }}
                  >
                    ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Project Details Area End --> */}

      {/* <!-- Features Area Start --> */}
      <section className="about-features-area pt-130 rpt-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-11">
              <div
                className="section-title text-center mb-45"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    Entekas & Trendyol Entegrasyonu
                  </span>
                  <h2>Nasıl Çalışır?</h2>
                </div>

                <div className="row justify-content-center">
                  <div className="col-xl-9 col-lg-11"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              // className="col-xl-4 col-md-6"
              className="col-12"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="far fa-cloud-upload"></i>
                  <h5>
                    <a href="">Trendyol Ürün Aktarımı</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Trendyol’da bulunan ürünlerinizi Entekas’a aktarabilirsiniz.
                    Bu aktarım esnasında hem tekil hem de seçenekli (varyantlı)
                    ürünlerinizin aktarımını gerçekleştirebilirsiniz
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-truck"></i>
                  <h5>
                    <a href="">Trendyol Sipariş Aktarımı</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Trendyol’da gelen siparişleriniz birkaç dakika içerisinde
                    Entekas’a aktarılır. Siparişlerinizin yönetimini, kargo fişi
                    çıktılarını ve kargo barkodlarını yazdırabilirsiniz.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="far fa-layer-group"></i>
                  <h5>
                    <a href="">Trendyol Stok Aktarımı</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Otomatik stok senkronizasyonu sayesinde tüm satışlarınızın
                    stok değişimi anlık olarak diğer satış kanallarına da
                    aktarılır.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="iconic-box style-seven border">
                <div className="icon-title">
                  <i className="fas fa-file-invoice"></i>
                  <h5>
                    <a href="">Trendyol Fatura Aktarımı</a>
                  </h5>
                </div>
                <div className="content">
                  <p>
                    Aktarılan siparişlerinizin e-fatura süreçlerini bir kaç tık
                    ile Entekas üzerinden yazdırabilirsiniz.
                  </p>
                </div>
                <div className="bg">
                  <img
                    src="/assets/landingPage/images/shapes/iconic-box-seven-bg2.png"
                    alt="Shape"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Features Area End --> */}

      {/* <!-- Ekran Görüntüleri Start --> */}
      {/* <section className="related-project-area py-85 rpy-55">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 col-lg-11">
              <div
                className="section-title mb-60"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    Trendyol Entegrasyonu
                  </span>
                  <h2>Ekran Görüntüleri</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-md-6 item design">
              <div
                className="project-item"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/projects/project-grid7.jpg"
                    alt="Project Grid"
                  />
                </div>
                <div className="content">
                  <h5>
                    <a href="project-details.html">Digital Product Design</a>
                  </h5>
                  <span className="category">Design, Branding</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 item marketing seo">
              <div
                className="project-item"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/projects/project-grid2.jpg"
                    alt="Project Grid"
                  />
                </div>
                <div className="content">
                  <h5>
                    <a href="project-details.html">Website Development</a>
                  </h5>
                  <span className="category">Design, Branding</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 item seo design">
              <div
                className="project-item"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/projects/project-grid9.jpg"
                    alt="Project Grid"
                  />
                </div>
                <div className="content">
                  <h5>
                    <a href="project-details.html">Digital Product Design</a>
                  </h5>
                  <span className="category">Design, Branding</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- Ekran Görüntüleri End --> */}

      {/* <!-- About Area Five Start --> */}
      <section className="about-area-five pt-80 pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-5">
              <div
                className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/integrators/entegrasyon-1.png"
                  alt="About"
                />
                <img
                  src="/assets/landingPage/integrators/trendyol-entegrasyon.png"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket"></i> Trendyol Entegrasyonunun
                  </span>
                  <h2>Avantajları Nelerdir?</h2>
                </div>
                <p>
                  Entekas ile Trendyol entegrasyonu, e-ticaret operasyonlarınızı
                  daha verimli, etkili ve kârlı hale getirir. Bu avantajlar,
                  işletmenizin rekabet gücünü artırır ve müşteri memnuniyetini
                  en üst düzeye çıkarır.
                </p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-sync"></i> Otomatik Ürün
                    Senkronizasyonu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas-Trendyol entegrasyonu, ürünlerinizi Trendyol’a
                    otomatik olarak senkronize etmenizi sağlar. Bu, ürün
                    bilgilerini manuel olarak güncelleme ihtiyacını ortadan
                    kaldırır ve hata riskini azaltır. Böylece, stok seviyeleri
                    ve ürün detayları her zaman güncel kalır.
                  </li>
                  <li>
                    <i className="fas fa-clipboard-list"></i> Kolay Sipariş
                    Yönetimi
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas sayesinde Trendyol üzerinden gelen siparişlerinizi
                    tek bir platformdan yönetebilirsiniz. Siparişlerin otomatik
                    olarak alınması ve güncellenmesi, işlemlerinizi hızlandırır
                    ve verimliliğinizi artırır. Sipariş durumları ve teslimat
                    bilgileri anında güncellenir, böylece müşteri memnuniyeti
                    sağlanır.
                  </li>
                  <li>
                    <i className="fas fa-chart-bar"></i> Kapsamlı Raporlama ve
                    Analiz
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas, Trendyol entegrasyonu ile satış performansınızı
                    detaylı bir şekilde analiz etmenize olanak tanır. Gelir ve
                    ürün performansı gibi kritik metrikler hakkında kapsamlı
                    raporlar alabilirsiniz. Bu veriler, stratejik kararlar
                    almanıza ve işinizi büyütmenize yardımcı olur.
                  </li>
                  <li>
                    <i className="fas fa-tags"></i> Fiyat ve Stok Yönetimi
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Trendyol’daki ürünlerinizin fiyatlarını ve stok seviyelerini
                    kolayca yönetebilirsiniz. Entekas, fiyat ve stok
                    güncellemelerini anında Trendyol'a yansıtarak rekabetçi
                    olmanızı sağlar. Bu, stok tükenmesi veya fiyat
                    değişikliklerinden kaynaklanan müşteri kayıplarını minimize
                    eder.
                  </li>
                  <li>
                    <i className="fas fa-clock"></i> Zaman ve Maliyet Tasarrufu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Otomatik süreçler ve merkezi yönetim paneli, iş gücü ve
                    zaman tasarrufu sağlar. Manuel veri girişleri ve hata
                    düzeltmeleriyle uğraşmak zorunda kalmazsınız. Böylece,
                    kaynaklarınızı daha verimli kullanabilir ve operasyonel
                    maliyetlerinizi düşürebilirsiniz.
                  </li>
                  <li>
                    <i className="fas fa-smile"></i> Kolay Kurulum ve Kullanım
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas-Trendyol entegrasyonu, kullanıcı dostu arayüzü ve
                    kolay kurulum süreci ile dikkat çeker. Teknik bilgi
                    gerektirmeyen bu sistem, her seviyeden kullanıcının hızlıca
                    uyum sağlamasını sağlar.
                  </li>
                  <li>
                    <i className="fas fa-shield-alt"></i> Güvenilir ve
                    Kesintisiz Hizmet
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas, bulut tabanlı yapısı sayesinde yüksek güvenlik ve
                    kesintisiz hizmet sunar. Verileriniz güvenli bir şekilde
                    saklanır ve yedeklenir. Bu, iş süreçlerinizin aksamadan
                    devam etmesini sağlar.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- About Area End --> */}

      {/* <!-- FeedbackArea Start --> */}
      <section className="feedback-area-five bgc-lighter pt-60 rpt-105 pb-80 rpb-60 rel z-1">
        <div className="container">
          <div
            className="section-title text-center mb-55"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="50"
          ></div>
          <div className="row gap-50">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="management-images float-lg-end my-40"
                style={{ paddingLeft: 0 }}
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/featurePageLogos/toplu-urun-ekleme.png"
                  alt="Management"
                />
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="service-mamagement-content mt-40 rmb-20"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div style={{ display: "flex", paddingBottom: 20 }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      height: screens.xs ? 38 : 47,
                      width: screens.xs ? 40 : 47,
                      padding: screens.xs
                        ? "4px 6px 8px 4px"
                        : "7px 12px 10px 10px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      marginRight: 4,
                    }}
                  >
                    <img src="/assets/innerPageLogos/dinazorsLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/trendyolLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/hepsiburadaLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/n11Logo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/ciceksepetiLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/amazonLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/bizimhesapLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/parasutLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/elogoLogo.png" />
                  </div>
                </div>
                <div className="section-title mb-30">
                  <h2>Toplu Ürün Aktarımı</h2>
                </div>
                <p>
                  Aktarım sihirbazı ile dilediğiniz pazaryerlerine toplu ürün
                  aktarımını başlatabilirsiniz.
                </p>
                <div className="row pt-25">
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="content">
                        <h5 style={{ paddingBottom: 20 }}>
                          Aktarım sihirbazı ile dilediğiniz pazaryerlerine toplu
                          ürün aktarımını başlatabilirsiniz.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="content">
                        <h5 style={{ paddingBottom: 20 }}>
                          Bir kaç kolay adımda tüm ürünlerinizi inceleyip akıllı
                          raporlar ile aktarım süreçlerinizi hızlandırıyoruz.
                        </h5>
                        <a
                          onClick={() => navigate("/kayit-ol")}
                          className="theme-btn"
                        >
                          ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- FeedbackArea End --> */}

      {/* <!-- Team Area Start --> */}
      <section className="team-area pt-120 pb-80 rpb-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div
                className="section-title text-center mb-55"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    Trendyol Mağazanızı
                  </span>
                  <h2>Entegre Edebileceğiniz Diğer Platformlar</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#B7D463",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/dinazorsBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/dinazors")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Dinazors</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#ff9900",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/amazonBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                    <div className="social-media">
                      <h5>
                        <a onClick={() => navigate("/entegrasyonlar/amazon")}>
                          Detayları İncele
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="description">
                  <h5>Amazon</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            {/* <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#f26725",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/trendyolBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/trendyol")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Trendyol</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div> */}

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#ff963f",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/hepsiBuradaBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a
                        onClick={() => navigate("/entegrasyonlar/hepsiburada")}
                      >
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Hepsiburada</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#6849c5",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/n11Beyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/n11")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>N11</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#51B449",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/ciceksepetiBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a
                        onClick={() => navigate("/entegrasyonlar/ciceksepeti")}
                      >
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Çiçeksepeti</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#28B29A",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/accountingLogos/bizimHesapBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/bizimhesap")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Bizim Hesap</h5>
                  <span className="designation">Muhasebe</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#fd5c4a",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/accountingLogos/parasutBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/parasut")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Paraşüt</h5>
                  <span className="designation">Muhasebe</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#2B378A",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/eInvoice/eLogoEfaturaBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/elogo")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Logo E-Fatura</h5>
                  <span className="designation">E Fatura</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Team Area End --> */}
    </>
  );
};

export default TrendyolIntegration;
