import { Col, Row, Tag, Tooltip, Typography } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

const { Text } = Typography;

interface ITransferDetailCardProps {
  transferDate?: any;
  matching?: any;
  mismatch?: any;
  status?: string;
}

const TransferDetailCard = (props: ITransferDetailCardProps) => {
  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <Row justify={"space-between"}>
          <Col>
            <Text style={{ fontSize: 15, color: "black" }}>Aktarım Durumu</Text>
            <Tag style={{ marginLeft: 4 }}>{props.status ?? ""}</Tag>
          </Col>
          <Col>
            <Text style={{ fontSize: 15, color: "black", paddingRight: 4 }}>
              Son Aktarım Tarihi:
            </Text>
            <Text strong style={{ fontSize: 14, color: "black" }}>
              {props.transferDate}
            </Text>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[48, 24]}>
          <Col xl={8} md={24} xs={24}>
            <Row
              style={{
                padding: "10px 15px",
                borderRadius: 6,
                backgroundColor: "#EEF8FE",
              }}
            >
              <Col span={24}>
                <Text style={{ fontSize: 28, color: "#0F82FF" }}>
                  {(props.matching ?? 0) + (props.mismatch ?? 0)}
                </Text>
              </Col>
              <Col span={24}>
                <Text style={{ fontSize: 14, color: "black", paddingRight: 4 }}>
                  Aktarılan
                </Text>
                <Tooltip title="İlgili platformdan Entekas panelinize aktarılan toplam ürün sayınız.">
                  <QuestionCircleFilled
                    style={{ fontSize: 14, color: "#0F82FF" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={24} xs={24}>
            <Row
              style={{
                padding: "10px 15px",
                borderRadius: 6,
                backgroundColor: "#DFFEEF",
              }}
            >
              <Col span={24}>
                <Text style={{ fontSize: 28, color: "#11C26D" }}>
                  {props.matching ?? 0}
                </Text>
              </Col>
              <Col span={24}>
                <Text style={{ fontSize: 14, color: "black", paddingRight: 4 }}>
                  Eşleşen
                </Text>
                <Tooltip title="İlgili platformdan aktarılan ürünlerin Entekas panelinde bulunan ürünlerle eşleşme sayısı.">
                  <QuestionCircleFilled
                    style={{ fontSize: 14, color: "#11C26D" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={24} xs={24}>
            <Row
              style={{
                padding: "10px 15px",
                borderRadius: 6,
                backgroundColor: "#FEF6EA",
              }}
            >
              <Col span={24}>
                <Text style={{ fontSize: 28, color: "#F6A939" }}>
                  {props.mismatch ?? 0}
                </Text>
              </Col>
              <Col span={24}>
                <Text style={{ fontSize: 14, color: "black", paddingRight: 4 }}>
                  Eşleşmeyen
                </Text>
                <Tooltip title="İlgili platformdan aktarılan ürünlerin Entekas panelinde bulunan ürünlerle eşleşmeyen ürünlerin sayısı.">
                  <QuestionCircleFilled
                    style={{ fontSize: 14, color: "#F6A939" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TransferDetailCard;
