import ReactQuill from "react-quill";

const RichText = (props: any) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "script",
    "sub",
    "super",
    "align",
    "direction",
    "link",
    "image",
    "video",
    "color",
    "background",
  ];

  return (
    <ReactQuill
      {...props}
      theme="snow"
      modules={modules}
      formats={formats}
      placeholder="İçeriği buraya yazın..."
      scrollingContainer="body"
      preserveWhitespace
    />
  );
};

export default RichText;
