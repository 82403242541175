import {
  Layout,
  Menu,
  Drawer,
  Image,
  Typography,
  Grid,
  Tooltip,
  Divider,
} from "antd";
import { useNavigate } from "react-router-dom";
import { colors, heights, widths } from "../../theme";
import {
  SettingOutlined,
  PushpinOutlined,
  PushpinFilled,
  ShoppingCartOutlined,
  HomeOutlined,
  ProductOutlined,
  NodeIndexOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const { Title } = Typography;

interface ISideBarProps {
  collapsed: any;
  toggleCollapsed: any;
  open: any;
  showDrawer: any;
  onClose: any;
  pinned: boolean;
  togglePinned: () => void;
}

const SideBar = (props: ISideBarProps) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const navigate = useNavigate();

  const getTermsMenuItems = () => [
    {
      label: "Dashboard",
      key: "dashboard",
      icon: <HomeOutlined style={{ fontSize: "1.5rem" }} />,
      onClick: () => navigate("/dashboard"),
    },
    {
      label: "Siparişler",
      key: "order",
      icon: <ShoppingCartOutlined style={{ fontSize: "1.5rem" }} />,
      onClick: () => navigate("/siparisler"),
    },
    {
      label: "Ürünler",
      key: "product",
      icon: <ProductOutlined style={{ fontSize: "1.5rem" }} />,
      children: [
        {
          label: "Ürün Listesi",
          key: "product:list",
          onClick: () => navigate("/urunler"),
        },
        {
          label: "Kategoriler",
          key: "product:categories",
          onClick: () => navigate("/kategoriler"),
        },
        {
          label: "Markalar",
          key: "product:brands",
          onClick: () => navigate("/markalar"),
        },
        {
          label: "Seçenekler",
          key: "product:attributes",
          onClick: () => navigate("/secenekler"),
        },
      ],
    },
    {
      label: "Toplu Aktarım",
      key: "bulkImport",
      icon: <DownloadOutlined style={{ fontSize: "1.5rem" }} />,
      children: [
        {
          label: "XML İçeri Aktarım",
          key: "bulkImport:xml",
          onClick: () => navigate("/xml-ice-aktarim"),
        },
      ],
    },

    {
      label: "Entegrasyon Kayıtları",
      key: "batchRequests",
      icon: <NodeIndexOutlined style={{ fontSize: "1.5rem" }} />,
      onClick: () => navigate("/entegrasyon-kayitlari"),
    },
    {
      label: "Ayarlar",
      key: "settings",
      icon: <SettingOutlined style={{ fontSize: "1.5rem" }} />,
      children: [
        {
          label: "Pazaryeri",
          key: "settings:marketplace",
          onClick: () => navigate("/pazaryeri"),
        },
        {
          label: "ERP Muhasebe",
          key: "settings:accounting",
          onClick: () => navigate("/muhasebe"),
        },
        {
          label: "E Fatura",
          key: "settings:einvoice",
          onClick: () => navigate("/efatura"),
        },
      ],
    },
  ];

  const menuContent = (
    <>
      <div
        style={{
          height: heights.headerHeight,
          justifyContent: "space-between",
          display: screens.md ? "flex" : "block",
          alignItems: "center",
          padding: "0 24px",
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            marginTop: screens.md ? "" : -6,
          }}
        >
          <div>
            <Image
              preview={false}
              src={screens.md ? "/assets/mini-logo.png" : "/assets/logo.png"}
              style={{
                height: "auto",
                width: screens.md ? 35 : 200,
              }}
            />
          </div>
          <div
            style={{
              display: screens.md
                ? props.collapsed
                  ? "none"
                  : "flex"
                : "none",
              marginLeft: 10,
              marginTop: -4,
            }}
          >
            <Title
              style={{
                fontSize: "1.4rem",
                margin: 0,
                width: 120,
                overflow: "hidden",
              }}
            >
              entekas
            </Title>
          </div>
        </div>
        {props.collapsed ? (
          <></>
        ) : screens.md ? (
          <Tooltip
            title={props.pinned ? "Sabitlemeyi Kaldır" : "Menüyü Sabitle"}
            placement="bottom"
          >
            <div onClick={props.togglePinned}>
              {props.pinned ? (
                <PushpinFilled style={{ fontSize: 16 }} />
              ) : (
                <PushpinOutlined style={{ fontSize: 16 }} />
              )}
            </div>
          </Tooltip>
        ) : (
          <></>
        )}
      </div>
      <Divider style={{ margin: screens.md ? 0 : "-18px 0 0 0" }} />
      <div style={{ paddingTop: "10px 0 0 14px" }}>
        <Title
          level={5}
          style={{
            color: "#A5A1AA",
            padding: "20px 30px 30px 30px",
            letterSpacing: "0.1rem",
            width: screens.md
              ? props.collapsed
                ? 0
                : widths.openDrawerWidth
              : "",
            overflow: "hidden",
          }}
        >
          {screens.md ? (
            props.collapsed ? (
              <s style={{ color: "transparent" }}>a</s>
            ) : (
              "Yönetim Paneli"
            )
          ) : (
            "Yönetim Paneli"
          )}
        </Title>
      </div>

      <Menu
        mode="inline"
        defaultSelectedKeys={["home"]}
        items={getTermsMenuItems()}
      />
    </>
  );

  const siderStyle = {
    height: "100vh",
    left: 0,
    zIndex: 2,
    backgroundColor: colors.white,
    boxShadow: "rgba(19, 25, 32, 0.08) 0px 8px 24px",
  };

  return (
    <>
      {screens.md ? (
        <Sider
          trigger={null}
          breakpoint="md"
          collapsedWidth={screens.md ? widths.closeSiderWidth : "0"}
          collapsible
          collapsed={props.collapsed}
          width={widths.openSiderWidth}
          onMouseEnter={() => {
            if (!props.pinned) {
              props.toggleCollapsed();
            }
          }}
          onMouseLeave={() => {
            if (!props.pinned) {
              props.toggleCollapsed();
            }
          }}
          style={{
            ...siderStyle,
            position: props.pinned ? "relative" : "absolute",
          }}
        >
          {menuContent}
        </Sider>
      ) : (
        <Drawer
          className="myDrawer"
          placement="left"
          onClose={props.onClose}
          open={props.open}
          width={widths.openDrawerWidth}
          style={{ zIndex: 2 }}
        >
          {menuContent}
        </Drawer>
      )}
    </>
  );
};

export default SideBar;
