import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../../helpers/AuthHelper";

interface ILoginRouteProps {
  element: any;
  redirectPath?: string;
}

const LoginRoute = (props: ILoginRouteProps) => {
  return isLoggedIn() ? (
    <Navigate to={props.redirectPath ?? "/"} />
  ) : (
    props.element
  );
};

export default LoginRoute;
