import { Checkbox, Col, Form, Row, Select } from "antd";

interface ITrendyolImportFormProps {
  form: any;
}

const TrendyolImportForm = (props: ITrendyolImportFormProps) => {
  return (
    <Form
      form={props.form}
      initialValues={{
        dontHave: true,
        zeroStockImport: true,
        nonApproved: false,
        archived: false,
        stockCodeMatchName: "productMainId",
      }}
    >
      <Row justify="space-between">
        <Col span={18}>
          Entegratörde bulunmayan ürünleriniz Trendyol'dan çekilsin mi?
        </Col>
        <Col span={4}>
          <Form.Item name="dontHave" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={18}>Stoğu sıfır(0) olan ürünler de çekilsin mi?</Col>
        <Col span={4}>
          <Form.Item name="zeroStockImport" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={18}>
          Trendyol’da bulunan onaylanmamış ürünler çekilsin mi?
        </Col>
        <Col span={4}>
          <Form.Item name="nonApproved" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={18}>Trendyol’da bulunan arşivdeki ürünler çekilsin mi?</Col>
        <Col span={4}>
          <Form.Item name="archived" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={16}>
          Trendyol’da bulunan ürünlerinizin Entegratördeki stok kodu alanına
          hangi bilgisi getirilsin?
        </Col>
        <Col span={6}>
          <Form.Item style={{ width: "100%" }} name="stockCodeMatchName">
            <Select
              style={{ width: "100%" }}
              options={[
                { label: "Model Kodu", value: "productMainId" },
                {
                  label: "Tedarikçi Stok Kodu",
                  value: "stockCode",
                },
                { label: "Barkod", value: "barcode" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TrendyolImportForm;
