import { Form, Input, Select } from "antd";
import { max, required } from "../../../helpers/FormRuleHelper";

interface ICicekSepetiApiFormProps {
  form: any;
}

const CicekSepetiApiForm = (props: ICicekSepetiApiFormProps) => {
  return (
    <Form form={props.form} layout="vertical" initialValues={{ active: false }}>
      <Form.Item name="apiKey" label="API KEY" rules={[required, max(255)]}>
        <Input.Password />
      </Form.Item>
      <Form.Item name="active" label="Entegrasyon Durumu" rules={[required]}>
        <Select
          options={[
            { label: "Entegrasyon Açık", value: true },
            { label: "Entegrasyon Kapalı", value: false },
          ]}
        />
      </Form.Item>
    </Form>
  );
};

export default CicekSepetiApiForm;
