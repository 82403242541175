import { Col, Divider, Row, Typography } from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import ImageCard from "../../components/common/ImageCard";

const { Title } = Typography;

const AccountingServicesPage = () => {
  const items = {
    active: [
      {
        title: "Bizim Hesap",
        color: "#28B29A",
        logo: "/assets/accountingLogos/bizimHesapBeyaz.png",
        to: "/bizimhesap",
        passive: true,
      },
      {
        title: "Paraşüt",
        color: "#fd5c4a",
        logo: "/assets/accountingLogos/parasutBeyaz.png",
        to: "/parasut",
      },
    ],
    comingSoon: [
      {
        title: "Asb Yazılım",
        color: "#e6831d",
        logo: "/assets/accountingLogos/asbYazilimBeyaz.png",
        to: "/asbyazilim",
      },
      {
        title: "Eta",
        color: "#1d3c76",
        logo: "/assets/accountingLogos/etaBeyaz.png",
        to: "/eta",
      },
      {
        title: "IdeaConnect Muhasebe",
        color: "#a2d45e",
        logo: "/assets/accountingLogos/ideaConnectBeyaz.png",
        to: "/idea-connect",
      },
      {
        title: "Logo İşbaşı",
        color: "#28B29A",
        logo: "/assets/accountingLogos/logoIsbasiBeyaz.png",
        to: "/logo-isbasi",
      },
      {
        title: "Logo",
        color: "#d5343e",
        logo: "/assets/accountingLogos/logoLogoBeyaz.png",
        to: "/logo",
      },
      {
        title: "Sysmond",
        color: "#30789e",
        logo: "/assets/accountingLogos/sysmondBeyaz.png",
        to: "/sysmond",
      },
      {
        title: "Dia",
        color: "#1d3c76",
        logo: "/assets/accountingLogos/dia.png",
        to: "/dia",
      },
    ],
  };

  return (
    <>
      <InnerHeader
        firstPageLink="/"
        secondPageName="Ayarlar"
        thirdPageName="Muhasebe Ayarları"
        thirdPageLink="/muhasebe"
      />
      <Row style={{ padding: 32 }} gutter={[24, 24]}>
        {items.active.map((item, index) => (
          <Col key={index}>
            <ImageCard
              title={item.title}
              src={item.logo}
              color={item.color}
              to={"/muhasebe" + item.to}
            />
          </Col>
        ))}
        <Divider orientation="left">
          <Title level={3}>Çok Yakında</Title>
        </Divider>
        {items.comingSoon.map((item, index) => (
          <Col key={index}>
            <ImageCard
              title={item.title}
              src={item.logo}
              color={item.color}
              to={item.to}
              comingSoon={true}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default AccountingServicesPage;
