import { Row, Tabs } from "antd";
import InnerHeader from "../components/layouts/InnerHeader";
import XmlImportRecords from "../components/xml/XmlImportRecords";
import XmlImportReservation from "../components/xml/XmlImportReservation";

const XmlImportPage = () => {
  return (
    <>
      <InnerHeader
        firstPageLink="/"
        secondPageName="Toplu Aktarım"
        thirdPageName="XML İçeri Aktarım"
        thirdPageLink="/xml-ice-aktarim"
      />
      <Row style={{ padding: "16px 32px 32px 32px" }}>
        <Tabs
          style={{ width: "100%" }}
          defaultActiveKey="1"
          type="card"
          items={[
            { key: "1", label: "Aktarım", children: <XmlImportReservation /> },
            {
              key: "2",
              label: "Aktarım Kayıtları",
              children: <XmlImportRecords />,
            },
          ]}
        />
      </Row>
    </>
  );
};

export default XmlImportPage;
