import { colors } from "../../theme";
import { Col, Row } from "antd";

interface IDefaultCardProps {
  header?: any;
  footer?: any;
  children?: any;
  style?: any;
  onClick?: () => void;
}

const DefaultCard = (props: IDefaultCardProps) => {
  const padding = 24;
  const border = "2px solid #E4EAF5";

  return (
    <Row
      onClick={props.onClick}
      align="middle"
      style={{
        borderRadius: 10,
        backgroundColor: colors.white,
        boxShadow: "0 1px 12px 0 rgba(27,71,175,0.13)",
        ...props.style,
      }}
    >
      {props.header && (
        <Col span={24} style={{ padding: padding, borderBottom: border }}>
          {props.header}
        </Col>
      )}
      <Col span={24} style={{ padding: padding }}>
        {props.children}
      </Col>
      {props.footer && (
        <Col span={24} style={{ padding: padding, borderTop: border }}>
          {props.footer}
        </Col>
      )}
    </Row>
  );
};

export default DefaultCard;
