import { Button, Col, Form, FormInstance, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { AttributeGroupCrudApi } from "../../client/EntityApi";
import { CloseOutlined } from "@ant-design/icons";
import AttributeSetForm from "./AttributeSetForm";

interface IProductAttributesFormProps {
  form: FormInstance;
}

const ProductAttributesForm = (props: IProductAttributesFormProps) => {
  const [selectedAttributeGroup, setSelectedAttributeGroup] = useState();
  const [attributeGroups, setAttributeGroups] = useState([]);
  const [selectedAttributeValue, setSelectedAttributeValue] = useState();
  const [attributeValues, setAttributeValues] = useState([]);

  useEffect(() => {
    AttributeGroupCrudApi.getAll().then((response) =>
      setAttributeGroups(
        response["hydra:member"].map((item: any) => ({
          ...item,
          label: item.name,
          value: item["@id"],
          attributeValues: item.attributeValues.map((subItem: any) => ({
            label: subItem.name,
            value: subItem["@id"],
          })),
        }))
      )
    );
  }, []);

  return (
    <Form.List name="productAttributes">
      {(fields, { add, remove }) => (
        <>
          <Col span={10}>
            <Select
              style={{ width: "100%" }}
              options={attributeGroups}
              value={selectedAttributeGroup}
              onSelect={(value, options: any) => {
                setSelectedAttributeGroup(value);
                setAttributeValues(options.attributeValues);
              }}
            />
          </Col>
          <Col span={10}>
            <Select
              style={{ width: "100%" }}
              options={attributeValues}
              value={selectedAttributeValue}
              onSelect={(value) => setSelectedAttributeValue(value)}
            />
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              style={{ width: "100%", height: "100%" }}
              onClick={() =>
                add({
                  attributeGroup: selectedAttributeGroup,
                  attributeValue: selectedAttributeValue,
                })
              }
            >
              Ekle
            </Button>
          </Col>
          <Col span={24} style={{ marginTop: 24 }}>
            {fields.map((field, index) => (
              <Row key={index} gutter={[8, 24]}>
                <AttributeSetForm
                  name={field.name}
                  attributeGroups={attributeGroups}
                  form={props.form}
                />
                <Col span={2}>
                  <Button
                    danger
                    shape="circle"
                    type="primary"
                    onClick={() => remove(field.name)}
                    icon={<CloseOutlined />}
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </>
      )}
    </Form.List>
  );
};

export default ProductAttributesForm;
