import {
  Layout,
  Button,
  Image,
  Grid,
  MenuProps,
  Dropdown,
  Row,
  Col,
  Typography,
  Tooltip,
} from "antd";
import { SettingOutlined, LockOutlined } from "@ant-design/icons";
import { colors, defaultSources, heights, widths } from "../../theme";
import LogoutIcon from "../icons/LogoutIcon";
import HamburderMenuIcon from "../icons/HamburderMenuIcon";
import { getUserField, removeCredentials } from "../../helpers/AuthHelper";
import { useRef } from "react";
import PasswordUpdateModal, {
  IPasswordUpdateModalRefMethods,
} from "../modal/PasswordUpdateModal";
import ProfileUpdateModal, {
  IProfileUpdateModalRefMethods,
} from "../modal/ProfileUpdateModal";

const { Header } = Layout;
const { Text } = Typography;

interface IMyHeaderProps {
  collapsed: any;
  toggleCollapsed: any;
  showDrawer: any;
  pinned: any;
}

const CustomHeader = (props: IMyHeaderProps) => {
  const { useBreakpoint } = Grid;
  const profileUpdateModalRef = useRef<IProfileUpdateModalRefMethods>(null);
  const passwordUpdateModalRef = useRef<IPasswordUpdateModalRefMethods>(null);

  const screens = useBreakpoint();

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <Tooltip title="Profilimi düzenle">
          <Row
            align="middle"
            justify="center"
            style={{ width: screens.md ? 290 : 250 }}
          >
            <Col span={6}>
              <Image
                preview={false}
                src={defaultSources.defaultAvatar}
                style={{ height: 46, width: 46, borderRadius: "50%" }}
              />
            </Col>
            <Col span={14}>
              <Row>
                <Col span={24}>{getUserField("fullName")}</Col>
              </Row>
            </Col>
            <Col span={4}>
              <SettingOutlined style={{ fontSize: 26 }} />
            </Col>
          </Row>
        </Tooltip>
      ),
      onClick: () => {
        profileUpdateModalRef.current?.form.setFieldsValue({
          email: getUserField("email"),
          fullName: getUserField("fullName"),
        });
        profileUpdateModalRef.current?.setOpen(true);
      },
    },
    {
      type: "divider",
    },
    {
      key: "1",
      label: (
        <Tooltip title="Şifremi Güncelle" arrow={false}>
          <Row align="middle" justify="center">
            <Col span={20}>
              <Text>Şifremi Güncelle</Text>
            </Col>
            <Col span={4}>
              <LockOutlined style={{ fontSize: 18 }} />
            </Col>
          </Row>
        </Tooltip>
      ),
      onClick: () => passwordUpdateModalRef.current?.setOpen(true),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      onClick: () => removeCredentials(),
      label: (
        <Row align="middle" justify="center">
          <Col span={20}>
            <Text style={{ color: colors.danger }}>Çıkış Yap</Text>
          </Col>
          <Col span={4}>
            <LogoutIcon height={26} width={26} />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Header
        style={{
          padding: screens.md ? "8px 64px" : "8px 16px",
          position: "fixed",
          height: heights.headerHeight,
          width: screens.md
            ? `calc(100% - ${
                props.pinned
                  ? props.collapsed
                    ? widths.closeSiderWidth
                    : widths.openSiderWidth
                  : widths.closeSiderWidth
              }px)`
            : "100%",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          backdropFilter: "blur(4px)",
          justifyContent: screens.md ? "end" : "space-between",
          alignItems: "center",
          display: "flex",
          boxShadow: "rgba(19, 25, 32, 0.08) 0px 8px 24px",
          borderBottom: "1px solid #eee",
        }}
      >
        {screens.md ? (
          <></>
        ) : (
          <Button
            onClick={screens.md ? props.toggleCollapsed : props.showDrawer}
            style={{
              height: 44,
              width: 44,
              padding: 8,
              margin: "0px 0px 0px -12px",
              transition:
                "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              backgroundColor: "#F3F5F7",
              border: "none",
            }}
          >
            <HamburderMenuIcon style={{ marginTop: 2 }} />
          </Button>
        )}

        <Dropdown menu={{ items }} trigger={["click"]} placement="bottom">
          <Image
            preview={false}
            src={defaultSources.defaultAvatar}
            style={{ height: 40, width: 40, borderRadius: "50%" }}
          />
        </Dropdown>
      </Header>
      <ProfileUpdateModal ref={profileUpdateModalRef} />
      <PasswordUpdateModal ref={passwordUpdateModalRef} />
    </>
  );
};

export default CustomHeader;
