import { Form, Input, Select } from "antd";
import { max, required } from "../../../helpers/FormRuleHelper";

interface IParasutApiFormProps {
  form: any;
}

const ParasutApiForm = (props: IParasutApiFormProps) => {
  return (
    <Form form={props.form} layout="vertical" initialValues={{ active: false }}>
      <Form.Item
        name="companyNumber"
        label="FİRMA NUMARASI*"
        rules={[required, max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="apiClientId"
        label="APİ CLIENT ID"
        rules={[required, max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="apiClientSecret"
        label="APİ CLIENT SECRET"
        rules={[required, max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="username"
        label="PARAŞÜT KULLANICI ADINIZ"
        rules={[required, max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="password"
        label="PARAŞÜT ŞİFRENİZ"
        rules={[required, max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="cargoCompanyId"
        label="BİRİNCİL KARGO FİRMANIZ(E-fatura Kullanıcıları İçin Zorunludur)*"
        rules={[required]}
      >
        <Select
          options={[
            { label: "DHL Marketplace", value: 42 },
            { label: "Sendeo Marketplace", value: 38 },
            { label: "Octovan Lojistik Marketplace", value: 35 },
            { label: "Borusan Lojistik Marketplace", value: 30 },
            { label: "Cainiao Marketplace", value: 14 },
            { label: "MNG Kargo Marketplace", value: 10 },
            { label: "PTT Kargo Marketplace", value: 19 },
            { label: "Sürat Kargo Marketplace", value: 9 },
            { label: "Trendyol Express Marketplace", value: 17 },
            { label: "Horoz Kargo Marketplace", value: 6 },
            { label: "CEVA Marketplace", value: 20 },
            { label: "Yurtiçi Kargo Marketplace", value: 4 },
            { label: "Aras Kargo Marketplace", value: 7 },
          ]}
        />
      </Form.Item>
      <Form.Item name="active" label="Entegrasyon Durumu" rules={[required]}>
        <Select
          options={[
            { label: "Entegrasyon Açık", value: true },
            { label: "Entegrasyon Kapalı", value: false },
          ]}
        />
      </Form.Item>
    </Form>
  );
};

export default ParasutApiForm;
