import { Button, Tooltip } from "antd";

interface ICrudTableButtonProps {
  icon?: any;
  style?: any;
  onClick?: any;
  danger?: any;
  type?: any;
  tooltipText?: any;
  loading?: any;
}

const CrudTableButton = (props: ICrudTableButtonProps) => {
  return (
    <Tooltip title={props.tooltipText}>
      <Button
        icon={props.icon}
        onClick={props.onClick}
        danger={props.danger}
        type={props.type}
        loading={props.loading}
        shape="circle"
        style={props.style}
      />
    </Tooltip>
  );
};

export default CrudTableButton;
