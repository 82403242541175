import { Card, Row, Col, Typography, Badge } from "antd";
import { useNavigate } from "react-router-dom";
import { SettingFilled } from "@ant-design/icons";
import { colors } from "../../theme";

const { Text } = Typography;

interface IImageCardProps {
  title: string;
  src: string;
  alt?: string;
  color: string;
  to: string;
  comingSoon?: boolean;
}

const ImageCard = (props: IImageCardProps) => {
  const navigate = useNavigate();

  return (
    <Badge.Ribbon
      text={props.comingSoon ? "Çok Yakında" : ""}
      color={props.comingSoon ? colors.danger : "transparent"}
    >
      <Card
        hoverable={props.comingSoon !== true}
        onClick={() => props.comingSoon !== true && navigate(props.to)}
        style={{ width: 320, minHeight: 275 }}
        cover={
          <Col
            style={{
              height: 200,
              backgroundColor: props.color,
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Row justify="center" align="middle" style={{ height: "100%" }}>
              <img
                src={props.src}
                alt={props.alt ?? ""}
                style={{ height: 35, width: "auto", maxWidth: "fit-content" }}
              />
            </Row>
          </Col>
        }
      >
        <Row justify="center" align="middle">
          <SettingFilled style={{ fontSize: 18 }} />
          <Text
            style={{
              fontSize: "1.2rem",
              textShadow: "rgba(0, 0, 0, .15) 0 0 1px",
              color: "#3F3F3F",
              paddingLeft: 8,
              marginBottom: 4,
            }}
          >
            {props.title} Ayarları
          </Text>
        </Row>
      </Card>
    </Badge.Ribbon>
  );
};

export default ImageCard;
