import { useForm } from "antd/es/form/Form";
import { AuthApi } from "../../client/Api";
import { saveCredentials } from "../../helpers/AuthHelper";
import { useState } from "react";
import AuthLayout from "../../components/layouts/AuthLayout";
import LoginForm from "../../components/forms/LoginForm";
import { defaultSources } from "../../theme";

const LoginPage = () => {
  const [loginForm] = useForm();
  const [loading, setLoading] = useState(false);

  const loginAction = () => {
    loginForm.validateFields().then((values: any) => {
      setLoading(true);
      AuthApi.login(values.email, values.password)
        .then((response: any) => {
          setLoading(false);
          saveCredentials(
            response.user,
            response.accessToken,
            response.refreshToken
          );
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <AuthLayout
      logoSrc={defaultSources.logoSrc}
      loginBackgroundSrc={defaultSources.loginBackgroundSrc}
    >
      <LoginForm form={loginForm} loading={loading} handleLogin={loginAction} />
    </AuthLayout>
  );
};

export default LoginPage;
