import { Row, Image, Tooltip } from "antd";
import { getConstantValue } from "../../helpers/UtilHelper";
import { ServiceIconMatchList } from "../../helpers/ServiceIconMatchHelper";

export const getBrandCPColumns = (marketPlaceServices: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "İsim",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Kaynak",
      dataIndex: "source",
      key: "source",
      filters: marketPlaceServices,
      render: (value: string) =>
        value ? getConstantValue(value, marketPlaceServices) : "Entekas",
    },
    {
      title: "Marka Eşitleme Durumları",
      dataIndex: "brandMatches",
      key: "brandMatches",
      render: (value: any) => (
        <Row>
          {value.length === 0
            ? "Eşitleme Yok"
            : value.map((brandMatch: any, index: number) => (
                <Tooltip
                  key={index}
                  title={brandMatch.matchName + " ile eşitlendi."}
                >
                  <Image
                    preview={false}
                    src={ServiceIconMatchList[brandMatch.serviceName]}
                    alt=""
                  />
                </Tooltip>
              ))}
        </Row>
      ),
    },
  ];
};
