import { Popconfirm, Space, Table } from "antd";
import {
  ColumnType,
  ExpandableConfig,
  SorterResult,
} from "antd/es/table/interface";
import {
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import CrudTableButton from "./CrudTableButton";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

interface ICrudTableProps {
  api: any;
  searchKey: string;
  columns: ColumnType<any>[];
  expandable?: ExpandableConfig<any>;
  extraRowProcess?: (row: any, loading: boolean) => ReactNode;
  hideDefaultRowProceses?: ((row: any) => boolean) | boolean;
  hideEditProcess?: ((row: any) => boolean) | boolean;
  editProcess?: (row: any) => void;
  hideDeleteProcess?: ((row: any) => boolean) | boolean;
  processColumnWidth?: number;
  tableTitle?: any;
  selectedRowKeys?: any;
  setSelectedRowKeys?: any;
  getAll?: (
    page?: number,
    pageSize?: number,
    search?: string,
    orders?: string[],
    filters?: string[]
  ) => Promise<any>;
}

const CrudTable = forwardRef((props: ICrudTableProps, ref) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(10);

  const [orders, setOrders] = useState<string[]>([]);
  const [filters, setFilters] = useState<string[]>([]);

  useImperativeHandle(ref, () => ({ refreshData: refreshData }));

  const refreshData = useCallback(() => {
    setLoading(true);
    const getAll = props.getAll ?? props.api.getAll;
    getAll(page, pageSize, props.searchKey, orders, filters)
      .then((response: any) => {
        setData(response["hydra:member"]);
        setTotal(response["hydra:totalItems"]);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [props.getAll, props.searchKey, page, pageSize, orders, filters]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const createOrderQuery = (sorter: SorterResult<any>) => {
    const order = sorter.order === "ascend" ? "asc" : "desc";
    return `order[${sorter.columnKey}]=${order}`;
  };

  const checkVisibility = (
    condition?: ((row?: any) => boolean) | boolean,
    conditionParameters?: any
  ) => {
    if (condition) {
      if (typeof condition === "boolean") {
        return !condition;
      }

      return !(conditionParameters
        ? condition(conditionParameters)
        : condition());
    }

    return true;
  };

  const deleteAction = (id: number) => {
    setActionLoading(true);
    props.api
      .delete(id)
      .then(() => refreshData())
      .finally(() => setActionLoading(false));
  };

  return (
    <Table
      scroll={{ x: "calc(100vw - 100px)" }}
      loading={loading}
      rowKey="id"
      title={props.tableTitle ?? undefined}
      expandable={props.expandable}
      rowSelection={{
        selectedRowKeys: props.selectedRowKeys,
        onChange: props.setSelectedRowKeys,
      }}
      columns={[
        ...props.columns,
        {
          title: "İşlemler",
          width: props.processColumnWidth ?? "",
          render: (row: any) => {
            return (
              <Space>
                {props.extraRowProcess ? (
                  props.extraRowProcess(row, actionLoading)
                ) : (
                  <></>
                )}
                {checkVisibility(props.hideDefaultRowProceses, row) && (
                  <>
                    {checkVisibility(props.hideEditProcess, row) && (
                      <CrudTableButton
                        loading={actionLoading}
                        tooltipText="Düzenle"
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() =>
                          props.editProcess
                            ? props.editProcess(row)
                            : navigate("duzenle/" + row.id)
                        }
                      />
                    )}
                    {checkVisibility(props.hideDeleteProcess, row) && (
                      <Popconfirm
                        title="Silmek istediğinize emin misiniz?"
                        okText="Onayla"
                        cancelText="Vazgeç"
                        onConfirm={() => deleteAction(row.id)}
                      >
                        <CrudTableButton
                          loading={actionLoading}
                          tooltipText="Sil"
                          type="primary"
                          danger
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    )}
                  </>
                )}
              </Space>
            );
          },
        },
      ]}
      dataSource={data}
      pagination={{
        current: page,
        pageSize: pageSize,
        total: total,
      }}
      onChange={(pagination, filters, sorter: any) => {
        setPage(pagination.current ?? 1);
        setPageSize(pagination.pageSize ?? 10);
        let mappedOrders = [];
        if (Array.isArray(sorter)) {
          mappedOrders = sorter.map((sorter) => createOrderQuery(sorter));
        } else {
          mappedOrders = sorter.order ? [createOrderQuery(sorter)] : [];
        }

        let mappedFilters = Object.keys(filters)
          .filter((item) => filters[item] !== null)
          .map((key) => key + "=[" + filters[key] + "]");

        setFilters(mappedFilters);
        setOrders(mappedOrders);
      }}
    />
  );
});

export default CrudTable;
