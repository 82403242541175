import { Grid } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddingSmartProduct = () => {
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);
  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className="page-banner-area 
        py-250 rpy-120 rel z-1 bgs-cover text-center"
        style={{
          backgroundImage: "url(/assets/landingPage/bannerBg/newyellowBg.png)",
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <div className="container">
          <div className="banner-inner pt-70 rpt-60">
            <h1
              className="page-title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-offset="50"
              style={{ color: "rgb(163,136,83)" }}
            >
              Akıllı Ürün Ekleme
            </h1>

            <h4 style={{ paddingBottom: 50 }}>
              Entekas'ın Akıllı Ürün Kataloğu özelliği, çevrimiçi
              pazaryerlerindeki ürünlerinizi tek bir yerden kolayca yönetmenize
              olanak tanır. Bu özellik sayesinde, ürünlerinizi listelemek,
              stokları takip etmek ve fiyatları güncellemek çok daha hızlı ve
              basit olur.
            </h4>
            <a
              onClick={() => navigate("/kayit-ol")}
              className="theme-btn"
              style={{
                backgroundImage: "none",
                backgroundColor: "rgb(163,136,83)",
              }}
            >
              ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Work Step Area Five Start --> */}
      <section className="work-step-area-five pt-120 rpt-100 pb-100 rpb-80 rel z-1">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div
                className="about-content-five mt-10 mb-20 rmb-55"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Kolay Ürün Yönetimi
                  </span>
                  <h2>Size Sağladığı Avantajlar</h2>
                </div>
                <ul className="icon-list mt-40 mb-40"></ul>
                <a onClick={() => navigate("/iletisim")} className="theme-btn">
                  Detaylı Bilgi <i className="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="numbered-boxes"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="numbered-box style-two">
                  <div className="number">1</div>
                  <div className="content">
                    <h5>Ürün kaynaklarınızı birleştirin </h5>
                    <p>
                      Tüm bilgileri standartlaştırın, akıllı bir katalogla tek
                      bir ürün kaynağı oluşturun.
                    </p>
                  </div>
                </div>
                <div className="numbered-box style-two">
                  <div className="number">2</div>
                  <div className="content">
                    <h5>Tüm platformlar için</h5>
                    <p>
                      Ürün yönetimini tek bir noktadan yaparak tüm fiyat
                      süreçlerinizi kolaylaştırın.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Work Step Area End --> */}

      {/* <!-- About Area Five Start --> */}
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/featurePageLogos/featureDetail1.png"
                  alt="About"
                />
                <img
                  src="/assets/landingPage/featurePageLogos/featureDetail2.png"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Akıllı Ürün
                    Kataloğu
                  </span>
                  <h2>
                    Yönetilebilir ve işlevsel ürün katalogları hazırlayın.
                  </h2>
                </div>
                <p>
                  Her satış kanalının gerekliliklerini belirleyip tek bir ürün
                  kaynağı oluşturun. Ürünlerinizi tüm satış kanallarına uyumlu
                  hale getirin. Ürün, kategori ve marka yönetim süreçlerinizi
                  hızlandırın.
                </p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-sync-alt"></i> Otomatik Stok
                    Senkronizasyonu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Bir satış kanalından gelen stok değişimi otomatik olarak
                    diğer satış kanallarınada yansır.
                  </li>

                  <li>
                    <i className="fas fa-list-ul"></i> Kanal Bazlı Fiyatlandırma
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Her satış kanalına özel fiyat tanımlamaları yapın.
                  </li>
                  <li>
                    <i className="fas fa-sliders-h"></i> Toplu İşlemler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Ürünlerinizin kategori, marka, barkod, desi vb.
                    tanımlamalarını toplu olarak oluşturun.
                  </li>
                  <li>
                    <i className="fas fa-hashtag"></i> Etiketler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Ürünlerinize etiketler tanımlayıp o etikete özel toplu
                    işlemler gerçekleştirin.
                  </li>
                  <li>
                    <i className="fas fa-vector-square"></i> Ürün Kaynağı
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Hali hazırda pazaryeri veya e-ticaret sitenizde olan ürün
                    kataloglarınızı Entekas'ya aktarın.
                  </li>

                  <li>
                    <i className="fas fa-cloud-download-alt"></i> Excel'e Aktar
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Entekas'da bulunan ürünlerinizi Excel olarak dışarı aktarın.
                  </li>
                  <li>
                    <i className="fas fa-sort-amount-down"></i> Detaylı
                    Filtreler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Geliştirmiş olduğumuz detaylı filtreler ile tüm ürünlerinizi
                    dilediğiniz şekilde görüntüleyin.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- About Area End --> */}

      {/* <!-- Service Management Area Start --> */}
      <section className="service-mamagement-area pt-60 rpt-30 pb-90 rpb-60">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="service-mamagement-content mt-40 rmb-20"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <h2>Seçenekli Ürün Yükleme</h2>
                </div>
                <p>
                  Pazaryerlerine seçenekli ürün yüklemesi gerçekleştirin. N11,
                  Hepsiburada, Trendyol, Gittigidiyor, PttAvm ve Çiçeksepeti'ne
                  seçenekli ürün aktarımı sağlayın. E-ticaret sitenize seçenekli
                  ürün yüklemesi gerçekleştirin. IdeaSoft, Shopify, Opencart,
                  Woocommerce ve Ticimax'a seçenekli ürün aktarımı sağlayın.
                </p>
                <div className="row pt-25">
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="icon">
                        <i className="far fa-check"></i>
                      </div>
                      <div className="content">
                        <h5>Akıllı Göstergeler</h5>
                        <p>
                          Kataloğunuzda yer alan ürünlerin seçenekli olup
                          olmadığını kolayca görüntüleyin.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="icon">
                        <i className="far fa-check"></i>
                      </div>
                      <div className="content">
                        <h5> Seçeneğe Özel Tanımlamalar</h5>
                        <p>
                          Ürün seçeneğine özel resim, fiyat ve stok
                          tanımlamaları yapın.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="management-images float-lg-end my-40"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/featurePageLogos/secenekli-urun-ekleme.png"
                  alt="Management"
                />
                <div className="management-over">
                  <img
                    src="/assets/landingPage/featurePageLogos/secenekli-urun-ekleme-2.png"
                    alt="Management"
                    data-aos="fade-right"
                    data-aos-delay="50"
                    data-aos-duration="1500"
                    data-aos-offset="50"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Service Management Area End --> */}

      {/* <!-- About Area Five Start --> */}
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Akıllı Ürün
                    Kataloğu
                  </span>
                  <h2>Benzersiz Özellikler</h2>
                </div>
                <p>
                  Tüm ürün kaynaklarınızı Entekas üzerinde birleştirin. Satış
                  kanallarınızdaki ürünleri otomatik ürün eşleştirme özelliği
                  ile eşitleyin. Eklenen her ürün kendi e-ticaret sitenizde de
                  yayınlansın. Entekas sayesinde tek bir tık ile ürünleriniz
                  e-ticaret sitenizde de yayınlansın.
                </p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-chart-pie"></i> Toplu Ürün Gönderimi
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Dilediğiniz pazaryerine toplu ürün gönderimi sağlayın.
                  </li>

                  <li>
                    <i className="far fa-copy"></i> Bundle (Set) Ürün
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Ürünlerinizi set haline getirip pazaryerlerinde satışa
                    çıkarın.
                  </li>
                  <li>
                    <i className="far fa-bell"></i>Kritik Stok Tanımlama
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Kritik stok tanımları ile stoğunuz kritik eşiğe geldiğinde
                    uyarı alın.
                  </li>
                  <li>
                    <i className="fas fa-plus"></i>Hızlı Ürün Ekleme
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Trendyol hızlı ürün ekleme ile hem tekil hem de seçenekli
                    ürünleri bir kaç saniyede yükleyin.
                  </li>
                  <li>
                    <i className="far fa-list-alt"></i>Toplu Ürün Eşitleme
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Ürünlerinizin kategori, seçenek ve marka eşitlemelerini bir
                    kaç tık ile gerçekleştirin.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/featurePageLogos/featureDetail4.png"
                  alt="About"
                />
                <img
                  src="/assets/landingPage/featurePageLogos/featureDetail3.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- About Area End --> */}

      {/* <!-- FeedbackArea Start --> */}
      <section className="feedback-area-five bgc-lighter pt-125 rpt-105 pb-80 rpb-60 rel z-1">
        <div className="container">
          <div
            className="section-title text-center mb-55"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="50"
          ></div>
          <div className="row gap-50">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="management-images float-lg-end my-40"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/featurePageLogos/toplu-urun-ekleme.png"
                  alt="Management"
                />
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="service-mamagement-content mt-40 rmb-20"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div style={{ display: "flex", paddingBottom: 20 }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      height: screens.xs ? 38 : 47,
                      width: screens.xs ? 40 : 47,
                      padding: screens.xs
                        ? "4px 6px 8px 4px"
                        : "7px 12px 10px 10px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      marginRight: 4,
                    }}
                  >
                    <img src="/assets/innerPageLogos/dinazorsLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/trendyolLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/hepsiburadaLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/n11Logo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/ciceksepetiLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/amazonLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/bizimhesapLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/parasutLogo.png" />
                  </div>
                  <div>
                    <img src="/assets/innerPageLogos/elogoLogo.png" />
                  </div>
                </div>
                <div className="section-title mb-30">
                  <h2>Toplu Ürün Aktarımı</h2>
                </div>
                <p>
                  Aktarım sihirbazı ile dilediğiniz pazaryerlerine toplu ürün
                  aktarımını başlatabilirsiniz.
                </p>
                <div className="row pt-25">
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="content">
                        <h5 style={{ paddingBottom: 20 }}>
                          Aktarım sihirbazı ile dilediğiniz pazaryerlerine toplu
                          ürün aktarımını başlatabilirsiniz.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="content">
                        <h5 style={{ paddingBottom: 20 }}>
                          Bir kaç kolay adımda tüm ürünlerinizi inceleyip akıllı
                          raporlar ile aktarım süreçlerinizi hızlandırıyoruz.
                        </h5>
                        <a
                          onClick={() => navigate("/kayit-ol")}
                          className="theme-btn"
                        >
                          ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- FeedbackArea End --> */}
    </>
  );
};

export default AddingSmartProduct;
