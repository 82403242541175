import { Form, Input, Select } from "antd";
import { max, required } from "../../../helpers/FormRuleHelper";

interface IHepsiburadaApiFormProps {
  form: any;
}

const HepsiburadaApiForm = (props: IHepsiburadaApiFormProps) => {
  return (
    <Form form={props.form} layout="vertical" initialValues={{ active: false }}>
      <Form.Item
        name="apiKey"
        label="API KEY (Merchantid)"
        rules={[required, max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="apiSecret"
        label="API SECRET"
        rules={[required, max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item name="active" label="Entegrasyon Durumu" rules={[required]}>
        <Select
          options={[
            { label: "Entegrasyon Açık", value: true },
            { label: "Entegrasyon Kapalı", value: false },
          ]}
        />
      </Form.Item>
    </Form>
  );
};

export default HepsiburadaApiForm;
