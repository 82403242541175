import { Row, Col, Tooltip, Button, Typography, message, Spin } from "antd";
import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import DefaultCard from "../common/DefaultCard";
import { useCallback, useEffect, useState } from "react";
import { ServiceSettingsApi } from "../../client/Api";
import { useForm } from "antd/es/form/Form";

const { Text } = Typography;

interface IApiSettingsCardProps {
  serviceName: string;
  apiFormComponent: any;
}

const ApiSettingsCard = (props: IApiSettingsCardProps) => {
  const [apiForm] = useForm();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [fields, setFields] = useState<any>();

  const getServiceSettings = useCallback(() => {
    setLoading(true);
    ServiceSettingsApi.getServiceSettings(props.serviceName)
      .then((response) => setFields(response.fields))
      .finally(() => setLoading(false));
  }, [props.serviceName]);

  useEffect(() => {
    getServiceSettings();
  }, [getServiceSettings]);

  useEffect(() => {
    if (fields) apiForm.setFieldsValue(fields);
    else apiForm.resetFields();
  }, [fields, apiForm]);

  const updateAction = () => {
    apiForm.validateFields().then((values) => {
      setActionLoading(true);
      ServiceSettingsApi.updateServiceSettings(props.serviceName, values)
        .then((response) => {
          getServiceSettings();
          message.success(response.message);
        })
        .finally(() => setActionLoading(false));
    });
  };

  const clearAction = () => {
    setActionLoading(true);
    ServiceSettingsApi.deleteServiceSettings(props.serviceName)
      .then((response) => {
        getServiceSettings();
        message.success(response.message);
      })
      .finally(() => setActionLoading(false));
  };

  return loading ? (
    <Row justify="center">
      <Spin />
    </Row>
  ) : (
    <Row justify="space-between" gutter={[32, 32]}>
      <Col lg={12} xs={24}>
        <DefaultCard
          header={
            <Row justify="space-between">
              <Text>API Bilgilerini Tanımlama</Text>
              <Tooltip title="API Bilgilerini Temizle">
                <Button
                  loading={actionLoading}
                  icon={<ClearOutlined />}
                  onClick={() => clearAction()}
                />
              </Tooltip>
            </Row>
          }
          footer={
            <Row justify="end">
              <Button
                loading={actionLoading}
                type="primary"
                icon={<SaveOutlined />}
                className="customPrimaryFormButton"
                onClick={() => updateAction()}
              >
                Güncelle
              </Button>
            </Row>
          }
        >
          <props.apiFormComponent form={apiForm} />
        </DefaultCard>
      </Col>
      <Col lg={12} xs={24}>
        <DefaultCard header="Kurulum Rehberi">
          TODO: Buraya bilgi gelecek
        </DefaultCard>
      </Col>
    </Row>
  );
};

export default ApiSettingsCard;
