import { Grid } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { useBreakpoint } = Grid;

const OrderManagement = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);
  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className="page-banner-area 
        py-250 rpy-120 rel z-1 bgs-cover text-center"
        style={{
          backgroundImage: "url(/assets/landingPage/bannerBg/newredBg.png)",
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <div className="container">
          <div className="banner-inner pt-70 rpt-60">
            <h1
              className="page-title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-offset="50"
              style={{ color: "rgb(161,104,96)" }}
            >
              Sipariş Yönetimi
            </h1>
            <h4 style={{ paddingBottom: 50 }}>
              Çeşitli çevrimiçi pazaryerlerinden gelen siparişleri tek bir
              merkezi yerden yönetmenizi sağlayan kapsamlı bir araçtır. Bu
              özellik sayesinde, işletmenizin pazaryerlerinde gelen siparişleri
              izlemek, işlemek ve yönetmek çok daha kolay hale gelir.
            </h4>
            <a
              onClick={() => navigate("/kayit-ol")}
              className="theme-btn"
              style={{
                backgroundImage: "none",
                backgroundColor: "rgb(161,104,96)",
              }}
            >
              ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Work Step Area Five Start --> */}
      <section className="work-step-area-five pt-120 rpt-100 pb-100 rpb-80 rel z-1">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div
                className="about-content-five mt-10 mb-20 rmb-55"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i>Kolay Sipariş
                    Yönetimi
                  </span>
                  <h2>Size Sağladığı Avantajlar</h2>
                </div>
                <ul className="icon-list mt-40 mb-40"></ul>
                <a onClick={() => navigate("/iletisim")} className="theme-btn">
                  Detaylı Bilgi <i className="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="numbered-boxes"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="numbered-box style-two">
                  <div className="number">1</div>
                  <div className="content">
                    <h5>Hızlı ve Kolay Sipariş Takibi</h5>
                    <p>
                      Entekas ile tüm pazaryerlerindeki siparişlerinizi tek bir
                      ekrandan takip edebilir, böylece sipariş süreçlerinizi
                      hızlandırarak müşteri memnuniyetini artırabilirsiniz.
                    </p>
                  </div>
                </div>
                <div className="numbered-box style-two">
                  <div className="number">2</div>
                  <div className="content">
                    <h5>Entegre Stok Yönetimi</h5>
                    <p>
                      Entekas'ın sipariş yönetimi özelliği sayesinde
                      stoklarınızı otomatik olarak güncelleyebilir ve stokta
                      tükenme sorunlarının önüne geçebilirsiniz.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Work Step Area End --> */}

      {/* <!-- About Area Five Start --> */}
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                // className="about-images-five rmb-55"
                style={{
                  width: screens.lg ? "74%" : "",
                  height: "100%",
                  display: "flex",
                  justifyContent: screens.lg ? "start" : "center",
                  backgroundColor: "transparent",
                  boxShadow: "0px 0px 60px 0px rgba(23, 105, 254, 0.1)",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 20,
                }}
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/orderManagementPage/1.png"
                  alt="About"
                  style={{ borderRadius: 20, width: screens.lg ? "100%" : "" }}
                />
                {/* <img
                  src="/assets/landingPage/orderManagementPage/7.png"
                  alt="About"
                /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Sipariş Yönetimi
                  </span>
                  <h2>Sipariş Takibi</h2>
                </div>
                <p>Tüm siparişlerinizi tek bir ekrandan kolayca takip edin.</p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="far fa-eye"></i> Merkezi Gözetim
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Tüm pazaryerlerinden gelen siparişleri tek bir platformda
                    görüntüleyin.
                  </li>

                  <li>
                    <i className="fas fa-cloud-download-alt"></i> Gerçek Zamanlı
                    Güncellemeler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Sipariş durumu değişiklikleri anında görüntülenir.
                  </li>
                  <li>
                    <i className="far fa-address-card"></i> Detaylı Sipariş
                    Bilgisi
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Sipariş detaylarını, müşteri bilgilerini ve ürün
                    ayrıntılarını kolayca inceleyin
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- About Area End --> */}

      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Sipariş Yönetimi
                  </span>
                  <h2>Sipariş İşleme</h2>
                </div>
                <p>Siparişlerinizi hızlı ve hatasız bir şekilde işleyin.</p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-file-invoice"></i> Otomatik Faturalama
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Siparişlere otomatik olarak fatura oluşturun.
                  </li>

                  <li>
                    <i className="fas fa-layer-group"></i> Stok Yönetimi
                    Entegrasyonu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Stok durumu anında güncellenir, hatalar minimize edilir.
                  </li>
                  <li>
                    <i className="fas fa-power-off"></i> Hızlı Onay ve İptal
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Siparişleri hızlıca onaylayabilir veya iptal edebilirsiniz.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                // className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
                style={{
                  width: screens.lg ? "74%" : "",
                  height: "100%",
                  display: "flex",
                  justifyContent: screens.lg ? "end" : "center",
                  backgroundColor: "transparent",
                  boxShadow: "0px 0px 60px 0px rgba(23, 105, 254, 0.1)",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                <img
                  src="/assets/landingPage/orderManagementPage/2.png"
                  alt="About"
                  style={{ borderRadius: 20, width: screens.lg ? "100%" : "" }}
                />
                {/* <img
                  src="/assets/landingPage/orderManagementPage/8.png"
                  alt="About"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                // className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
                style={{
                  width: screens.lg ? "74%" : "",
                  height: "100%",
                  display: "flex",
                  justifyContent: screens.lg ? "start" : "center",
                  backgroundColor: "transparent",
                  boxShadow: "0px 0px 60px 0px rgba(23, 105, 254, 0.1)",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                {/* <img
                  src="/assets/landingPage/orderManagementPage/10.png"
                  alt="About"
                /> */}
                <img
                  src="/assets/landingPage/orderManagementPage/3.png"
                  alt="About"
                  style={{ borderRadius: 20, width: screens.lg ? "100%" : "" }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Sipariş Yönetimi
                  </span>
                  <h2> Müşteri İletişimi</h2>
                </div>
                <p>Müşterilerinizle etkili ve hızlı iletişim kurun.</p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-exclamation"></i>Otomatik Bildirimler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Sipariş durumu hakkında müşterilerinize otomatik bildirimler
                    gönderin.
                  </li>

                  <li>
                    <i className="fas fa-headset"></i>Canlı Destek Entegrasyonu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Müşterilerinize anında yardım sağlayabilmek için canlı
                    destek seçeneklerini entegre edin.
                  </li>
                  <li>
                    <i className="fas fa-comments"></i>Geri Bildirim Toplama
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Müşteri memnuniyeti için geri bildirim toplayın ve analiz
                    edin.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Sipariş Yönetimi
                  </span>
                  <h2>Raporlama ve Analiz</h2>
                </div>
                <p>Siparişlerinizin performansını analiz edin ve raporlayın.</p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="far fa-file-word"></i> Detaylı Raporlar
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Sipariş hacmi, teslimat süreleri ve müşteri memnuniyeti gibi
                    metrikleri raporlayın.
                  </li>

                  <li>
                    <i className="fas fa-chart-bar"></i> Grafiksel Analizler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Sipariş trendlerini grafikler üzerinden inceleyin.
                  </li>
                  <li>
                    <i className="fas fa-edit"></i>Özelleştirilebilir Raporlar
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Kendi ihtiyaçlarınıza göre özelleştirilebilen raporlar
                    oluşturun.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                // className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
                style={{
                  width: screens.lg ? "74%" : "",
                  height: "100%",
                  display: "flex",
                  justifyContent: screens.lg ? "end" : "center",
                  backgroundColor: "transparent",
                  boxShadow: "0px 0px 60px 0px rgba(23, 105, 254, 0.1)",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                <img
                  src="/assets/landingPage/orderManagementPage/4.png"
                  alt="About"
                  style={{ borderRadius: 20, width: screens.lg ? "100%" : "" }}
                />
                {/* <img
                  src="/assets/landingPage/orderManagementPage/13.png"
                  alt="About"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>

      {/* <!-- FeedbackArea Start --> */}
      {/* <section className="feedback-area-five bgc-lighter pt-125 rpt-105 pb-80 rpb-60 rel z-1">
        <div className="container">
          <div
            className="section-title text-center mb-55"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="50"
          ></div>
          <div className="row gap-50">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="management-images float-lg-end my-40"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="https://webtendtheme.net/html/2024/akpager/assets/images/about/service-management.png"
                  alt="Management"
                />
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="service-mamagement-content mt-40 rmb-20"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <h2>Toplu Ürün Aktarımı</h2>
                </div>
                <p>
                  Aktarım sihirbazı ile dilediğiniz pazaryerlerine toplu ürün
                  aktarımını başlatabilirsiniz.
                </p>
                <div className="row pt-25">
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="content">
                        <h5 style={{ paddingBottom: 20 }}>
                          Aktarım sihirbazı ile dilediğiniz pazaryerlerine toplu
                          ürün aktarımını başlatabilirsiniz.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="feature-icon-box style-two">
                      <div className="content">
                        <h5 style={{ paddingBottom: 20 }}>
                          Bir kaç kolay adımda tüm ürünlerinizi inceleyip akıllı
                          raporlar ile aktarım süreçlerinizi hızlandırıyoruz.
                        </h5>
                        <a
                          onClick={() => navigate("/kayit-ol")}
                          className="theme-btn"
                        >
                          ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section> */}
      {/* <!-- FeedbackArea End --> */}
    </>
  );
};

export default OrderManagement;
