import { Form } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ContactPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);
  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className="page-banner-area py-250 rpy-120 rel z-1 bgs-cover"
        style={{ backgroundColor: "#eee" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ alignContent: "center" }}>
              <div>
                <h1
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{ paddingBottom: 30, textAlign: "center" }}
                >
                  <b style={{ fontWeight: "lighter", position: "relative" }}>
                    İletişim
                    <img
                      style={{ position: "absolute", left: 0, top: 84 }}
                      src="/assets/landingPage/bannerBg/bottomLine.png"
                    />{" "}
                  </b>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Contact Page Start --> */}
      <section className="contact-page py-130 rpy-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div
                className="section-title text-center mb-55"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one mb-10 p-0 shadow-none">
                    İLETİŞİM
                  </span>
                  <h3>Sizinle iletişime geçmek için sabırsızlanıyoruz</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row gap-100 align-items-center">
            <div className="col-lg-6">
              <div className="contact-info-part">
                <div
                  className="section-title mb-50"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <h3>
                    {" "}
                    <b style={{ position: "relative" }}>
                      Bize Yazın
                      <img
                        style={{ position: "absolute", left: 0, top: 34 }}
                        src="/assets/landingPage/bannerBg/bottomLine.png"
                      />{" "}
                    </b>
                  </h3>

                  <p>
                    Tüm soru ve sorunlarınızda bize mail ile ulaşabilirsiniz.
                    Size en kısa sürede cevap vermiş olacağız.
                  </p>
                </div>

                <div
                  className="contact-info-item style-two"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <div className="icon">
                    <i className="far fa-envelope-open-text"></i>
                  </div>
                  <div className="content">
                    <span className="title">Email Address</span>
                    <span className="text">
                      <a href="mailto:support@gmail.com">support@gmail.com</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-info-part">
                <div
                  className="section-title mb-50"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <h3>
                    {" "}
                    <b style={{ position: "relative" }}>
                      Telefon İle Destek
                      <img
                        style={{ position: "absolute", left: 0, top: 34 }}
                        src="/assets/landingPage/bannerBg/bottomLine.png"
                      />{" "}
                    </b>
                  </h3>

                  <p>
                    Haftaiçi 10:00-13:00 ile 14:00-17:00 saatleri arasında her
                    türlü soru, öneri ve isteğiniz için bizimle iletişime
                    geçebilirsiniz.
                  </p>
                </div>

                <div
                  className="contact-info-item style-two"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <div className="icon">
                    <i className="fal fa-phone-volume"></i>
                  </div>
                  <div className="content">
                    <span className="title">Make A Call</span>
                    <span className="text">
                      <a href="calto:+000(123)456889">+000 (123) 456 889</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Contact Page End --> */}

      {/* <!-- Location Map Area Start --> */}
      <div className="contact-page-map" style={{ padding: 50 }}>
        <div className="our-location">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3009.3471473486884!2d28.99263071255051!3d41.039536971226745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab76818a2a3f5%3A0xddc67a270befe8ce!2zQkpLIMSwTsOWTsOcIFNUQURJ!5e0!3m2!1str!2str!4v1717086263321!5m2!1str!2str"
            style={{ border: 0, width: "100%" }}
            loading="lazy"
            //   referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactPage;
