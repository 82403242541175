import { Col, Row, Tabs } from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import ApiSettingsCard from "../../components/settings/ApiSettingsCard";
import ParasutApiForm from "../../components/forms/settings/ParasutApiForm";

const ParasutSettingsPage = () => {
  const items = [
    {
      label: "API Tanımlama",
      key: "apiSettings",
      children: (
        <ApiSettingsCard
          serviceName="parasut"
          apiFormComponent={ParasutApiForm}
        />
      ),
    },
  ];

  return (
    <>
      <InnerHeader
        firstPageName="Ayarlar"
        secondPageName="Muhasebe Ayarları"
        secondPageLink="/muhasebe"
        thirdPageName="Parasut Ayarlar"
      />
      <Row justify="center" style={{ padding: "16px 32px 32px 32px" }}>
        <Col span={24}>
          <Tabs defaultActiveKey="apiSettings" items={items} />
        </Col>
      </Row>
    </>
  );
};

export default ParasutSettingsPage;
