import { Form, Input, Select, Checkbox } from "antd";
import { useState } from "react";
import { BrandApi } from "../../client/EntityApi";
import { required } from "../../helpers/FormRuleHelper";
import SearchSelect from "../common/SearchSelect";

const matchBrandOptions = [
  {
    label: "Trendyol",
    value: "trendyol",
  },
  {
    label: "Dinazors",
    value: "dinazors",
  },
  {
    label: "N11",
    value: "n11",
  },
  {
    label: "Çiçeksepeti",
    value: "ciceksepeti",
  },
];

interface IBrandMatchFormProps {
  form: any;
  serviceName: string;
  setServiceName: (serviceName: any) => void;
}

const BrandMatchForm = (props: IBrandMatchFormProps) => {
  const [isVisibleMatchField, setIsVisibleMatchField] = useState(true);

  return (
    <Form
      form={props.form}
      layout="vertical"
      initialValues={{ removeMatch: false }}
      onValuesChange={(_, values) => {
        if (values.removeMatch) {
          values.matchId = null;
          values.matchName = null;
          props.form.setFieldsValue(values);
          setIsVisibleMatchField(false);
        } else {
          setIsVisibleMatchField(true);
        }
      }}
    >
      <Form.Item hidden name="id" rules={[required]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Eşitlenecek Servis"
        name="serviceName"
        rules={[required]}
      >
        <Select
          options={matchBrandOptions}
          onSelect={(value) => {
            props.setServiceName(value);
            props.form.resetFields(["matchId", "matchName"]);
          }}
        />
      </Form.Item>
      <Form.Item name="removeMatch" valuePropName="checked">
        <Checkbox>Eşitlemeyi Kaldır</Checkbox>
      </Form.Item>
      {isVisibleMatchField && props.serviceName !== "" && (
        <>
          <Form.Item hidden name="matchName">
            <Input />
          </Form.Item>
          <Form.Item
            label="Eşitlenecek Marka"
            name="matchId"
            rules={[required]}
          >
            <SearchSelect
              onSelect={(_, option) =>
                props.form.setFieldValue("matchName", option.name)
              }
              customSearchRequest={(
                searchValue: any,
                setOptions: (data: any[]) => void,
                setLoading: (loading: boolean) => void
              ) => {
                BrandApi.searchBrands(searchValue, props.serviceName)
                  .then((response) => {
                    setOptions(
                      response.map((item: any) => ({
                        ...item,
                        label: item.name,
                        value: item.id,
                      }))
                    );
                  })
                  .finally(() => setLoading(false));
              }}
            />
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default BrandMatchForm;
