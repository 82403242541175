import { Grid } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { useBreakpoint } = Grid;

const FlexReports = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);
  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className="page-banner-area 
        py-250 rpy-120 rel z-1 bgs-cover text-center"
        style={{
          backgroundImage: "url(/assets/landingPage/bannerBg/newredBg.png)",
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <div className="container">
          <div className="banner-inner pt-70 rpt-60">
            <h1
              className="page-title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-offset="50"
              style={{ color: "rgb(161,104,96)" }}
            >
              Esnek Raporlar
            </h1>

            <h4 style={{ paddingBottom: 50 }}>
              Entekas'ın Akıllı Ürün Kataloğu özelliği, çevrimiçi
              pazaryerlerindeki ürünlerinizi tek bir yerden kolayca yönetmenize
              olanak tanır. Bu özellik sayesinde, ürünlerinizi listelemek,
              stokları takip etmek ve fiyatları güncellemek çok daha hızlı ve
              basit olur.
            </h4>
            <a
              onClick={() => navigate("/kayit-ol")}
              className="theme-btn"
              style={{
                backgroundImage: "none",
                backgroundColor: "rgb(161,104,96)",
              }}
            >
              ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Work Step Area Five Start --> */}
      <section className="work-step-area-five pt-120 rpt-100 pb-100 rpb-80 rel z-1">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div
                className="about-content-five mt-10 mb-20 rmb-55"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i>Esnek Raporlar
                  </span>
                  <h2>Size Sağladığı Avantajlar</h2>
                </div>
                <ul className="icon-list mt-40 mb-40"></ul>
                <a onClick={() => navigate("/iletisim")} className="theme-btn">
                  Detaylı Bilgi <i className="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="numbered-boxes"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="numbered-box style-two">
                  <div className="number">1</div>
                  <div className="content">
                    <h5>Kapsamlı Veri Analizi</h5>
                    <p>
                      Entekas, özelleştirilebilir raporlama seçenekleri ile
                      satış performansınızı detaylı analiz etmenizi sağlar ve iş
                      stratejilerinizi veri odaklı olarak belirlemenize yardımcı
                      olur.
                    </p>
                  </div>
                </div>
                <div className="numbered-box style-two">
                  <div className="number">2</div>
                  <div className="content">
                    <h5>Kolay Erişilebilir Bilgiler</h5>
                    <p>
                      Entekas'ın esnek raporlama sistemi, ihtiyacınız olan
                      verilere hızlı ve kolay bir şekilde ulaşmanızı sağlayarak,
                      iş süreçlerinizi daha verimli hale getirir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Work Step Area End --> */}

      {/* <!-- About Area Five Start --> */}
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                // className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
                style={{
                  width: screens.lg ? "74%" : "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: screens.lg ? "start" : "center",
                  backgroundColor: "#F4F4F4",
                  boxShadow: "0px 0px 60px 0px rgba(23, 105, 254, 0.1)",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                <img
                  src="/assets/landingPage/flexReportsPage/5.png"
                  alt="About"
                  style={{ borderRadius: 20, width: screens.lg ? "100%" : "" }}
                />
                {/* <img
                  src="/assets/landingPage/images/about/about-five2.png"
                  alt="About"
                /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Esnek Raporlar
                  </span>
                  <h2>Kullanıcı Dostu Arayüz</h2>
                </div>
                {/* <p> Stoklarınızı anlık olarak takip edin ve güncel tutun.</p> */}
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-location-arrow"></i> Kolay Navigasyon
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Kullanıcıların ihtiyaç duydukları raporlara hızlıca
                    erişmelerini sağlayan sezgisel menüler.
                  </li>
                  <li>
                    <i className="fas fa-ellipsis-h"></i>
                    Özelleştirilebilir Görünüm
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Kullanıcılar raporlarının görünümünü ve düzenini kendi
                    ihtiyaçlarına göre özelleştirebilirler.
                  </li>
                  <li>
                    <i className="far fa-address-card"></i> Sürükle-Bırak
                    Özelliği
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Rapor oluştururken sürükle-bırak yöntemiyle kolayca veri
                    ekleme imkanı.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- About Area End --> */}

      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Esnek Raporlar
                  </span>
                  <h2>Gelişmiş Filtreleme Seçenekleri</h2>
                </div>
                {/* <p>
                  Stok bilgilerinizi kolayca güncelleyin ve senkronize edin.
                </p> */}
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-file-invoice"></i>Dinamik Filtreler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Veri setlerini anlık olarak filtreleme ve daraltma
                    imkanları.
                  </li>

                  <li>
                    <i className="fas fa-layer-group"></i> Kapsamlı Kriterler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Çok sayıda filtreleme kriteri ile detaylı analiz yapabilme.
                  </li>
                  <li>
                    <i className="fas fa-save"></i>Kaydedilebilir Filtreler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Sık kullanılan filtreleme seçeneklerini kaydederek zaman
                    kazanma.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                // className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
                style={{
                  width: screens.lg ? "74%" : "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: screens.lg ? "end" : "center",
                  backgroundColor: "#F4F4F4",
                  boxShadow: "0px 0px 60px 0px rgba(23, 105, 254, 0.1)",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                <img
                  src="/assets/landingPage/flexReportsPage/6.png"
                  alt="About"
                  style={{ borderRadius: 20, width: screens.lg ? "100%" : "" }}
                />
                {/* <img
                  src="/assets/landingPage/images/about/about-five2.png"
                  alt="About"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                // className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
                style={{
                  width: screens.lg ? "74%" : "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: screens.lg ? "start" : "center",
                  backgroundColor: "#F4F4F4",
                  boxShadow: "0px 0px 60px 0px rgba(23, 105, 254, 0.1)",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                <img
                  src="/assets/landingPage/flexReportsPage/7.png"
                  alt="About"
                  style={{ borderRadius: 20, width: screens.lg ? "100%" : "" }}
                />
                {/* <img
                  src="/assets/landingPage/images/about/about-five2.png"
                  alt="About"
                /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Esnek Raporlar
                  </span>
                  <h2>Detaylı Veri Analizi</h2>
                </div>
                {/* <p>
                  Gelecekteki stok ihtiyaçlarınızı planlayın ve optimize edin.
                </p> */}
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-solar-panel"></i>Çok Boyutlu Analiz
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Verileri farklı boyutlarda inceleme ve karşılaştırma yapma
                    olanağı.
                  </li>

                  <li>
                    <i className="fas fa-chart-pie"></i>Grafik ve Tablo
                    Seçenekleri
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Verileri grafikler ve tablolar halinde görselleştirme.
                  </li>
                  <li>
                    <i className="fas fa-database"></i>Veri İhracı
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Analiz sonuçlarını CSV, Excel gibi formatlarda dışa aktarma.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Esnek Raporlar
                  </span>
                  <h2> Otomatik Raporlama</h2>
                </div>
                {/* <p>
                  Esnek Raporlarnizi diğer sistemlerle sorunsuz bir şekilde
                  entegre edin.
                </p> */}
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-stopwatch"></i>Zamanlanmış Raporlar
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Belirlenen zaman aralıklarında otomatik olarak rapor
                    oluşturma.
                  </li>

                  <li>
                    <i className="far fa-bell"></i>Bildirimler
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Rapor hazırlandığında e-posta veya uygulama içi
                    bildirimlerle kullanıcıyı bilgilendirme.
                  </li>
                  <li>
                    <i className="fas fa-digital-tachograph"></i>Önceden Tanımlı
                    Şablonlar
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Hazır rapor şablonları ile hızlı ve kolay raporlama.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                // className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
                style={{
                  width: screens.lg ? "74%" : "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: screens.lg ? "end" : "center",
                  backgroundColor: "#F4F4F4",
                  boxShadow: "0px 0px 60px 0px rgba(23, 105, 254, 0.1)",
                  marginTop: 20,
                  marginBottom: 20,
                  borderRadius: 20,
                }}
              >
                <img
                  src="/assets/landingPage/flexReportsPage/8.png"
                  alt="About"
                  style={{ borderRadius: 20, width: screens.lg ? "100%" : "" }}
                />
                {/* <img
                  src="/assets/landingPage/images/about/about-five2.png"
                  alt="About"
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
    </>
  );
};

export default FlexReports;
