import { ThemeConfig } from "antd";
import { AliasToken } from "antd/es/theme/internal";

export const defaultSources = {
  logoSrc: "/assets/logo.png",
  loginBackgroundSrc: "/assets/login-bg.jpg",
  defaultAvatar: "/assets/default-avatar.png",
  variantWheel: "/assets/variant-wheel.png",
};

export const heights = {
  headerHeight: 66,
  footerHeight: 30,
  innerHeaderHeight: 120,
};

export const widths = {
  openSiderWidth: 290,
  closeSiderWidth: 80,
  openDrawerWidth: 290,
  tableWidth: 1200,
};

export const colors = {
  primary: "#1769FE",
  primaryBg: "rgba(23, 105, 254, 0.2)",
  contentBg: "#F2F8FF",
  secondary: "#1E90FF",
  complementary: "#82CAFF",
  white: "white",
  danger: "rgb(220, 38, 38)",
  text: "#5B6B79",
  heading: "#000000",
  boxShadow: "rgba(19, 25, 32, 0.08) 0px 8px 24px",
  inputTextColor: "#76838f",
  formBorderColor: "#e4eaec",
};

export const seedToken: Partial<AliasToken> = {
  colorPrimary: colors.primary,
  fontFamily: '"ArboriaMedium", sans-serif',
  fontSize: 15,
  colorText: colors.text,
  colorTextHeading: colors.heading,
  colorBorder: "#ced4da",
  borderRadius: 8,
};

export const componentsToken: ThemeConfig["components"] = {
  Form: {
    labelFontSize: 12,
    labelColor: "black",
    verticalLabelMargin: 0,
    verticalLabelPadding: 2,
    fontFamily: '"Tahoma", sans-serif',
  },
  Input: {
    fontFamily: '"Tahoma", sans-serif',
    borderRadius: 4,
    colorBorder: colors.formBorderColor,
    paddingInline: 14,
    paddingBlock: 4,
    lineHeight: 2,
    colorText: colors.inputTextColor,
    fontSize: 16,
    boxShadow: "none",
    borderRadiusLG: 4,
    fontSizeLG: 16,
  },
  InputNumber: {
    fontFamily: '"Tahoma", sans-serif',
    borderRadius: 4,
    colorBorder: colors.formBorderColor,
    paddingInline: 14,
    paddingBlock: 4,
    lineHeight: 2,
    colorText: colors.inputTextColor,
    fontSize: 16,
    boxShadow: "none",
    borderRadiusLG: 4,
    fontSizeLG: 16,
  },
  Switch: {
    handleSize: 31.9,
    trackPadding: 3.5,
    trackHeight: 38,
    innerMinMargin: 34.5,
    innerMaxMargin: 51.8,
  },
  Select: {
    fontFamily: '"Tahoma", sans-serif',
    borderRadius: 4,
    colorBorder: colors.formBorderColor,
    optionSelectedFontWeight: 400,
    lineHeight: 1,
    controlHeight: 40,
  },
  Cascader: {
    optionSelectedFontWeight: 400,
  },
  Table: {
    fontFamily: '"Tahoma", sans-serif',
    rowSelectedBg: "transparent",
    rowSelectedHoverBg: "transparent",
    cellPaddingBlock: 24,
    cellPaddingInline: 16,
    headerColor: "black",
  },
};
