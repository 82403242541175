import { Col, Grid, Row, Typography } from "antd";
import { colors } from "../../theme";

const { Title } = Typography;
const { useBreakpoint } = Grid;

interface ISummaryCardProps {
  background?: any;
  backgroundImage?: any;
  topText?: any;
  bottomText?: any;
  number?: any;
  icon?: any;
}

const SummaryCard = (props: ISummaryCardProps) => {
  const screens = useBreakpoint();
  return (
    <Col
      xl={6}
      lg={12}
      md={12}
      xs={24}
      className="custom-dashboard-card"
      style={{ height: "100%" }}
    >
      <Row
        style={{
          background: props.background,
          backgroundImage: props.backgroundImage,
          borderRadius: 8,
          boxShadow: "0px 16px 43px 0px rgba(200, 203, 230, 0.57)",
          padding: screens.xl ? 10 : screens.xs ? 20 : 20,
        }}
      >
        <Col span={3}>
          <Row justify="center" align="middle" style={{ height: "100%" }}>
            {props.icon}
          </Row>
        </Col>
        <Col span={19}>
          <Row style={{ color: colors.white }}>
            <Col span={24}>
              <Row justify="center">{props.topText}</Row>
            </Col>
            <Col span={24}>
              <Row justify="center">
                <Title level={2} style={{ margin: 0, color: colors.white }}>
                  {props.number}
                </Title>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="center" style={{ fontSize: 12 }}>
                {props.bottomText}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default SummaryCard;
