import { Form, Input, Row, Col, InputNumber, Select } from "antd";
import { required, min, max } from "../../helpers/FormRuleHelper";
import BrandSelect from "../common/BrandSelect";
import CategorySelect from "../common/CategorySelect";
import ChunkUpload, { getValueFromEvent } from "../common/ChunkUpload";
import DefaultCard from "../common/DefaultCard";
import RichText from "../common/RichText";
import ProductAttributesForm from "./ProductAttributesForm";

interface IProductFormProps {
  isEdit?: boolean;
  form: any;
  defaultCategoryOption?: any;
  defaultBrandOption?: any;
}

const ProductForm = (props: IProductFormProps) => {
  return (
    <Form form={props.form} layout="vertical" initialValues={{ kdv: 20 }}>
      {props.isEdit && (
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
      )}
      <Row style={{ padding: 32, paddingBottom: 94 }} gutter={[24, 24]}>
        <Col lg={16} xs={24}>
          <DefaultCard header="Ürün Bilgileri">
            <Row gutter={[24, 0]}>
              <Col lg={12} xs={24}>
                <Form.Item label="ÜRÜN MARKASI" name="brand">
                  <BrandSelect defaultOption={props.defaultBrandOption} />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item label="ÜRÜN KATEGORİSİ" name="category">
                  <CategorySelect defaultOption={props.defaultCategoryOption} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="ÜRÜN BAŞLIĞI"
                  name="name"
                  rules={[required, min(2), max(150)]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="BARKOD/GTIN" name="barcode">
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="DESİ" name="desi">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    precision={0}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="STOK" name="stock" rules={[required]}>
                  <InputNumber style={{ width: "100%" }} min={0} />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="STOK KODU"
                  name="stockCode"
                  rules={[required, min(1), max(150)]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="ÜRÜN AÇIKLAMASI" name="description">
                  <RichText />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="ÜRÜN RESİMLERİ"
                  name="media"
                  valuePropName="fileList"
                  getValueFromEvent={getValueFromEvent}
                >
                  <ChunkUpload
                    multiple
                    accept=".jpeg, .JPEG, .jpg, .JPG, .png, .PNG"
                    listType="picture-card"
                    style={{ marginBottom: 32 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </DefaultCard>
        </Col>
        <Col lg={8} xs={24}>
          <DefaultCard style={{ width: "100%" }}>
            <Row gutter={[24, 0]}>
              <Col md={12} xs={24}>
                <Form.Item label="KDV ORANI (%)" name="kdv">
                  <Select
                    style={{ width: "100%" }}
                    options={[
                      { label: "20", value: 20 },
                      { label: "18", value: 18 },
                      { label: "10", value: 10 },
                      { label: "8", value: 8 },
                      { label: "1", value: 1 },
                      { label: "0", value: 0 },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="ALIŞ FİYATI" name="buyPrice">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    stringMode
                    precision={2}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="PİYASA(LİSTE) FİYATI" name="listPrice">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    stringMode
                    precision={2}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item label="SATIŞ FİYATI" name="price">
                  <InputNumber
                    style={{ width: "100%" }}
                    min={0}
                    precision={2}
                    stringMode
                  />
                </Form.Item>
              </Col>
            </Row>
          </DefaultCard>
          <DefaultCard style={{ width: "100%", marginTop: 24 }}>
            <Row gutter={[8, 0]}>
              <ProductAttributesForm form={props.form} />
            </Row>
          </DefaultCard>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductForm;
