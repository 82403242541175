import React from "react";
import { AxisOptions, Chart } from "react-charts";
import tableData from "./tableData";

export default function ExampleTable() {
  const { data } = tableData({ series: 3, dataType: "ordinal" });

  const primaryAxis = React.useMemo<
    AxisOptions<(typeof data)[number]["data"][number]>
  >(
    () => ({
      getValue: (datum: any) => 0, //datum.primary,
    }),
    []
  );

  // const primaryAxis = React.useMemo(
  //   () => [{ getValue: (datum: any) => 0 }],
  //   []
  // );

  // <AxisOptions<(typeof data)[number]["data"][number]>[]>
  const secondaryAxes = React.useMemo(
    () => [{ getValue: (datum: any) => 0 }],
    []
  );

  return (
    <Chart
      options={{
        data,
        primaryAxis,
        secondaryAxes,
      }}
    />
  );
}
