import { Alert, Col, Collapse, Row, Space, Spin, Tag } from "antd";
import InnerHeader from "../components/layouts/InnerHeader";
import { useEffect, useState } from "react";
import { BatchRequestCrudApi } from "../client/EntityApi";
import { GeneralApi } from "../client/Api";
import { getConstantValue } from "../helpers/UtilHelper";

const BatchRequestsPage = () => {
  const [loading, setLoading] = useState(false);
  const [batchRequests, setBatchRequests] = useState([]);
  const [items, setItems] = useState<any[]>([]);
  const [constants, setConstants] = useState<any>();

  useEffect(() => {
    setLoading(true);
    GeneralApi.getConstants().then((response) => setConstants(response));
    BatchRequestCrudApi.getAll(0, 10)
      .then((response) => setBatchRequests(response["hydra:member"]))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!constants) return;
    const mappedbatchRequests = batchRequests.map(
      (batchRequest: any, index: number) => {
        const reasons = batchRequest.failReason?.split(";");
        return {
          key: index,
          label: (
            <Row justify="space-between">
              <Col span={24}>{batchRequest.batchRequestId}</Col>
              <Col span={24}>
                <Space>
                  <Tag>
                    {getConstantValue(
                      batchRequest.type,
                      constants.batchRequestTypes
                    )}
                  </Tag>
                  <Tag>
                    {getConstantValue(
                      batchRequest.status,
                      constants.batchRequestStatus
                    )}
                  </Tag>
                  <Tag>
                    {getConstantValue(
                      batchRequest.serviceSetting.service,
                      constants.marketPlaceServices
                    )}
                  </Tag>
                </Space>
              </Col>
            </Row>
          ),
          children: (
            <Space direction="vertical">
              {reasons?.length > 0 && reasons[0] !== "" ? (
                reasons.map((reason: string, index: number) => (
                  <Alert key={index} message={reason} type="error" />
                ))
              ) : batchRequest.status === "wait" ? (
                <Alert message="İşlem sonucu bekleniyor." type="warning" />
              ) : batchRequest.status === "success" ? (
                <Alert message="İşlem başarılı." type="success" />
              ) : (
                ""
              )}
            </Space>
          ),
        };
      }
    );
    setItems(mappedbatchRequests);
  }, [constants, batchRequests]);

  return (
    <>
      <InnerHeader thirdPageName="Entegrasyon Kayıtları" firstPageLink="/" />
      <Row justify="center" style={{ padding: "16px 32px 32px 32px" }}>
        <Col span={24}>{loading ? <Spin /> : <Collapse items={items} />}</Col>
      </Row>
    </>
  );
};

export default BatchRequestsPage;
