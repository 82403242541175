import { Button, Col, Row, Typography } from "antd";
import { FireFilled, CaretRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { colors } from "../../theme";

const { Title } = Typography;

const OfferCard = () => {
  const [countdown, setCountdown] = useState({
    days: 3,
    hours: 6,
    minutes: 15,
    seconds: 10,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown.seconds === 0) {
          if (prevCountdown.minutes === 0) {
            if (prevCountdown.hours === 0) {
              if (prevCountdown.days === 0) {
                clearInterval(interval);
                return prevCountdown;
              } else {
                return {
                  days: prevCountdown.days - 1,
                  hours: 23,
                  minutes: 59,
                  seconds: 59,
                };
              }
            } else {
              return {
                ...prevCountdown,
                hours: prevCountdown.hours - 1,
                minutes: 59,
                seconds: 59,
              };
            }
          } else {
            return {
              ...prevCountdown,
              minutes: prevCountdown.minutes - 1,
              seconds: 59,
            };
          }
        } else {
          return {
            ...prevCountdown,
            seconds: prevCountdown.seconds - 1,
          };
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Row
      gutter={[24, 24]}
      align="middle"
      justify="space-around"
      style={{
        backgroundColor: "#810576",
        color: colors.white,
        minHeight: 60,
        maxWidth: "100%",
      }}
    >
      <Col>
        <Row>
          <FireFilled />
          <Title
            level={3}
            style={{ margin: 0, padding: "0 10px", color: colors.white }}
          >
            KISA SÜRELİ FIRSAT
          </Title>
          <FireFilled />
        </Row>
      </Col>
      <Col>
        Ay Sonu Fırsayını Kaçırma{" "}
        <Button
          icon={<CaretRightOutlined />}
          iconPosition="end"
          style={{
            backgroundColor: "#fab73b",
            color: colors.white,
            border: "none",
          }}
        >
          PAKET SATIN AL
        </Button>
      </Col>
      <Col>{`${countdown.days} GÜN : ${countdown.hours} SAAT : ${countdown.minutes} DAKİKA : ${countdown.seconds} SANİYE`}</Col>
    </Row>
  );
};

export default OfferCard;
