import { Col, Row, Tabs } from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import ApiSettingsCard from "../../components/settings/ApiSettingsCard";
import HepsiburadaApiForm from "../../components/forms/settings/HepsiburadaApiForm";
import ImportProcessCard from "../../components/settings/ImportProcessCard";
import { ServiceIconMatchList } from "../../helpers/ServiceIconMatchHelper";
import React from "react";

const HepsiburadaSettingsPage = () => {
  const items = [
    {
      label: "API Tanımlama",
      key: "apiSettings",
      children: (
        <ApiSettingsCard
          serviceName="hepsiburada"
          apiFormComponent={HepsiburadaApiForm}
        />
      ),
    },
    {
      label: "Ürün Aktarımı",
      key: "importing",
      children: (
        <ImportProcessCard
          serviceName="hepsiburada"
          logo={ServiceIconMatchList.hepsiburada}
          importForm={React.Fragment}
        />
      ),
    },
  ];

  return (
    <>
      <InnerHeader
        firstPageName="Ayarlar"
        secondPageName="Mağaza Ayarları"
        secondPageLink="/pazaryeri"
        thirdPageName="Hepsiburada Ayarlar"
      />
      <Row justify="center" style={{ padding: "16px 32px 32px 32px" }}>
        <Col span={24}>
          <Tabs defaultActiveKey="apiSettings" items={items} />
        </Col>
      </Row>
    </>
  );
};

export default HepsiburadaSettingsPage;
