import { CategoryCrudApi } from "../../client/EntityApi";
import SearchSelect from "./SearchSelect";

const CategorySelect = (props: any) => {
  return (
    <SearchSelect
      {...props}
      placeholder="Kategori ara..."
      customSearchRequest={(
        searchValue: any,
        setOptions: any,
        setLoading: any
      ) => {
        if (searchValue === "") return;
        setLoading(true);
        CategoryCrudApi.getAll(1, 10, searchValue)
          .then((response) =>
            setOptions(
              response["hydra:member"].map((item: any) => ({
                ...item,
                label: item.name,
                value: item["@id"],
              }))
            )
          )
          .finally(() => setLoading(false));
      }}
    />
  );
};

export default CategorySelect;
