import AuthLayout from "../../components/layouts/AuthLayout";
import { defaultSources } from "../../theme";
import RegisterForm from "../../components/forms/RegisterForm";
import { AuthApi } from "../../client/Api";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { message } from "antd";
import { saveCredentials } from "../../helpers/AuthHelper";

const RegisterPage = () => {
  const [registerForm] = useForm();
  const [loading, setLoading] = useState(false);

  const registerAction = () => {
    registerForm.validateFields().then((values: any) => {
      if (values.plainPassword !== values.againPassword) {
        message.warning("Şifreler aynı değil!");
        return;
      }
      setLoading(true);
      AuthApi.register(values)
        .then((response: any) => {
          setLoading(false);
          message.success(response.message);
          saveCredentials(
            response.data.user,
            response.data.accessToken,
            response.data.refreshToken
          );
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <AuthLayout
      logoSrc={defaultSources.logoSrc}
      loginBackgroundSrc={defaultSources.loginBackgroundSrc}
    >
      <RegisterForm
        form={registerForm}
        loading={loading}
        handleRegister={registerAction}
      />
    </AuthLayout>
  );
};

export default RegisterPage;
