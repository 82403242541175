import api from "./AxiosConfig";

export const GeneralApi = {
  getConstants: () => {
    return api.get("constants").then((response: any) => response.data);
  },
};

export const AuthApi = {
  login: (email: string, password: string) => {
    return api
      .post("login", { username: email, password: password })
      .then((response: any) => response.data);
  },
  register: (values: any) => {
    return api.post("register", values).then((response: any) => response.data);
  },
  refreshToken: (refreshToken: string) => {
    return api
      .post("refresh-token", { refreshToken: refreshToken })
      .then((response: any) => response.data);
  },
  profileUpdate: (values: any) => {
    return api.put("profile", values).then((response: any) => response.data);
  },
  renewPassword: (values: any) => {
    return api
      .put("renew-password", values)
      .then((response: any) => response.data);
  },
};

export const ServiceSettingsApi = {
  getServiceSettings: (serviceName: string) => {
    return api
      .get("/service-settings/" + serviceName)
      .then((response: any) => response.data);
  },
  updateServiceSettings: (serviceName: string, params: any) => {
    return api
      .put("/service-settings/" + serviceName, params)
      .then((response: any) => response.data);
  },
  deleteServiceSettings: (serviceName: string) => {
    return api
      .delete("/service-settings/" + serviceName)
      .then((response: any) => response.data);
  },
};

export const TransferApi = {
  importReservation: (serviceName: string, params: any) => {
    return api
      .post("/import-reservation/" + serviceName, params)
      .then((response: any) => response.data);
  },
  transferReservation: (values: any) => {
    return api
      .post("/transfer-reservation", values)
      .then((response: any) => response.data);
  },
};

export const XmlApi = {
  getXmlPattern: (mediaId: string) => {
    return api
      .get("/get-xml-pattern/" + mediaId)
      .then((response: any) => response.data);
  },
  xmlImport: (values: any) => {
    return api
      .post("/xml-import", values)
      .then((response: any) => response.data);
  },
  getXmlTracks: () => {
    return api
      .get("/xml-process-tracks")
      .then((response: any) => response.data);
  },
};
