import { Col, Divider, Row, Typography } from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import ImageCard from "../../components/common/ImageCard";

const { Title } = Typography;

const MarketPlaceServicesPage = () => {
  const items = {
    active: [
      {
        title: "Trendyol",
        color: "#f26725",
        logo: "/assets/brandLogos/trendyolBeyaz.png",
        to: "/trendyol",
        passive: true,
      },
      {
        title: "Dinazors",
        color: "#B7D463",
        logo: "/assets/brandLogos/dinazorsBeyaz.png",
        to: "/dinazors",
      },
      {
        title: "Hepsiburada",
        color: "#ff963f",
        logo: "/assets/brandLogos/hepsiBuradaBeyaz.png",
        to: "/hepsiburada",
      },
      {
        title: "N11",
        color: "#6849c5",
        logo: "/assets/brandLogos/n11Beyaz.png",
        to: "/n11",
      },
      {
        title: "Çiçeksepeti",
        color: "#51B449",
        logo: "/assets/brandLogos/ciceksepetiBeyaz.png",
        to: "/ciceksepeti",
      },
    ],
    comingSoon: [
      {
        title: "Amazon",
        color: "#ff9900",
        logo: "/assets/brandLogos/amazonBeyaz.png",
        to: "/amazon",
      },
      {
        title: "Akakçe",
        color: "#17629e",
        logo: "/assets/brandLogos/akakceBeyaz.png",
        to: "/akakce",
      },
      {
        title: "Pazarama",
        color: "#0136F4",
        logo: "/assets/brandLogos/pazaramaBeyaz.png",
        to: "/pazarama",
      },
      {
        title: "PttAVM",
        color: "#00A7CF",
        logo: "/assets/brandLogos/pttBeyaz.png",
        to: "/pttavm",
      },
    ],
  };

  return (
    <>
      <InnerHeader
        firstPageLink="/"
        secondPageName="Ayarlar"
        thirdPageName="Mağaza Ayarları"
        thirdPageLink="/pazaryeri"
      />
      <Row style={{ padding: 32 }} gutter={[24, 24]}>
        {items.active.map((item, index) => (
          <Col key={index}>
            <ImageCard
              title={item.title}
              src={item.logo}
              color={item.color}
              to={"/pazaryeri" + item.to}
            />
          </Col>
        ))}
        <Divider orientation="left">
          <Title level={3}>Çok Yakında</Title>
        </Divider>
        {items.comingSoon.map((item, index) => (
          <Col key={index}>
            <ImageCard
              title={item.title}
              src={item.logo}
              color={item.color}
              to={item.to}
              comingSoon={true}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default MarketPlaceServicesPage;
