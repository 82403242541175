import { Checkbox, Col, Form, Input, Row, Select, Spin } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { useEffect, useRef, useState } from "react";
import { AttributeApi, CategoryApi } from "../../client/EntityApi";
import SearchSelect from "../common/SearchSelect";

interface IAttributeMatchFormProps {
  form: any;
}

const matchAttributeOptions = [
  {
    label: "Trendyol",
    value: "trendyol",
  },
  {
    label: "Dinazors",
    value: "dinazors",
  },
  {
    label: "Hepsiburada",
    value: "hepsiburada",
  },
];

const AttributeMatchForm = (props: IAttributeMatchFormProps) => {
  const searchSelectRef = useRef<any>(null);

  const [loading, setLoading] = useState(false);
  const [attributeGroupOptions, setAttributeGroupOptions] = useState([]);
  const [attributeValueOptions, setAtributeValueOptions] = useState([]);

  const serviceName = Form.useWatch("serviceName", props.form);
  const removeMatch = Form.useWatch("removeMatch", props.form);
  const categoryId = Form.useWatch("categoryId", props.form);
  const matchId = Form.useWatch("matchId", props.form);

  //Servis tipi yada eşitlemeyi kaldır özelliği her değiştiğinde categoryId ve options'ı sıfırlanır
  useEffect(() => {
    props.form.setFieldValue("categoryId", undefined);
    searchSelectRef.current?.setOptions([]);
  }, [serviceName, removeMatch, props.form]);

  //categoryId undefined ise Eşitlenen Seçenek Grubu sıfırlanıyor
  useEffect(() => {
    if (categoryId !== undefined) return;

    props.form.setFieldValue("matchName", undefined);
    props.form.setFieldValue("matchId", undefined);
  }, [categoryId, props.form]);

  //matchId değiştiğinde Eşitlenen Seçenekler sıfırlanıyor
  useEffect(() => {
    const attributeValuesLength =
      props.form.getFieldValue("attributeValues")?.length ?? 0;
    for (let index = 0; index < attributeValuesLength; index++) {
      props.form.setFieldValue(
        ["attributeValues", index, "matchId"],
        undefined
      );
      props.form.setFieldValue(
        ["attributeValues", index, "matchName"],
        undefined
      );
    }
  }, [matchId, props.form]);

  useEffect(() => {
    if (serviceName === undefined || categoryId === undefined) return;

    setLoading(true);
    AttributeApi.searchAttributes(serviceName, categoryId)
      .then((response) => {
        setAttributeGroupOptions(
          response.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
              attributeValues: item.value.map((valueItem: any) => {
                return {
                  label: valueItem.name,
                  value: valueItem.id,
                };
              }),
            };
          })
        );
      })
      .finally(() => setLoading(false));
  }, [serviceName, categoryId]);

  return (
    <Form
      form={props.form}
      layout="vertical"
      initialValues={{ removeMatch: false }}
    >
      <Form.Item hidden name="id" rules={[required]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Eşitlenecek Servis"
        name="serviceName"
        rules={[required]}
      >
        <Select options={matchAttributeOptions} />
      </Form.Item>
      <Form.Item name="removeMatch" valuePropName="checked">
        <Checkbox>Eşitlemeyi Kaldır</Checkbox>
      </Form.Item>
      {!removeMatch && serviceName !== undefined && (
        <>
          <Form.Item
            label="Kategori Seçiniz"
            name="categoryId"
            rules={[required]}
          >
            <SearchSelect
              ref={searchSelectRef}
              customSearchRequest={(
                searchValue: any,
                setOptions: (data: any[]) => void,
                setLoading: (loading: boolean) => void
              ) => {
                CategoryApi.searchCategories(searchValue, serviceName)
                  .then((response) => {
                    setOptions(
                      response.map((item: any) => ({
                        ...item,
                        label: item.name,
                        value: item.id,
                      }))
                    );
                  })
                  .finally(() => setLoading(false));
              }}
            />
          </Form.Item>
          {categoryId && (
            <>
              {loading ? (
                <Row justify="center">
                  <Spin />
                </Row>
              ) : (
                <>
                  <Row gutter={[8, 8]}>
                    <Form.Item hidden name="id">
                      <Input />
                    </Form.Item>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label="Eşitlenecek Seçenek Grubu"
                        rules={[required]}
                      >
                        <Select disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item hidden name="matchName">
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="matchId"
                        label="Eşitlenen Seçenek Grubu"
                        rules={[required]}
                      >
                        <Select
                          options={attributeGroupOptions}
                          onSelect={(_, option: any) => {
                            props.form.setFieldValue("matchName", option.label);
                            setAtributeValueOptions(option.attributeValues);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {matchId && (
                    <Form.List name="attributeValues">
                      {(fields) => (
                        <>
                          {fields.map(({ key, name }) => (
                            <Row key={key} gutter={[8, 8]}>
                              <Form.Item hidden name={[name, "id"]}>
                                <Input />
                              </Form.Item>
                              <Form.Item hidden name={[name, "matchName"]}>
                                <Input />
                              </Form.Item>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "name"]}
                                  label="Eşitlenecek Seçenek"
                                  rules={[required]}
                                >
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={[name, "matchId"]}
                                  label="Eşitlenen Seçenek"
                                  rules={[required]}
                                >
                                  <Select
                                    style={{ width: "100%" }}
                                    options={attributeValueOptions}
                                    onSelect={(_, option: any) => {
                                      props.form.setFieldValue(
                                        ["attributeValues", name, "matchName"],
                                        option.label
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </Form.List>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Form>
  );
};

export default AttributeMatchForm;
