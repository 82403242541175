import { ConfigProvider } from "antd";
import { componentsToken, seedToken } from "./theme";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import LoginRoute from "./components/common/LoginRoute";
import PrivateRoute from "./components/common/PrivateRoute";
import NotFoundPage from "./pages/common/NotFoundPage";
import LoginPage from "./pages/common/LoginPage";
import Dashboard from "./pages/Dashboard";
import SiderLayout from "./components/layouts/SiderLayout";
import BrandCrudPage from "./pages/crud/BrandCrudPage";
import CategoryCrudPage from "./pages/crud/CategoryCrudPage";
import OrderCrudPage from "./pages/crud/OrderCrudPage";
import ProductCrudPage from "./pages/crud/ProductCrudPage";
import TrendyolSettingsPage from "./pages/settings/TrendyolSettingsPage";
import MarketPlaceServicesPage from "./pages/services/MarketPlaceServicesPage";
import AccountingServicesPage from "./pages/services/AccountingServicesPage";
import EInvoiceServicesPage from "./pages/services/EInvoiceServicesPage";
import locale from "antd/locale/tr_TR";
import ProductEditPage from "./pages/crud/product/ProductEditPage";
import RegisterPage from "./pages/common/RegisterPage";
import ProductAddPage from "./pages/crud/product/ProductAddPage";
import DinazorsSettingsPage from "./pages/settings/DinazorsSettingsPage";
import HepsiburadaSettingsPage from "./pages/settings/HepsiburadaSettingsPage";
import N11SettingsPage from "./pages/settings/N11SettingsPage";
import CicekSepetiSettingsPage from "./pages/settings/CicekSepetiSettingsPage";
import BizimHesapSettingsPage from "./pages/settings/BizimHesapSettingsPage";
import HomePage from "./pages/landing/HomePage";
import LandingLayout from "./components/layouts/LandingLayout";
import AboutPage from "./pages/landing/AboutPage";
import ContactPage from "./pages/landing/ContactPage";
import FeaturesPage from "./pages/landing/FeaturesPage";
import IntegrationsPage from "./pages/landing/IntegrationsPage";
import PricingPage from "./pages/landing/PricingPage";
import BlogsPage from "./pages/landing/BlogsPage";
import SmartProductCatalog from "./pages/landing/features/SmartProductCatalog";
import AddingSmartProduct from "./pages/landing/features/AddingSmartProduct";
import FlexReports from "./pages/landing/features/FlexReports";
import StockManagement from "./pages/landing/features/StockManagement";
import BulkProductTransfer from "./pages/landing/features/BulkProductTransfer";
import OrderManagement from "./pages/landing/features/OrderManagement";
import BlogDetail from "./pages/landing/blog/BlogDetail";
import AttributeCrudPage from "./pages/crud/AttributeCrudPage";
import DinazorsIntegration from "./pages/landing/integration/DinazorsIntegration";
import AmazonIntegration from "./pages/landing/integration/AmazonIntegration";
import HepsiburadaIntegration from "./pages/landing/integration/HepsiburadaIntegration";
import TrendyolIntegration from "./pages/landing/integration/TrendyolIntegration";
import N11Integration from "./pages/landing/integration/N11Integration";
import CiceksepetiIntegration from "./pages/landing/integration/CiceksepetiIntegration";
import BizimhesapIntegration from "./pages/landing/integration/BizimhesapIntegration";
import ParasutIntegration from "./pages/landing/integration/ParasutIntegration";
import ElogoIntegration from "./pages/landing/integration/ElogoIntegration";
import BatchRequestsPage from "./pages/BatchRequestsPage";
import XmlImportPage from "./pages/XmlImportPage";
import ParasutSettingsPage from "./pages/settings/ParasutSettingsPage";
import LogoEFaturaSettingsPage from "./pages/settings/LogoEFaturaSettingsPage";
import Erp from "./pages/landing/features/Erp";
import EInvoice from "./pages/landing/features/EInvoice";
import XmlIntegration from "./pages/landing/features/XmlIntegration";

const App = () => {
  const getPrivateRoutes = () => {
    let routes = [
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/siparisler", element: <OrderCrudPage /> },
      { path: "/urunler", element: <ProductCrudPage /> },
      { path: "/entegrasyon-kayitlari", element: <BatchRequestsPage /> },
      { path: "/xml-ice-aktarim", element: <XmlImportPage /> },
      { path: "/kategoriler", element: <CategoryCrudPage /> },
      { path: "/markalar", element: <BrandCrudPage /> },
      { path: "/secenekler", element: <AttributeCrudPage /> },
      { path: "/pazaryeri", element: <MarketPlaceServicesPage /> },
      { path: "/muhasebe", element: <AccountingServicesPage /> },
      { path: "/efatura", element: <EInvoiceServicesPage /> },
      { path: "/pazaryeri/trendyol", element: <TrendyolSettingsPage /> },
      { path: "/pazaryeri/dinazors", element: <DinazorsSettingsPage /> },
      { path: "/pazaryeri/hepsiburada", element: <HepsiburadaSettingsPage /> },
      { path: "/pazaryeri/n11", element: <N11SettingsPage /> },
      { path: "/pazaryeri/ciceksepeti", element: <CicekSepetiSettingsPage /> },
      { path: "/muhasebe/bizimhesap", element: <BizimHesapSettingsPage /> },
      { path: "/muhasebe/parasut", element: <ParasutSettingsPage /> },
      { path: "/efatura/logo-efatura", element: <LogoEFaturaSettingsPage /> },
      { path: "/urunler/ekle", element: <ProductAddPage /> },
      { path: "/urunler/duzenle/:id", element: <ProductEditPage /> },
    ];

    return routes;
  };

  const getPublicRoutes = () => {
    return [
      { path: "/", element: <HomePage /> },
      { path: "/hakkimizda", element: <AboutPage /> },
      { path: "/iletisim", element: <ContactPage /> },
      { path: "/ozellikler", element: <FeaturesPage /> },
      { path: "/entegrasyonlar", element: <IntegrationsPage /> },
      { path: "/fiyatlandirma", element: <PricingPage /> },
      { path: "/blog", element: <BlogsPage /> },
      {
        path: "/ozellikler/akilli-urun-katalogu",
        element: <SmartProductCatalog />,
      },
      { path: "/ozellikler/siparis-yonetimi", element: <OrderManagement /> },
      {
        path: "/ozellikler/akilli-urun-ekleme",
        element: <AddingSmartProduct />,
      },
      {
        path: "/ozellikler/esnek-raporlar",
        element: <FlexReports />,
      },
      {
        path: "/ozellikler/stok-yonetimi",
        element: <StockManagement />,
      },
      {
        path: "/ozellikler/toplu-urun-aktarimi",
        element: <BulkProductTransfer />,
      },
      {
        path: "/ozellikler/erp-entegrasyonu",
        element: <Erp />,
      },
      {
        path: "/ozellikler/e-fatura",
        element: <EInvoice />,
      },
      {
        path: "/ozellikler/tedarikci-xml-entegrasyonu",
        element: <XmlIntegration />,
      },
      { path: "/entegrasyonlar/dinazors", element: <DinazorsIntegration /> },
      { path: "/entegrasyonlar/amazon", element: <AmazonIntegration /> },
      { path: "/entegrasyonlar/trendyol", element: <TrendyolIntegration /> },
      {
        path: "/entegrasyonlar/hepsiburada",
        element: <HepsiburadaIntegration />,
      },
      { path: "/entegrasyonlar/n11", element: <N11Integration /> },
      {
        path: "/entegrasyonlar/ciceksepeti",
        element: <CiceksepetiIntegration />,
      },
      {
        path: "/entegrasyonlar/bizimhesap",
        element: <BizimhesapIntegration />,
      },
      { path: "/entegrasyonlar/parasut", element: <ParasutIntegration /> },
      { path: "/entegrasyonlar/elogo", element: <ElogoIntegration /> },
      { path: "/blog/1", element: <BlogDetail /> },
    ];
  };

  const router = createBrowserRouter([
    {
      path: "/giris",
      element: <LoginRoute redirectPath="/dashboard" element={<LoginPage />} />,
    },
    {
      path: "/kayit-ol",
      element: (
        <LoginRoute redirectPath="/dashboard" element={<RegisterPage />} />
      ),
    },
    {
      path: "/",
      element: (
        <LandingLayout>
          <Outlet />
        </LandingLayout>
      ),
      children: getPublicRoutes(),
    },
    {
      path: "/",
      element: <PrivateRoute loginPath="giris" layout={SiderLayout} />,
      children: getPrivateRoutes(),
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);

  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: seedToken,
        components: componentsToken,
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default App;
