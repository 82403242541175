import "./styles/general.css";
import "react-quill/dist/quill.snow.css";
import App from "./App";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
