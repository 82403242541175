import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const StockManagement = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);
  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className="page-banner-area 
        py-250 rpy-120 rel z-1 bgs-cover text-center"
        style={{
          backgroundImage: "url(/assets/landingPage/bannerBg/newyellowBg.png)",
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <div className="container">
          <div className="banner-inner pt-70 rpt-60">
            <h1
              className="page-title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-offset="50"
              style={{ color: "rgb(163,136,83)" }}
            >
              Stok Yönetimi
            </h1>

            <h4 style={{ paddingBottom: 50 }}>
              Entekas'ın Akıllı Ürün Kataloğu özelliği, çevrimiçi
              pazaryerlerindeki ürünlerinizi tek bir yerden kolayca yönetmenize
              olanak tanır. Bu özellik sayesinde, ürünlerinizi listelemek,
              stokları takip etmek ve fiyatları güncellemek çok daha hızlı ve
              basit olur.
            </h4>
            <a
              onClick={() => navigate("/kayit-ol")}
              className="theme-btn"
              style={{
                backgroundImage: "none",
                backgroundColor: "rgb(163,136,83)",
              }}
            >
              ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Work Step Area Five Start --> */}
      <section className="work-step-area-five pt-120 rpt-100 pb-100 rpb-80 rel z-1">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div
                className="about-content-five mt-10 mb-20 rmb-55"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i>Kolay Stok Yönetimi
                  </span>
                  <h2>Size Sağladığı Avantajlar</h2>
                </div>
                <ul className="icon-list mt-40 mb-40"></ul>
                <a onClick={() => navigate("/iletisim")} className="theme-btn">
                  Detaylı Bilgi <i className="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="numbered-boxes"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="numbered-box style-two">
                  <div className="number">1</div>
                  <div className="content">
                    <h5>Otomatik Stok Güncellemeleri</h5>
                    <p>
                      Entekas, tüm pazaryerlerindeki stok bilgilerinizi otomatik
                      olarak senkronize eder, böylece yanlış stok bilgisi ile
                      satış yapma riskini ortadan kaldırır.
                    </p>
                  </div>
                </div>
                <div className="numbered-box style-two">
                  <div className="number">2</div>
                  <div className="content">
                    <h5>Verimli Stok Yönetimi</h5>
                    <p>
                      Entekas, stok seviyelerinizi anlık olarak izleyerek,
                      envanter yönetiminizi optimize eder ve stokta tükenme veya
                      fazla stok tutma problemlerini minimize eder.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Work Step Area End --> */}

      {/* <!-- About Area Five Start --> */}
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/stockManagementPage/14.png"
                  alt="About"
                />
                <img
                  src="/assets/landingPage/stockManagementPage/stok-takibi.png"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Stok Yönetimi
                  </span>
                  <h2>Stok Takibi</h2>
                </div>
                <p> Stoklarınızı anlık olarak takip edin ve güncel tutun.</p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="far fa-eye"></i> Gerçek Zamanlı Stok İzleme
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Stok seviyelerinizi anında görebilir ve anlık güncellemeler
                    alabilirsiniz.
                  </li>

                  <li>
                    <i className="fas fa-exclamation"></i> Düşük Stok Uyarıları
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Stok seviyeleri belirli bir eşiğin altına düştüğünde
                    otomatik uyarılar alırsınız
                  </li>
                  <li>
                    <i className="far fa-address-card"></i> Detaylı Stok
                    Raporları
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Stok hareketlerini ve durumlarını detaylı raporlarla
                    izleyebilirsiniz.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- About Area End --> */}

      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Stok Yönetimi
                  </span>
                  <h2>Stok Güncelleme</h2>
                </div>
                <p>
                  Stok bilgilerinizi kolayca güncelleyin ve senkronize edin.
                </p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-file-invoice"></i>Toplu Stok Güncelleme
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Birden fazla ürünü aynı anda güncelleyerek zamandan tasarruf
                    edin.
                  </li>

                  <li>
                    <i className="fas fa-redo-alt"></i> Otomatik Senkronizasyon
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Stok bilgileriniz tüm platformlarda otomatik olarak
                    güncellenir.
                  </li>
                  <li>
                    <i className="far fa-edit"></i>Esnek Düzenleme Seçenekleri
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Ürün detaylarını ve stok seviyelerini hızlıca
                    düzenleyebilirsiniz.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/stockManagementPage/17.png"
                  alt="About"
                />
                <img
                  src="/assets/landingPage/stockManagementPage/16.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/images/about/about-five1.png"
                  alt="About"
                />
                <img
                  src="/assets/landingPage/images/about/about-five2.png"
                  alt="About"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Stok Yönetimi
                  </span>
                  <h2> Stok Planlama</h2>
                </div>
                <p>
                  Gelecekteki stok ihtiyaçlarınızı planlayın ve optimize edin.
                </p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fas fa-magic"></i>Talep Tahmini
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Geçmiş satış verilerine dayanarak gelecekteki stok
                    ihtiyaçlarınızı tahmin eder.
                  </li>

                  <li>
                    <i className="fas fa-business-time"></i>Otomatik Yeniden
                    Sipariş
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Belirlenen kriterlere göre otomatik yeniden sipariş
                    oluşturur.
                  </li>
                  <li>
                    <i className="fas fa-layer-group"></i>Stok Seviyesi
                    Optimizasyonu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Envanterinizi optimum seviyede tutarak gereksiz maliyetleri
                    azaltın.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section> */}
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Stok Yönetimi
                  </span>
                  <h2>Entegrasyonlar</h2>
                </div>
                <p>
                  Stok yönetiminizi diğer sistemlerle sorunsuz bir şekilde
                  entegre edin.
                </p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="far fa-file-word"></i>Pazaryeri Entegrasyonu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Stok bilgileriniz tüm pazaryerleriyle uyumlu hale gelir.
                  </li>

                  <li>
                    <i className="fas fa-chart-bar"></i> ERP Sistemi
                    Entegrasyonu
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    İşletmenizin ERP sistemi ile kolayca entegre edilebilir.
                  </li>
                  <li>
                    <i className="fas fa-edit"></i>API Desteği
                  </li>
                  <li style={{ fontWeight: "normal" }}>
                    Kendi sistemlerinizle entegrasyon sağlamak için geniş API
                    desteği sunar.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-images-five rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/stockManagementPage/18.png"
                  alt="About"
                />
                <img
                  src="/assets/landingPage/stockManagementPage/19.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
    </>
  );
};

export default StockManagement;
