import { Button, Image, Popover, Row, TableColumnType, Tooltip } from "antd";
import { getConstantValue, getUploadUrl } from "../../helpers/UtilHelper";
import SearchSelect from "../common/SearchSelect";
import { BrandCrudApi, CategoryCrudApi } from "../../client/EntityApi";
import { Link } from "react-router-dom";
import { ServiceIconMatchList } from "../../helpers/ServiceIconMatchHelper";
import { defaultSources } from "../../theme";

export const getProductCPColumns = (marketPlaceServices: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "",
      dataIndex: "image",
      key: "image",
      render: (value: any) => (
        <Image width={50} height={50} src={getUploadUrl(value?.uid)} alt="" />
      ),
    },
    {
      title: "Ürün Adı",
      dataIndex: "name",
      key: "name",
      render: (value, row) =>
        row.variants.length > 0 ? (
          <Popover
            content={
              <>
                <Row
                  justify="center"
                  style={{ fontSize: 16, borderBottom: "1px solid black" }}
                >
                  Varyantlar
                </Row>
                {row.variants.map((variant: any) => (
                  <Row>
                    <Link to={"duzenle/" + variant.id}>
                      {variant.id +
                        " | " +
                        variant.barcode +
                        " | " +
                        variant.name}
                    </Link>
                  </Row>
                ))}
              </>
            }
          >
            <img src={defaultSources.variantWheel} alt="" />{" "}
            <Link to={"duzenle/" + row.id}>{value}</Link>
          </Popover>
        ) : (
          <Link to={"duzenle/" + row.id}>{value}</Link>
        ),
    },
    {
      title: "Kategori",
      dataIndex: "category",
      key: "category",
      render: (value: any) => value?.name,
      onFilter: (value: any, record: any) => value === record.category?.id,
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
          <SearchSelect
            style={{ width: 200 }}
            onClear={() => clearFilters && clearFilters()}
            onSelect={(value) => setSelectedKeys([value])}
            placeholder="Kategori ara..."
            customSearchRequest={(
              searchValue: any,
              setOptions: any,
              setLoading: any
            ) => {
              if (searchValue === "") return;
              setLoading(true);
              CategoryCrudApi.getAll(1, 10, searchValue)
                .then((response) =>
                  setOptions(
                    response["hydra:member"].map((item: any) => ({
                      ...item,
                      label: item.name,
                      value: item.id,
                    }))
                  )
                )
                .finally(() => setLoading(false));
            }}
          />
          <Row style={{ marginTop: 8 }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={() => confirm()}
            >
              Tamam
            </Button>
          </Row>
        </div>
      ),
    },
    {
      title: "Marka",
      dataIndex: "brand",
      key: "brand",
      render: (value: any) => value?.name,
      onFilter: (value: any, record: any) => value === record.brand?.id,
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 16 }} onKeyDown={(e) => e.stopPropagation()}>
          <SearchSelect
            style={{ width: 200 }}
            onClear={() => clearFilters && clearFilters()}
            onSelect={(value) => setSelectedKeys([value])}
            placeholder="Marka ara..."
            customSearchRequest={(
              searchValue: any,
              setOptions: any,
              setLoading: any
            ) => {
              if (searchValue === "") return;
              setLoading(true);
              BrandCrudApi.getAll(1, 10, searchValue)
                .then((response) =>
                  setOptions(
                    response["hydra:member"].map((item: any) => ({
                      ...item,
                      label: item.name,
                      value: item.id,
                    }))
                  )
                )
                .finally(() => setLoading(false));
            }}
          />
          <Row style={{ marginTop: 8 }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={() => confirm()}
            >
              Tamam
            </Button>
          </Row>
        </div>
      ),
    },
    {
      title: "Fiyat (₺)",
      dataIndex: "price",
      key: "price",
      sorter: true,
    },
    {
      title: "Stok",
      dataIndex: "stock",
      key: "stock",
      sorter: true,
    },
    {
      title: "Stok Kodu",
      dataIndex: "stockCode",
      key: "stockCode",
    },
    {
      title: "Kaynak",
      dataIndex: "source",
      key: "source",
      filters: marketPlaceServices,
      render: (value: string) =>
        value ? getConstantValue(value, marketPlaceServices) : "Entekas",
    },
    {
      title: "Platform Eşitleme Durumları",
      dataIndex: "productMatches",
      key: "productMatches",
      render: (value: any) => (
        <Row>
          {value.length === 0
            ? "Eşitleme Yok"
            : value.map((productMatch: any, index: number) => (
                <Tooltip
                  key={index}
                  title={productMatch.matchName + " ile eşitlendi."}
                >
                  <Image
                    preview={false}
                    src={ServiceIconMatchList[productMatch.serviceName]}
                    alt=""
                  />
                </Tooltip>
              ))}
        </Row>
      ),
    },
  ] as TableColumnType<any>[];
};
