import { Col, Row, Tabs } from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import ApiSettingsCard from "../../components/settings/ApiSettingsCard";
import N11ApiForm from "../../components/forms/settings/N11ApiForm";
import ImportProcessCard from "../../components/settings/ImportProcessCard";
import { ServiceIconMatchList } from "../../helpers/ServiceIconMatchHelper";
import React from "react";

const N11SettingsPage = () => {
  const items = [
    {
      label: "API Tanımlama",
      key: "apiSettings",
      children: (
        <ApiSettingsCard serviceName="n11" apiFormComponent={N11ApiForm} />
      ),
    },
    {
      label: "Ürün Aktarımı",
      key: "importing",
      children: (
        <ImportProcessCard
          serviceName="n11"
          logo={ServiceIconMatchList.n11}
          importForm={React.Fragment}
        />
      ),
    },
  ];

  return (
    <>
      <InnerHeader
        firstPageName="Ayarlar"
        secondPageName="Mağaza Ayarları"
        secondPageLink="/pazaryeri"
        thirdPageName="N11 Ayarlar"
      />
      <Row justify="center" style={{ padding: "16px 32px 32px 32px" }}>
        <Col span={24}>
          <Tabs defaultActiveKey="apiSettings" items={items} />
        </Col>
      </Row>
    </>
  );
};

export default N11SettingsPage;
