import { Col, Grid, Input, Row, Typography } from "antd";
import DefaultCard from "../DefaultCard";

const { Text } = Typography;
const { useBreakpoint } = Grid;

interface ICrudTableHeaderProps {
  searchLabel: string;
  searchPlaceholder: string;
  onSearch: (value: string) => void;
  children?: any;
}

const CrudTableHeader = (props: ICrudTableHeaderProps) => {
  const screens = useBreakpoint();

  return (
    <DefaultCard style={{ margin: "16px 0px" }}>
      <Row gutter={[0, 8]}>
        <Col md={8} xs={24}>
          <Row gutter={[0, 8]}>
            <Text>{props.searchLabel}</Text>
            <Input.Search
              size="large"
              placeholder={props.searchPlaceholder}
              onSearch={props.onSearch}
            />
          </Row>
        </Col>
        <Col md={16} xs={24}>
          <Row
            style={{ height: "100%" }}
            justify={screens.md ? "end" : "start"}
            align="middle"
          >
            {props.children}
          </Row>
        </Col>
      </Row>
    </DefaultCard>
  );
};

export default CrudTableHeader;
