import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "antd";
import { colors } from "../../theme";
import Parallax from "parallax-js";

const IntegrationsPage = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);

  const sceneRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (sceneRef.current) {
      const parallaxInstance = new Parallax(sceneRef.current, {
        hoverOnly: true,
        invertX: true,
        invertY: true,
      });

      return () => {
        parallaxInstance.destroy();
      };
    }
  }, []);

  return (
    <>
      <section
        className="page-banner-area py-250 rpy-120 rel z-1 bgs-cover text-center"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          overflow: "hidden",
          height: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: screens.xs ? "" : 0,
        }}
      >
        <div className="custom-banner-text">
          <h3
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="50"
            style={{
              paddingBottom: 30,
              fontSize: screens.xs ? 24 : "",
            }}
          >
            <b style={{ fontWeight: "lighter", position: "relative" }}>
              50’den Fazla Entegrasyon İle
              <img
                style={{
                  position: "absolute",
                  left: 0,
                  top: 36,
                  width: "100%",
                }}
                src="/assets/landingPage/bannerBg/bottomLine.png"
              />
            </b>{" "}
            <br />
            <br />
            Daha Fazla Satış Yapın!
          </h3>
          <a style={{ color: colors.primary }}>
            Entegrasyonları İncele <i className="fas fa-arrow-right"></i>
          </a>
        </div>
        {screens.xs ? (
          <div
            className="custom-items"
            ref={sceneRef}
            style={{ backgroundColor: screens.xs ? "#EEF1F8" : "" }}
          >
            <div
              style={{
                width: 90,
                height: 90,
                position: "absolute" as const,
                left: `5%`,
                top: `5%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/bizimhesap.png"
                alt="Item1"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
            <div
              style={{
                width: 100,
                height: 100,
                position: "absolute" as const,
                left: `50%`,
                top: `30%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/amazon.png"
                alt="Item2"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
            <div
              style={{
                width: 80,
                height: 80,
                position: "absolute" as const,
                left: `80%`,
                top: `50%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/trendyol.png"
                alt="Item3"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
            <div
              style={{
                width: 90,
                height: 90,
                position: "absolute" as const,
                left: `30%`,
                top: `70%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/hepsiburada.png"
                alt="Item4"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
            <div
              style={{
                width: 90,
                height: 90,
                position: "absolute" as const,
                left: `70%`,
                top: `10%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/n11.png"
                alt="Item5"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
            <div
              style={{
                width: 110,
                height: 110,
                position: "absolute" as const,
                left: `90%`,
                top: `70%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/ciceksepeti.png"
                alt="Item6"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
            <div
              style={{
                width: 50,
                height: 50,
                position: "absolute" as const,
                left: `10%`,
                top: `90%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/dinazors.png"
                alt="Item7"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
            <div
              style={{
                width: 70,
                height: 70,
                position: "absolute" as const,
                left: `60%`,
                top: `80%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/parasut.png"
                alt="Item8"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
            <div
              style={{
                width: 44,
                height: 44,
                position: "absolute" as const,
                left: `10%`,
                top: `30%`,
              }}
            >
              <img
                src="/assets/innerPageLogos/elogo.png"
                alt="Item9"
                style={{
                  position: "absolute",
                  width: "100%",
                  opacity: screens.xs ? 0.4 : 1,
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="custom-items"
            ref={sceneRef}
            style={{ backgroundColor: screens.xs ? "#EEF1F8" : "" }}
          >
            <div
              className="custom-items__layer"
              data-depth="0.2"
              style={{
                width: 90,
                height: 90,
              }}
            >
              <img
                src="/assets/innerPageLogos/bizimhesap.png"
                alt="Item1"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
            <div
              className="custom-items__layer"
              data-depth="0.4"
              style={{
                width: 100,
                height: 100,
              }}
            >
              <img
                src="/assets/innerPageLogos/amazon.png"
                alt="Item2"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
            <div
              className="custom-items__layer"
              data-depth="0.6"
              style={{
                width: 80,
                height: 80,
              }}
            >
              <img
                src="/assets/innerPageLogos/trendyol.png"
                alt="Item3"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
            <div
              className="custom-items__layer"
              data-depth="0.8"
              style={{
                width: 60,
                height: 60,
              }}
            >
              <img
                src="/assets/innerPageLogos/hepsiburada.png"
                alt="Item4"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
            <div
              className="custom-items__layer"
              data-depth="1.0"
              style={{
                width: 60,
                height: 60,
              }}
            >
              <img
                src="/assets/innerPageLogos/n11.png"
                alt="Item5"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
            <div
              className="custom-items__layer"
              data-depth="1.0"
              style={{
                width: 110,
                height: 110,
              }}
            >
              <img
                src="/assets/innerPageLogos/ciceksepeti.png"
                alt="Item6"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
            <div
              className="custom-items__layer"
              data-depth="1.4"
              style={{
                width: 50,
                height: 50,
              }}
            >
              <img
                src="/assets/innerPageLogos/dinazors.png"
                alt="Item7"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
            <div
              className="custom-items__layer"
              data-depth="1.6"
              style={{
                width: 70,
                height: 70,
              }}
            >
              <img
                src="/assets/innerPageLogos/parasut.png"
                alt="Item8"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
            <div
              className="custom-items__layer"
              data-depth="1.8"
              style={{
                width: 44,
                height: 44,
              }}
            >
              <img
                src="/assets/innerPageLogos/elogo.png"
                alt="Item9"
                style={{ width: "100%", opacity: screens.xs ? 0.4 : 1 }}
              />
            </div>
          </div>
        )}
      </section>

      {/* <!-- Team Area Start --> */}
      <section
        className={
          screens.xs ? "team-area pt-80 pb-80 rpb-50" : "team-area pb-80 rpb-50"
        }
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div
                className="section-title text-center mb-55"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <h2>
                  Her Türlü Pazaryeri İhtiyaçlarınıza Karşılık Entekas
                  Entegrasyonları
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#B7D463",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/dinazorsBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/dinazors")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Dinazors</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#ff9900",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/amazonBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                    <div className="social-media">
                      <h5>
                        <a onClick={() => navigate("/entegrasyonlar/amazon")}>
                          Detayları İncele
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="description">
                  <h5>Amazon</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#f26725",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/trendyolBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/trendyol")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Trendyol</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#ff963f",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/hepsiBuradaBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a
                        onClick={() => navigate("/entegrasyonlar/hepsiburada")}
                      >
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Hepsiburada</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#6849c5",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/n11Beyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/n11")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>N11</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#51B449",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/brandLogos/ciceksepetiBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a
                        onClick={() => navigate("/entegrasyonlar/ciceksepeti")}
                      >
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Çiçeksepeti</h5>
                  <span className="designation">Pazaryeri</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#28B29A",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/accountingLogos/bizimHesapBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/bizimhesap")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Bizim Hesap</h5>
                  <span className="designation">Muhasebe</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#fd5c4a",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/accountingLogos/parasutBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/parasut")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Paraşüt</h5>
                  <span className="designation">Muhasebe</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="team-member"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="50"
              >
                <div
                  className="image"
                  style={{
                    height: screens.md ? 300 : 243,
                  }}
                >
                  <div
                    className="image"
                    style={{
                      backgroundColor: "#2B378A",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src="/assets/eInvoice/eLogoEfaturaBeyaz.png"
                      alt="Team"
                      style={{ height: 35, borderRadius: 0 }}
                    />
                  </div>
                  <div className="social-media">
                    <h5>
                      <a onClick={() => navigate("/entegrasyonlar/elogo")}>
                        Detayları İncele
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="description">
                  <h5>Logo E-Fatura</h5>
                  <span className="designation">E Fatura</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Team Area End --> */}

      {/* <!-- Countries Area Start --> */}
      <section className="countries-area rel z-1 pb-70 rpb-40">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-7 col-md-9 col-sm-11">
              <div
                className="section-title text-center mb-30"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <h2>
                  Entekas İle Tüm Platfomlarınızı Tek Bir Yerden Kontrol Edin
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="image mt-40 text-center"
            data-aos="zoom-in-up"
            data-aos-duration="1500"
          >
            <img
              src="/assets/landingPage/images/backgrounds/map-locations.png"
              alt="Map Locations"
            />
          </div>
        </div>
      </section>
      {/* <!-- Countries Area End --> */}
    </>
  );
};

export default IntegrationsPage;
