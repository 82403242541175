import { Breadcrumb, Col, Row, Typography } from "antd";
import { colors, heights } from "../../theme";

const { Text } = Typography;

interface IInnerHeaderProps {
  firstPageName?: any;
  firstPageLink?: any;
  secondPageName?: any;
  secondPageLink?: any;
  thirdPageName?: any;
  thirdPageLink?: any;
}

const InnerHeader = (props: IInnerHeaderProps) => {
  return (
    <Row
      style={{
        height: heights.innerHeaderHeight,
        backgroundColor: colors.white,
        padding: 32,
        position: "relative",
      }}
    >
      <Col span={24}>
        <Text
          style={{
            fontSize: 24,
            textShadow: "rgba(0, 0, 0, .15) 0 0 1px",
            color: "#3F3F3F",
          }}
        >
          {props.thirdPageName}
        </Text>
      </Col>
      <Breadcrumb
        separator=">"
        items={[
          {
            title: props.firstPageName ? props.firstPageName : "Entekas",
            href: props.firstPageLink,
          },
          {
            title: props.secondPageName,
            href: props.secondPageLink,
          },
          {
            title: props.thirdPageName,
            href: props.thirdPageLink,
          },
        ]}
      />
    </Row>
  );
};

export default InnerHeader;
