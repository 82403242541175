import { Form, Input, Select } from "antd";
import { max, required } from "../../../helpers/FormRuleHelper";

interface ILogoEFaturaApiFormProps {
  form: any;
}

const LogoEFaturaApiForm = (props: ILogoEFaturaApiFormProps) => {
  return (
    <Form form={props.form} layout="vertical" initialValues={{ active: false }}>
      <Form.Item
        name="userCode"
        label="KULLANICI KODU"
        rules={[required, max(255)]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item name="password" label="ŞİFRE" rules={[required, max(255)]}>
        <Input.Password />
      </Form.Item>
      <Form.Item name="invoiceType" label="FATURA TİPİ" rules={[required]}>
        <Select
          options={[
            { label: "Temel Fatura", value: "basic" },
            { label: "Ticari Fatura", value: "commercial" },
          ]}
        />
      </Form.Item>
      <Form.Item name="active" label="Entegrasyon Durumu" rules={[required]}>
        <Select
          options={[
            { label: "Entegrasyon Açık", value: true },
            { label: "Entegrasyon Kapalı", value: false },
          ]}
        />
      </Form.Item>
    </Form>
  );
};

export default LogoEFaturaApiForm;
