import { Col, Row, Tag, Tooltip, Image, Typography, Space } from "antd";
import { ServiceIconMatchList } from "../../helpers/ServiceIconMatchHelper";

const { Text, Title } = Typography;

export const getAttributeCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Seçenek Grubu",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Seçenekler",
      dataIndex: "attributeValues",
      key: "attributeValues",
      render: (value: any) =>
        value.map((item: any, index: number) => (
          <Tag key={index}>{item.name}</Tag>
        )),
    },
  ];
};

export const getAttributeCPExpanded = (record: any) => {
  return (
    <>
      <Title level={5}>Seçenek Grubu Eşitlemesi</Title>
      <Row align="middle">
        <Col span={2}>
          <Text>{record.name}</Text>
        </Col>
        <Col span={22}>
          <Space>
            {record.attributeGroupMatches?.map(
              (attributeGroupMatch: any, index: number) => (
                <Tooltip
                  key={index}
                  title={attributeGroupMatch.matchName + " ile eşitlendi."}
                >
                  <Image
                    preview={false}
                    src={ServiceIconMatchList[attributeGroupMatch.serviceName]}
                    alt=""
                  />
                </Tooltip>
              )
            )}
          </Space>
        </Col>
      </Row>
      <Title level={5}>Seçenek Eşitlemesi</Title>
      {record.attributeValues?.map((attributeValue: any, index: number) => (
        <Row align="middle" key={index}>
          <Col span={2}>
            <Text>{attributeValue.name}</Text>
          </Col>
          <Col span={20}>
            <Space>
              {attributeValue.attributeValueMatches?.map(
                (attributeGroupMatch: any, index: number) => (
                  <Tooltip
                    key={index}
                    title={attributeGroupMatch.matchName + " ile eşitlendi."}
                  >
                    <Image
                      preview={false}
                      src={
                        ServiceIconMatchList[attributeGroupMatch.serviceName]
                      }
                      alt=""
                    />
                  </Tooltip>
                )
              )}
            </Space>
          </Col>
        </Row>
      ))}
    </>
  );
};
