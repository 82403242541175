import Cookies from "js-cookie";

export const isLoggedIn = () => Boolean(Cookies.get("accessToken"));
export const isAdmin = () => getUserField("roles").includes("ROLE_ADMIN");

export const getUser = () => {
  const userJson = Cookies.get("user");
  return userJson !== undefined ? JSON.parse(userJson) : undefined;
};

export const getUserField = (field: string) => {
  return getUser()?.[field];
};

export const saveCredentials = (
  user: any,
  accessToken: string,
  refreshToken: string
) => {
  Cookies.set("user", JSON.stringify(user), { expires: 365 });
  Cookies.set("accessToken", accessToken, { expires: 365 });
  Cookies.set("refreshToken", refreshToken, { expires: 365 });
  window.location.reload();
};

export const removeCredentials = () => {
  Cookies.remove("user");
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  window.location.replace(window.location.origin);
};
