import { Grid } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const { useBreakpoint } = Grid;

const BlogsPage = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);

  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className="page-banner-area py-250 rpy-120 rel z-1 bgs-cover"
        style={{ backgroundColor: "#eee" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12" style={{ alignContent: "center" }}>
              <div>
                <h1
                  data-aos="fade-up"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                  style={{
                    paddingBottom: 30,
                    textAlign: "center",
                    fontSize: screens.xs ? 35 : "",
                  }}
                >
                  <b style={{ fontWeight: "lighter", position: "relative" }}>
                    Blog
                    <img
                      style={{
                        position: "absolute",
                        left: 0,
                        top: screens.xs ? 44 : 84,
                        width: screens.xs ? "50%" : "",
                      }}
                      src="/assets/landingPage/bannerBg/bottomLine.png"
                    />{" "}
                    Yazılarımız
                  </b>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Blog Standard Area Start --> */}
      <section className="blog-standard-area py-130 rpy-100">
        <div className="container">
          {/* <div className="row"> */}
          {/* <div className="col-lg-8"> */}
          <div className="col-lg-12">
            {/* <div className="blog-standard-wrap" > */}
            <div>
              <div
                className="blog-standard-item"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/bannerBg/blogImg.jpg"
                    alt="Blog"
                  />
                </div>
                <div className="content" style={{ paddingTop: 20 }}>
                  <h3>
                    <a onClick={() => navigate("/blog/1")}>
                      E-Ticarette Çapraz Satış ve Ek Satış Rehberi
                    </a>
                  </h3>
                  <p>
                    Bir müşterinin satın alma işlemi tamamlandığında gelir
                    potansiyeli sona ermez. Satış sonrası birçok fırsat vardır
                    ve çapraz satış ile ek satış teknikleri, aynı müşteriden
                    tekrar tekrar yararlanmanıza yardımcı olabilir. 2022 HubSpot
                    Blog anketine göre, ek satış yapan satış temsilcilerinin
                    %72’si ve çapraz satış yapanların %74’ü, gelirlerinin %30’a
                    kadar arttığını belirtiyor.
                  </p>
                  <a
                    onClick={() => navigate("/blog/1")}
                    className="theme-btn style-two"
                  >
                    Devamını Oku <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <blockquote
                className="blockquote-three bgc-lighter mb-50"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="text">
                  Müşterilerimizi bir partiye davetli misafirler olarak
                  görüyoruz ve biz de ev sahibiyiz. Her gün, müşteri deneyiminin
                  her önemli yönünü biraz daha iyi hale getirmek bizim
                  görevimiz.
                </div>
                <h5 className="name">Jeff Bezos</h5>
                <span className="designation">
                  Amazon'un CEO'su ve Kurucusu
                </span>
              </blockquote>
              {/* <div
                className="blog-standard-item"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/blog/blog-standard2.png"
                    alt="Blog"
                  />
                </div>
                <div className="content" style={{ paddingTop: 20 }}> */}
              {/* <ul className="blog-meta">
                    <li>
                      <a className="category" href="#">
                        SEO Camping
                      </a>
                    </li>
                    <li>
                      <img
                        src="/assets/landingPage/images/blog/author.png"
                        alt="Author"
                      />{" "}
                      <a href="#">Matthew</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt"></i>{" "}
                      <a href="#">September 20, 2023</a>
                    </li>
                  </ul> */}
              {/* <h3>
                    <a onClick={() => navigate("/blog/1")}>
                      Achieving Fashion Elegant Design Runway Life
                    </a>
                  </h3>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus sit voluptatem
                    accusantium doloremque laudantium eaque ipsa quae abillo
                    inventore veritatis et quasi architecto beatae
                  </p>
                  <a
                    onClick={() => navigate("/blog/1")}
                    className="theme-btn style-two"
                  >
                    Devamını Oku <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              </div> */}
              {/* <blockquote
                className="blockquote-three bgc-primary text-white mb-50"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="text">
                  Remote work made easy way better tools Section Bonorum et
                  Malorum
                </div>
                <h5 className="name">Michael A. Jenkins</h5>
                <span className="designation">CEO &amp; Founder</span>
              </blockquote> */}
              {/* <div
                className="blog-standard-item"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/images/blog/blog-standard3.png"
                    alt="Blog"
                  />
                </div>
                <div className="content" style={{ paddingTop: 20 }}> */}
              {/* <ul className="blog-meta">
                    <li>
                      <a className="category" href="#">
                        SEO Camping
                      </a>
                    </li>
                    <li>
                      <img
                        src="/assets/landingPage/images/blog/author.png"
                        alt="Author"
                      />{" "}
                      <a href="#">Matthew</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt"></i>{" "}
                      <a href="#">September 20, 2023</a>
                    </li>
                  </ul> */}
              {/* <h3>
                    <a onClick={() => navigate("/blog/1")}>
                      Achieving Fashion Elegant Design Runway Life
                    </a>
                  </h3>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus sit voluptatem
                    accusantium doloremque laudantium eaque ipsa quae abillo
                    inventore veritatis et quasi architecto beatae
                  </p>
                  <a
                    onClick={() => navigate("/blog/1")}
                    className="theme-btn style-two"
                  >
                    Devamını Oku <i className="far fa-arrow-right"></i>
                  </a>
                </div>
              </div> */}
              {/* <ul className="pagination mt-25 flex-wrap">
                <li className="page-item disabled">
                  <span className="page-link">
                    <i className="fas fa-angle-left"></i>
                  </span>
                </li>
                <li className="page-item active">
                  <span className="page-link">
                    1<span className="sr-only">(current)</span>
                  </span>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    4
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="fas fa-angle-right"></i>
                  </a>
                </li>
              </ul> */}
            </div>
          </div>
          {/* <div className="col-lg-4">
              <div className="main-sidebar rmt-75">
                <div
                  className="widget widget-category"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <h5 className="widget-title">Category</h5>
                  <ul>
                    <li>
                      <a href="blog.html">
                        Marketing <i className="far fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="blog.html">
                        SEO optimization <i className="far fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="blog.html">
                        Content Marketing <i className="far fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="blog.html">
                        Keywords Research <i className="far fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="blog.html">
                        Technical Adult <i className="far fa-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="widget widget-recent-news"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <h4 className="widget-title">Recent News</h4>
                  <ul>
                    <li>
                      <div className="image">
                        <img
                          src="/assets/landingPage/images/widget/news1.jpg"
                          alt="News"
                        />
                      </div>
                      <div className="content">
                        <span className="date">September 20, 2023</span>
                        <h6>
                          <a href="blog-details.html">
                            Achieving Fashion Elegant se Runway to Real Life
                          </a>
                        </h6>
                      </div>
                    </li>
                    <li>
                      <div className="image">
                        <img
                          src="/assets/landingPage/images/widget/news2.jpg"
                          alt="News"
                        />
                      </div>
                      <div className="content">
                        <span className="date">September 20, 2023</span>
                        <h6>
                          <a href="blog-details.html">
                            Achieving Fashion Elegant se Runway to Real Life
                          </a>
                        </h6>
                      </div>
                    </li>
                    <li>
                      <div className="image">
                        <img
                          src="/assets/landingPage/images/widget/news3.jpg"
                          alt="News"
                        />
                      </div>
                      <div className="content">
                        <span className="date">September 20, 2023</span>
                        <h6>
                          <a href="blog-details.html">
                            Achieving Fashion Elegant se Runway to Real Life
                          </a>
                        </h6>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  className="widget widget-tag-cloud"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <h4 className="widget-title">Popular Tags</h4>
                  <div className="tag-coulds">
                    <a href="blog.html">Marketing</a>
                    <a href="blog.html">Product</a>
                    <a href="blog.html">Social Media</a>
                    <a href="blog.html">SEO Optimize</a>
                    <a href="blog.html">Content</a>
                    <a href="blog.html">Design</a>
                  </div>
                </div>
                <div
                  className="widget widget-cta"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1500"
                  data-aos-offset="50"
                >
                  <h3>Boost your Digital Product marketing?</h3>
                  <a href="contact.html" className="theme-btn">
                    Contact Us <i className="fas fa-angle-double-right"></i>
                  </a>
                  <div className="man">
                    <img
                      src="/assets/landingPage/images/widget/cta-man.png"
                      alt="CTA"
                    />
                  </div>
                  <div
                    className="bg bgs-cover"
                    style={{
                      backgroundImage:
                        "url(/assets/landingPage/images/widget/cta-bg.png)",
                    }}
                  ></div>
                </div>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </section>
      {/* <!-- Blog Standard Area End --> */}
    </>
  );
};

export default BlogsPage;
