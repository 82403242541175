import Icon from "@ant-design/icons";

const HamburderMenuIcon = (props: any) => {
  return (
    <Icon
      {...props}
      style={{
        ...props.style,
        height: props.height ?? 24,
        width: props.width ?? 24,
      }}
      component={() => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M3 7h18M3 12h18M3 17h18"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
          ></path>
        </svg>
      )}
    />
  );
};

export default HamburderMenuIcon;
