import { Row } from "antd";
import InnerHeader from "../components/layouts/InnerHeader";
import { colors } from "../theme";
import { ShoppingCartOutlined, ShopOutlined } from "@ant-design/icons";
import SummaryCard from "../components/dashboard/SummaryCard";
import OfferCard from "../components/dashboard/OfferCard";
import DefaultCard from "../components/common/DefaultCard";
import ExampleTable from "../components/dashboard/ExampleTable";

const Dashboard = () => {
  return (
    <>
      <OfferCard />

      <InnerHeader thirdPageName="Dashboard" firstPageLink="/" />

      <Row gutter={[24, 24]} style={{ margin: 24 }}>
        <SummaryCard
          background="#a5d0f7"
          backgroundImage="linear-gradient(-180deg, #a5d0f7 0%, #67a7f3 100%)"
          icon={
            <ShoppingCartOutlined
              style={{ fontSize: 50, color: colors.white }}
            />
          }
          topText="BUGÜN GELEN SİPARİŞ SAYISI"
          number={0}
          bottomText="BUGÜN TOPLAM SİPARİŞ SAYISI"
        />
        <SummaryCard
          background="#00C4FF"
          backgroundImage="linear-gradient(-180deg, #00C4FF 0%, #0092FF 100%)"
          icon={<ShopOutlined style={{ fontSize: 50, color: colors.white }} />}
          topText="BUGÜN TOPLAM CİRO"
          number="0 TL"
          bottomText="BUGÜN ELDE EDİLEN KAZANÇ"
        />
        <SummaryCard
          background="#008EFF"
          backgroundImage="linear-gradient(-180deg, #008EFF 0%, #0055FF 100%)"
          icon={
            <ShoppingCartOutlined
              style={{ fontSize: 50, color: colors.white }}
            />
          }
          topText="BU AY GELEN SİPARİŞ SAYISI"
          number="0"
          bottomText="BU AY GELEN TOPLAM SİPARİŞ SAYISI"
        />
        <SummaryCard
          background="#3861D8"
          backgroundImage="linear-gradient(-180deg, #3861D8 0%, #1A32AF 100%)"
          icon={<ShopOutlined style={{ fontSize: 50, color: colors.white }} />}
          topText="BU AYKİ TOPLAM CİRO"
          number="0 TL"
          bottomText="BU AY ELDE EDİLEN KAZANÇ"
        />
      </Row>
      <DefaultCard style={{ margin: 24 }} header={"Aylık Satış İstatistikleri"}>
        <div style={{ height: 300 }}>
          <ExampleTable />
        </div>
      </DefaultCard>
    </>
  );
};

export default Dashboard;
