import { Button, Col, Image, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Row
      gutter={[0, 24]}
      style={{ height: "100%", width: "100%", padding: 24 }}
      justify="center"
      align="middle"
    >
      <Col span={24}>
        <Row justify="center">
          <Image
            width={400}
            height={400}
            preview={false}
            src="/assets/404-error.png"
          />
        </Row>
      </Col>
      <Col span={24}>
        <Title style={{ textAlign: "center", margin: 0 }}>
          Sayfa Bulunamadı
        </Title>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Text style={{ textAlign: "center" }}>
            Görünüşe göre keşfedilmemiş bir bölgeye girmişsiniz. Aradığınız
            sayfa taşınmış, silinmiş ya da hiç var olmamış olabilir. Ama
            endişelenme, uzun süre kaybolmayacaksın.
          </Text>
        </Row>
      </Col>
      <Col span={24}>
        <Row justify="center">
          <Button type="primary" onClick={() => navigate("/")}>
            Anasayfaya Dön
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default NotFoundPage;
