import InnerHeader from "../../../components/layouts/InnerHeader";
import { Button, Row, Spin, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { colors } from "../../../theme";
import { useForm } from "antd/es/form/Form";
import {
  getFormValue,
  isUploadDone,
} from "../../../components/common/ChunkUpload";
import { ProductCrudApi } from "../../../client/EntityApi";
import { useEffect, useState } from "react";
import ProductForm from "../../../components/forms/ProductForm";
import { useNavigate, useParams } from "react-router-dom";
import { getUploadUrl } from "../../../helpers/UtilHelper";

const ProductEditPage = () => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [defaultCategoryOption, setDefaultCategoryOption] = useState<any>();
  const [defaultBrandOption, setDefaultBrandOption] = useState<any>();

  useEffect(() => {
    if (id === undefined) return;

    setLoading(true);
    ProductCrudApi.get(parseInt(id))
      .then((response: any) => {
        if (response.category) {
          setDefaultCategoryOption({
            label: response.category.name,
            value: response.category?.["@id"],
          });
        }
        if (response.brand) {
          setDefaultBrandOption({
            label: response.brand.name,
            value: response.brand?.["@id"],
          });
        }

        response.category = response.category?.["@id"];
        response.brand = response.brand?.["@id"];
        response.media = response.media?.map((item: any) => ({
          ...item,
          thumbUrl: getUploadUrl(item?.uid),
        }));

        form.setFieldsValue(response);
      })
      .finally(() => setLoading(false));
  }, [id, form]);

  return (
    <>
      <InnerHeader
        firstPageLink="/"
        secondPageName="Ürünler"
        secondPageLink="/urunler"
        thirdPageName="Ürün Düzenle"
      />
      {loading ? (
        <Row justify="center" style={{ padding: 64 }}>
          <Spin />
        </Row>
      ) : (
        <>
          <ProductForm
            form={form}
            isEdit
            defaultBrandOption={defaultBrandOption}
            defaultCategoryOption={defaultCategoryOption}
          />
          <div
            style={{
              width: "100%",
              paddingRight: 24,
              height: 70,
              position: "fixed",
              bottom: 0,
              right: 0,
              backgroundColor: colors.white,
              alignContent: "center",
              borderTop: "1px solid #eee",
            }}
          >
            <Row justify="end">
              <Button
                type="primary"
                className="customPrimaryFormButton"
                icon={<ArrowRightOutlined />}
                iconPosition="end"
                loading={actionLoading}
                onClick={() => {
                  if (id === undefined) return;

                  form.validateFields().then((values) => {
                    if (!isUploadDone(values.media)) {
                      message.warning(
                        "Lütfen yüklemelerin tamamlanmasını bekleyin!"
                      );
                      return;
                    }

                    values.media = getFormValue(values?.media);
                    values.brand = values.brand ?? null;
                    values.category = values.category ?? null;

                    setActionLoading(true);
                    ProductCrudApi.edit(parseInt(id), values)
                      .then(() => {
                        message.success("Ürün güncellendi.");
                        navigate("/urunler/duzenle/" + id);
                      })
                      .finally(() => setActionLoading(false));
                  });
                }}
              >
                GÜNCELLE
              </Button>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default ProductEditPage;
