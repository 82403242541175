import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  message,
} from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import { useEffect, useRef, useState } from "react";
import CrudTable from "../../components/common/crud/CrudTable";
import { ProductApi, ProductCrudApi } from "../../client/EntityApi";
import { getProductCPColumns } from "../../components/hook/ProductCPComponents";
import { GeneralApi, TransferApi } from "../../client/Api";
import CrudTableHeader from "../../components/common/crud/CrudTableHeader";
import {
  FileAddOutlined,
  FormOutlined,
  ApiOutlined,
  ArrowsAltOutlined,
  // ApartmentOutlined,
} from "@ant-design/icons";
import CrudTableButton from "../../components/common/crud/CrudTableButton";
import { useForm } from "antd/es/form/Form";
import { required } from "../../helpers/FormRuleHelper";
import { useNavigate } from "react-router-dom";
import ProductMatchForm from "../../components/forms/ProductMatchForm";
import OutTransferForm from "../../components/forms/OutTransferForm";

const ProductCrudPage = () => {
  const navigate = useNavigate();
  const crudTableRef = useRef<any>(null);

  const [fastEditForm] = useForm();
  const [fastEditModalOpen, setFastEditModalOpen] = useState(false);
  const [fastEditActionLoading, setFastEditActionLoading] = useState(false);

  const [productMatchForm] = useForm();
  const [productMatchModalOpen, setProductMatchModalOpen] = useState(false);
  const [productMatchActionLoading, setProductMatchActionLoading] =
    useState(false);

  const [outTransferForm] = useForm();
  const [transferErrors, setTransferErrors] = useState<string[]>([]);
  const [outTransferModalOpen, setOutTransferModalOpen] = useState(false);
  const [outTransferActionLoading, setOutTransferActionLoading] =
    useState(false);

  const [search, setSearch] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [marketPlaceServices, setMarketPlaceServices] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    GeneralApi.getConstants().then((response) =>
      setMarketPlaceServices(response.marketPlaceServices)
    );
  }, []);

  const fastEditModalCancel = () => {
    setFastEditModalOpen(false);
    setFastEditActionLoading(false);
    fastEditForm.resetFields();
  };

  const productMatchModalCancel = () => {
    setProductMatchModalOpen(false);
    setProductMatchActionLoading(false);
    setServiceName("");
    productMatchForm.resetFields();
  };

  const outTransferModalCancel = () => {
    setOutTransferModalOpen(false);
    setOutTransferActionLoading(false);
    outTransferForm.resetFields();
    setTransferErrors([]);
  };

  const orangeButtonStyle = {
    color: "#FF7C3F",
    border: "1px solid #FF7C3F",
    backgroundColor: "#FFF2EB",
  };

  return (
    <>
      <InnerHeader
        firstPageLink="/"
        secondPageName="Ürünler"
        thirdPageName="Ürün Listesi"
        thirdPageLink="/urunler"
      />
      <Row justify="center" style={{ padding: "16px 32px 32px 32px" }}>
        <Col span={24}>
          <CrudTableHeader
            searchLabel="Ürün Arama"
            searchPlaceholder="Ürün adı, Stok Kodu yada Barkod ile ara..."
            onSearch={(value) => setSearch(value)}
          >
            <Button
              style={{ marginRight: 8 }}
              icon={<ArrowsAltOutlined />}
              size="large"
              type="primary"
              onClick={() => setOutTransferModalOpen(true)}
            >
              Ürün Gönderimi
            </Button>
            <Button
              icon={<FileAddOutlined />}
              size="large"
              onClick={() => navigate("/urunler/ekle")}
            >
              EKLE
            </Button>
          </CrudTableHeader>
        </Col>
        <Col span={24}>
          <CrudTable
            ref={crudTableRef}
            api={ProductCrudApi}
            searchKey={search}
            columns={getProductCPColumns(marketPlaceServices)}
            processColumnWidth={270}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            extraRowProcess={(row) => (
              <>
                <CrudTableButton
                  loading={fastEditActionLoading}
                  tooltipText="Ürün Eşle/Kaldır"
                  icon={<ApiOutlined />}
                  style={orangeButtonStyle}
                  onClick={() => {
                    setProductMatchModalOpen(true);
                    productMatchForm.setFieldsValue(row);
                  }}
                />
                {/* <CrudTableButton
                  tooltipText="Ürün Seçenekleri"
                  icon={<ApartmentOutlined />}
                  style={orangeButtonStyle}
                  onClick={() => navigate("/urunler/secenekler/" + row.id)}
                /> */}
                <CrudTableButton
                  loading={fastEditActionLoading}
                  tooltipText="Hızlı Düzenle"
                  icon={<FormOutlined />}
                  style={orangeButtonStyle}
                  onClick={() => {
                    setFastEditModalOpen(true);
                    fastEditForm.setFieldsValue(row);
                  }}
                />
              </>
            )}
          />
        </Col>
      </Row>
      <Modal
        title="Hızlı Düzenle"
        open={fastEditModalOpen}
        confirmLoading={fastEditActionLoading}
        onCancel={() => fastEditModalCancel()}
        onOk={() => {
          setFastEditActionLoading(true);
          fastEditForm.validateFields().then((values: any) => {
            ProductApi.fastEdit(values.id, values)
              .then((response) => {
                if (response.success?.length > 0)
                  message.success(response.success.join(" "));

                if (response.error?.length > 0)
                  message.error(response.error.join(" "));

                if (response.message) message.warning(response.message);

                fastEditModalCancel();
                crudTableRef.current.refreshData();
              })
              .finally(() => setFastEditActionLoading(false));
          });
        }}
      >
        <Form form={fastEditForm} layout="vertical">
          <Form.Item hidden name="id" rules={[required]}>
            <Input />
          </Form.Item>
          <Form.Item label="Fiyat" name="price" rules={[required]}>
            <InputNumber
              style={{ width: "100%" }}
              stringMode
              precision={2}
              min={0}
            />
          </Form.Item>
          <Form.Item label="Stok" name="stock" rules={[required]}>
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Ürün Eşle/Kaldır"
        open={productMatchModalOpen}
        confirmLoading={productMatchActionLoading}
        onCancel={() => productMatchModalCancel()}
        onOk={() => {
          setProductMatchActionLoading(true);
          productMatchForm.validateFields().then((values: any) => {
            ProductApi.matchProduct(values)
              .then((response) => {
                message.success(response.message);
                productMatchModalCancel();
                crudTableRef.current.refreshData();
              })
              .finally(() => setProductMatchActionLoading(false));
          });
        }}
      >
        <Form form={productMatchForm} layout="vertical">
          <ProductMatchForm
            form={productMatchForm}
            serviceName={serviceName}
            setServiceName={setServiceName}
          />
        </Form>
      </Modal>
      <Modal
        title="Ürün Gönderimi"
        open={outTransferModalOpen}
        confirmLoading={outTransferActionLoading}
        onCancel={() => outTransferModalCancel()}
        onOk={() => {
          setOutTransferActionLoading(true);
          outTransferForm.validateFields().then((values: any) => {
            TransferApi.transferReservation(values)
              .then((response) => {
                message.success(response.message);
                outTransferModalCancel();
              })
              .catch((reason) => setTransferErrors(reason.response.data.errors))
              .finally(() => setOutTransferActionLoading(false));
          });
        }}
      >
        <OutTransferForm
          form={outTransferForm}
          selectedKeys={selectedRowKeys}
          transferErrors={transferErrors}
        />
      </Modal>
    </>
  );
};

export default ProductCrudPage;
