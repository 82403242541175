import InnerHeader from "../../components/layouts/InnerHeader";
import { Row, Col, Input, Modal, Form, message, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { BrandApi, BrandCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/common/crud/CrudTable";
import { getBrandCPColumns } from "../../components/hook/BrandCPComponents";
import { GeneralApi } from "../../client/Api";
import CrudTableButton from "../../components/common/crud/CrudTableButton";
import { CloudSyncOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { max, min, required } from "../../helpers/FormRuleHelper";
import BrandMatchForm from "../../components/forms/BrandMatchForm";
import { FileAddOutlined } from "@ant-design/icons";
import CrudTableHeader from "../../components/common/crud/CrudTableHeader";

const BrandCrudPage = () => {
  const crudTableRef = useRef<any>(null);
  const [serviceName, setServiceName] = useState("");

  const [addForm] = useForm();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [addActionLoading, setAddActionLoading] = useState(false);

  const [editForm] = useForm();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editActionLoading, setEditActionLoading] = useState(false);

  const [syncBrandForm] = useForm();
  const [syncBrandModalOpen, setSyncBrandModalOpen] = useState(false);
  const [syncBrandActionLoading, setSyncBrandActionLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [marketPlaceServices, setMarketPlaceServices] = useState();

  useEffect(() => {
    GeneralApi.getConstants().then((response) =>
      setMarketPlaceServices(response.marketPlaceServices)
    );
  }, []);

  const addModalCancel = () => {
    setAddModalOpen(false);
    setAddActionLoading(false);
    addForm.resetFields();
  };

  const editModalCancel = () => {
    setEditModalOpen(false);
    setEditActionLoading(false);
    editForm.resetFields();
  };

  const syncBrandModalCancel = () => {
    setSyncBrandModalOpen(false);
    setSyncBrandActionLoading(false);
    setServiceName("");
    syncBrandForm.resetFields();
  };

  return (
    <>
      <InnerHeader
        firstPageLink="/"
        secondPageName="Ürünler"
        thirdPageName="Marka Listesi"
        thirdPageLink="/markalar"
      />
      <Row justify="center" style={{ padding: "16px 32px 32px 32px" }}>
        <Col span={24}>
          <CrudTableHeader
            searchLabel="Marka Arama"
            searchPlaceholder="Marka adı ile ara..."
            onSearch={(value) => setSearch(value)}
          >
            <Button
              icon={<FileAddOutlined />}
              size="large"
              onClick={() => setAddModalOpen(true)}
            >
              EKLE
            </Button>
          </CrudTableHeader>
        </Col>
        <Col span={24}>
          <CrudTable
            ref={crudTableRef}
            api={BrandCrudApi}
            searchKey={search}
            columns={getBrandCPColumns(marketPlaceServices)}
            processColumnWidth={270}
            editProcess={(row) => {
              editForm.setFieldsValue(row);
              setEditModalOpen(true);
            }}
            extraRowProcess={(row, actionLoading) => (
              <>
                <CrudTableButton
                  loading={actionLoading}
                  tooltipText="Marka Eşitle"
                  icon={<CloudSyncOutlined />}
                  onClick={() => {
                    setSyncBrandModalOpen(true);
                    syncBrandForm.setFieldsValue({ id: row.id });
                  }}
                  style={{
                    color: "#FF7C3F",
                    border: "1px solid #FF7C3F",
                    backgroundColor: "#FFF2EB",
                  }}
                />
              </>
            )}
          />
        </Col>
      </Row>
      <Modal
        title="Marka Ekle"
        open={addModalOpen}
        confirmLoading={addActionLoading}
        onCancel={() => addModalCancel()}
        onOk={() => {
          setAddActionLoading(true);
          addForm.validateFields().then((values: any) => {
            BrandCrudApi.create(values)
              .then(() => {
                addModalCancel();
                crudTableRef.current.refreshData();
              })
              .finally(() => setAddActionLoading(false));
          });
        }}
      >
        <Form form={addForm} layout="vertical">
          <Form.Item
            label="Marka Adı"
            name="name"
            rules={[required, min(2), max(255)]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Marka Düzenle"
        open={editModalOpen}
        confirmLoading={editActionLoading}
        onCancel={() => editModalCancel()}
        onOk={() => {
          setEditActionLoading(true);
          editForm.validateFields().then((values: any) => {
            BrandCrudApi.edit(values.id, values)
              .then(() => {
                editModalCancel();
                crudTableRef.current.refreshData();
              })
              .finally(() => setEditActionLoading(false));
          });
        }}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item hidden name="id" rules={[required]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Marka Adı"
            name="name"
            rules={[required, min(2), max(255)]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Marka Eşitle"
        open={syncBrandModalOpen}
        confirmLoading={syncBrandActionLoading}
        onCancel={() => syncBrandModalCancel()}
        onOk={() => {
          syncBrandForm.validateFields().then((values: any) => {
            setSyncBrandActionLoading(true);
            BrandApi.matchBrand(values)
              .then((response) => {
                syncBrandModalCancel();
                crudTableRef.current.refreshData();
                message.success(response.message);
              })
              .finally(() => setSyncBrandActionLoading(false));
          });
        }}
      >
        <BrandMatchForm
          form={syncBrandForm}
          serviceName={serviceName}
          setServiceName={setServiceName}
        />
      </Modal>
    </>
  );
};

export default BrandCrudPage;
