import { useState } from "react";
import { Layout, Grid } from "antd";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import { colors, heights, widths } from "../../theme";
import CustomHeader from "./CustomHeader";

const { Content } = Layout;

const SiderLayout = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [collapsed, setCollapsed] = useState(true);
  const [open, setOpen] = useState(false);
  const [pinned, setPinned] = useState(false);

  const toggleCollapsed = () => {
    if (!pinned) {
      setCollapsed(!collapsed);
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const togglePinned = () => {
    setPinned(!pinned);
    setCollapsed(pinned);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideBar
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        open={open}
        showDrawer={showDrawer}
        onClose={onClose}
        pinned={pinned}
        togglePinned={togglePinned}
      />
      <Layout
        style={{
          marginLeft: screens.md ? (pinned ? 0 : widths.closeSiderWidth) : "",
          backgroundColor: colors.white,
        }}
      >
        <CustomHeader
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
          showDrawer={showDrawer}
          pinned={pinned}
        />
        <Content
          style={{
            marginTop: heights.headerHeight,
            zIndex: 1,
            backgroundColor: colors.contentBg,
            height: "calc(100vh - " + (heights.headerHeight + 48) + "px)",
            overflow: "auto",
          }}
        >
          <Outlet context={{ pinned }} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default SiderLayout;
