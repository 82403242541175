import { Col, Divider, Row, Typography } from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import ImageCard from "../../components/common/ImageCard";

const { Title } = Typography;

const EInvoiceServicesPage = () => {
  const items = {
    active: [
      {
        title: "Logo E-Fatura",
        color: "#2B378A",
        logo: "/assets/eInvoice/eLogoEfaturaBeyaz.png",
        to: "/logo-efatura",
      },
    ],
    comingSoon: [
      {
        title: "Trendyol E-Fatura (Sovos)",
        color: "#B4C42C",
        logo: "/assets/eInvoice/trendyolEfaturaBeyaz.png",
        to: "/trendyol-efatura",
      },
      {
        title: "GİB E-Arşiv",
        color: "#D8232A",
        logo: "/assets/eInvoice/gelirIdaresiBeyaz.png",
        to: "/gib-earsiv",
      },
      {
        title: "QNB eFinans E-Fatura",
        color: "#b14f88",
        logo: "/assets/eInvoice/qnbEfaturaBeyaz.png",
        to: "/qnbfinans-efatura",
      },
      {
        title: "Turkcell E-Fatura",
        color: "#ffc20d5e",
        logo: "/assets/eInvoice/turkcellEfaturaBeyaz.png",
        to: "/turkcell-efatura",
      },
    ],
  };

  return (
    <>
      <InnerHeader
        firstPageLink="/"
        secondPageName="Ayarlar"
        thirdPageName="E-Fatura Ayarları"
        thirdPageLink="/efatura"
      />
      <Row style={{ padding: 32 }} gutter={[24, 24]}>
        {items.active.map((item, index) => (
          <Col key={index}>
            <ImageCard
              title={item.title}
              src={item.logo}
              color={item.color}
              to={"/efatura" + item.to}
            />
          </Col>
        ))}
        <Divider orientation="left">
          <Title level={3}>Çok Yakında</Title>
        </Divider>
        {items.comingSoon.map((item, index) => (
          <Col key={index}>
            <ImageCard
              title={item.title}
              src={item.logo}
              color={item.color}
              to={item.to}
              comingSoon={true}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default EInvoiceServicesPage;
