import { Col, Row, Tabs } from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import ApiSettingsCard from "../../components/settings/ApiSettingsCard";
import LogoEFaturaApiForm from "../../components/forms/settings/LogoEFaturaApiForm";

const LogoEFaturaSettingsPage = () => {
  const items = [
    {
      label: "API Tanımlama",
      key: "apiSettings",
      children: (
        <ApiSettingsCard
          serviceName="logoefatura"
          apiFormComponent={LogoEFaturaApiForm}
        />
      ),
    },
  ];

  return (
    <>
      <InnerHeader
        firstPageName="Ayarlar"
        secondPageName="E-Fatura Ayarları"
        secondPageLink="/efatura"
        thirdPageName="Logo E-Fatura Ayarlar"
      />
      <Row justify="center" style={{ padding: "16px 32px 32px 32px" }}>
        <Col span={24}>
          <Tabs defaultActiveKey="apiSettings" items={items} />
        </Col>
      </Row>
    </>
  );
};

export default LogoEFaturaSettingsPage;
