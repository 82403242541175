import { Col, Row, Image, Tooltip, Button, Typography } from "antd";
import { QuestionCircleFilled, ApiFilled } from "@ant-design/icons";

const { Text } = Typography;

interface IProductTransferCardProps {
  logo?: any;
  onClick?: () => void;
  refreshData?: () => void;
}

const ProductTransferCard = (props: IProductTransferCardProps) => {
  return (
    <Row style={{ padding: "18px 0" }}>
      <Col
        span={6}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image style={{ height: 85 }} preview={false} src={props.logo} />
      </Col>
      <Col span={18}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <Text style={{ fontSize: 15, color: "black", paddingRight: 10 }}>
              Ürün Aktarımları
            </Text>
            <Tooltip title="Platformda yer alan ürünlerinizi Entekas panelinize aktarmak için Ürünleri Aktar butonunu kullanabilirsiniz.">
              <QuestionCircleFilled style={{ fontSize: 14 }} />
            </Tooltip>
          </Col>
          <Col span={24}>
            <Button
              type="primary"
              className="customPrimaryButton"
              icon={<ApiFilled />}
              onClick={props.onClick}
            >
              ÜRÜNLERİ GETİR
            </Button>
          </Col>
          <Col span={24}>
            <Button className="customPrimaryButton" onClick={props.refreshData}>
              Aktarım Durumunu Güncelle
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ProductTransferCard;
