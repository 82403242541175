import { Col, Row, Grid, Image } from "antd";
import { colors } from "../../theme";

interface IAuthLayoutProps {
  children: any;
  logoSrc: string;
  loginBackgroundSrc: string;
}

const AuthLayout = (props: IAuthLayoutProps) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        position: "relative",
        background: "url(./assets/auth-bg.jpg)",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <div style={{ height: "50%", width: "100%", position: "absolute" }}>
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            backgroundColor: "rgba(64, 81, 137, 0.5)",
          }}
        ></div>

        <canvas
          className="particles-js-canvas-el"
          width="1374"
          height="380"
          style={{ width: "100%", height: "100%" }}
        ></canvas>
        <div
          style={{
            backgroundImage: "url(" + props.loginBackgroundSrc + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            height: "100%",
            width: "100%",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            maxHeight: "50%",
            bottom: -1,
            left: 0,
            width: "50%",
            height: screens.sm ? "100px" : "40px",
            backgroundColor: "white",
            zIndex: 3,
            clipPath: "polygon(0 100%, 100% 100%, 0 0)",
          }}
        >
          <div
            style={{
              position: "absolute",
              maxHeight: "100%",
              bottom: 0,
              left: 0,
              width: "100%",
              height: screens.sm ? "100px" : "40px",
              background: "url(./assets/auth-bg.jpg)",
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
              zIndex: 3,
              clipPath: "polygon(0 100%, 100% 100%, 0 0)",
            }}
          ></div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: -1,
            right: 0,
            width: "50%",
            height: screens.sm ? "100px" : "40px",
            backgroundColor: "white",
            zIndex: 3,
            clipPath: "polygon(100% 100%, 0 100%, 100% 0)",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "100%",
              height: screens.sm ? "100px" : "40px",
              background: "url(./assets/auth-bg.jpg)",
              backgroundAttachment: "fixed",
              backgroundSize: "cover",
              zIndex: 3,
              clipPath: "polygon(100% 100%, 0 100%, 100% 0)",
            }}
          ></div>
        </div>
      </div>
      <Row
        gutter={[0, 0]}
        style={{ height: "100%", width: "100%" }}
        justify="center"
      >
        <Col
          span={24}
          style={{
            zIndex: 99,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={props.logoSrc}
            style={{
              width: 200,
              height: "auto",
            }}
            preview={false}
          />
        </Col>
        <Col
          span={24}
          style={{
            height: "fit-content",
            backgroundColor: colors.white,
            justifyContent: "center",
            alignContent: "center",
            maxWidth: screens.sm ? 475 : "94%",
            zIndex: 99,
            border: "1px solid #ced4da",
            borderRadius: 8,
            boxShadow: "0 1px 2px rgba(56, 65, 74, 0.15)",
          }}
        >
          {props.children}
        </Col>
      </Row>
    </div>
  );
};

export default AuthLayout;
