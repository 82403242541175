import { Col, Form, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { useEffect, useState } from "react";

interface IAttributeSetFormProps {
  name: string | number;
  attributeGroups: any[];
  form: FormInstance;
}

const AttributeSetForm = (props: IAttributeSetFormProps) => {
  const [values, setValues] = useState([]);
  const attributeGroupIri = useWatch(
    ["productAttributes", props.name, "attributeGroup"],
    props.form
  );

  useEffect(() => {
    const selectedAttributeGroups = props.attributeGroups.filter(
      (attributeGroup: any) => attributeGroup["@id"] === attributeGroupIri
    );

    if (selectedAttributeGroups.length === 0) {
      setValues([]);
      return;
    }

    setValues(selectedAttributeGroups[0].attributeValues);
  }, [props.attributeGroups, attributeGroupIri]);

  return (
    <>
      <Col span={11}>
        <Form.Item name={[props.name, "attributeGroup"]}>
          <Select
            disabled
            options={props.attributeGroups}
            onSelect={() => {
              props.form.resetFields([
                ["productAttributes", props.name, "attributeValue"],
              ]);
            }}
          />
        </Form.Item>
      </Col>
      <Col span={11}>
        <Form.Item name={[props.name, "attributeValue"]}>
          <Select disabled options={values} />
        </Form.Item>
      </Col>
    </>
  );
};

export default AttributeSetForm;
