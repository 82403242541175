import {
  Divider,
  Input,
  Row,
  Typography,
  Form,
  Button,
  FormInstance,
} from "antd";
import { colors } from "../../theme";
import { email, max, min, required } from "../../helpers/FormRuleHelper";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

interface ILoginFormProps {
  loading: boolean;
  form: FormInstance;
  handleLogin: () => void;
}

const LoginForm = (props: ILoginFormProps) => {
  return (
    <div style={{ padding: "1.5rem" }}>
      <Row justify="center">
        <Title level={3} style={{ color: colors.primary, marginTop: 0 }}>
          Tekrar Hoşgeldin
        </Title>
      </Row>
      <Row justify="center">
        <Text style={{ textAlign: "center" }}>
          Lütfen giriş bilgilerinizi giriniz
        </Text>
      </Row>
      <Divider />
      <Form
        form={props.form}
        style={{ width: "100%", paddingBottom: 20 }}
        layout="vertical"
        size="large"
        autoComplete="off"
      >
        <Form.Item
          name="email"
          label="E-POSTA"
          rules={[required, email, max(255)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="ŞİFRE"
          rules={[required, min(2), max(255)]}
        >
          <Input type="password" />
        </Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          loading={props.loading}
          onClick={() => props.handleLogin()}
        >
          Giriş Yap
        </Button>
      </Form>
      <Row justify="center">
        <Text style={{ textAlign: "center" }}>
          Hesabınız yok mu? <Link to={"/kayit-ol"}>Kaydol</Link>
        </Text>
      </Row>
    </div>
  );
};

export default LoginForm;
