import { Col, Row, Select, Spin } from "antd";
import debounce from "lodash.debounce";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

interface ISearchSelectProps {
  placeholder?: string;
  style?: any;
  customSearchRequest: any;
  defaultOption?: any;
  onSelect?: (value: any, option: any) => void;
  onClear?: () => void;
}

//TODO {...props} yüzünden customSearchRequest için error veriyor
const SearchSelect = forwardRef((props: ISearchSelectProps, ref) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const debouncedCallback = debounce((searchValue) => {
    setOptions([]);
    setLoading(true);
    props.customSearchRequest(searchValue, setOptions, setLoading);
  }, 500);

  useEffect(() => {
    props.defaultOption && setOptions([props.defaultOption]);
  }, [props.defaultOption]);

  useImperativeHandle(ref, () => ({ setOptions: setOptions }));

  return (
    <Select
      {...props}
      allowClear
      showSearch
      options={options}
      filterOption={false}
      onSearch={debouncedCallback}
      placeholder={props.placeholder}
      onSelect={props.onSelect}
      onClear={props.onClear}
      notFoundContent={loading ? <Spin size="small" /> : "Bulunamadı."}
      optionRender={(option) => (
        <Row>
          <Col span={24}>{option.data.name}</Col>
          <Col span={24}>{option.data.path}</Col>
        </Row>
      )}
    />
  );
});

export default SearchSelect;
