import dayjs from "dayjs";
import moment from "moment";

export const getConstantValue = (value: any, constants?: any[]) => {
  const findedConstant = constants?.find((constant) => {
    return constant.value === value;
  });

  return findedConstant ? findedConstant.label : "";
};

export const getDateFromString = (value: string) => {
  return value ? moment(new Date(value).getTime()) : undefined;
};

export const formattedDate = (value: string, format?: string) => {
  return dayjs(value).format(format ?? "DD.MM.YYYY");
};

export const getUploadUrl = (uid?: string) => {
  return uid ? process.env.REACT_APP_UPLOAD_URL + uid : "assets/default.jpg";
};

export const getOptionsFromEntities = (
  entityArray: any,
  labelName = "name",
  valueName = "@id"
) => {
  return entityArray.map((entity: any) => {
    return {
      id: entity["id"],
      label: entity[labelName],
      value: entity[valueName],
    };
  });
};
