import { Col, Row, Tabs } from "antd";
import InnerHeader from "../../components/layouts/InnerHeader";
import TrendyolApiForm from "../../components/forms/settings/TrendyolApiForm";
import ApiSettingsCard from "../../components/settings/ApiSettingsCard";
import ImportProcessCard from "../../components/settings/ImportProcessCard";
import { ServiceIconMatchList } from "../../helpers/ServiceIconMatchHelper";
import TrendyolImportForm from "../../components/forms/settings/TrendyolImportForm";

const TrendyolSettingsPage = () => {
  const items = [
    {
      label: "API Tanımlama",
      key: "apiSettings",
      children: (
        <ApiSettingsCard
          serviceName="trendyol"
          apiFormComponent={TrendyolApiForm}
        />
      ),
    },
    {
      label: "Ürün Aktarımı",
      key: "importing",
      children: (
        <ImportProcessCard
          serviceName="trendyol"
          logo={ServiceIconMatchList.trendyol}
          importForm={TrendyolImportForm}
        />
      ),
    },
  ];

  return (
    <>
      <InnerHeader
        firstPageName="Ayarlar"
        secondPageName="Mağaza Ayarları"
        secondPageLink="/pazaryeri"
        thirdPageName="Trendyol Ayarlar"
      />
      <Row justify="center" style={{ padding: "16px 32px 32px 32px" }}>
        <Col span={24}>
          <Tabs defaultActiveKey="apiSettings" items={items} />
        </Col>
      </Row>
    </>
  );
};

export default TrendyolSettingsPage;
