import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FeaturesPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");
    if (!reloaded) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      sessionStorage.removeItem("reloaded");
      window.scrollTo(-1000, -1000);
    }
  }, []);

  useEffect(() => {
    // Sayfa her yüklendiğinde en üste kaydır
    window.scrollTo(-1000, -1000);
  }, []);
  return (
    <>
      {/* <!-- Page Banner Start --> */}
      <section
        className="page-banner-area 
        py-250 rpy-120 rel z-1 bgs-cover text-center"
        style={{
          backgroundImage: "url(/assets/landingPage/bannerBg/newblueBg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="banner-inner pt-70 rpt-60">
            <h1
              className="page-title"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              Tüm Özellikler
            </h1>
            <h4 style={{ paddingBottom: 50 }}>
              Tüm özelliklerimizi kolaylıkla yönetin.
            </h4>

            <a onClick={() => navigate("/kayit-ol")} className="theme-btn">
              ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </section>
      {/* <!-- Page Banner End --> */}

      {/* <!-- Project List Area Start --> */}
      <section className="project-list-area py-130 rpy-100">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9 col-lg-11">
              <div
                className="section-title mb-60"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <h2>En Güçlü Özellikler</h2>
              </div>
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/1.png"
                alt="Project List"
                onClick={() => navigate("/ozellikler/akilli-urun-katalogu")}
              />
            </div>
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              {/* <a className="category" href="#">
                Design, Branding
              </a> */}
              <h2>
                <a onClick={() => navigate("/ozellikler/akilli-urun-katalogu")}>
                  Akıllı Ürün Kataloğu
                </a>
              </h2>
              <p>
                Entekas'ın Akıllı Ürün Kataloğu özelliği, çevrimiçi
                pazaryerlerindeki ürünlerinizi tek bir yerden kolayca
                yönetmenize olanak tanır. Bu özellik sayesinde, ürünlerinizi
                listelemek, stokları takip etmek ve fiyatları güncellemek çok
                daha hızlı ve basit olur.
              </p>
              <a
                onClick={() => navigate("/ozellikler/akilli-urun-katalogu")}
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              {/* <a className="category" href="#">
                Design, Branding
              </a> */}
              <h2>
                <a onClick={() => navigate("/ozellikler/siparis-yonetimi")}>
                  Sipariş Yönetimi
                </a>
              </h2>
              <p>
                Çeşitli çevrimiçi pazaryerlerinden gelen siparişleri tek bir
                merkezi yerden yönetmenizi sağlayan kapsamlı bir araçtır. Bu
                özellik sayesinde, işletmenizin pazaryerlerinde gelen
                siparişleri izlemek, işlemek ve yönetmek çok daha kolay hale
                gelir.
              </p>
              <a
                onClick={() => navigate("/ozellikler/siparis-yonetimi")}
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/2.png"
                alt="Project List"
                onClick={() => navigate("/ozellikler/siparis-yonetimi")}
              />
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/3.png"
                alt="Project List"
                onClick={() => navigate("/ozellikler/akilli-urun-ekleme")}
              />
            </div>
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              {/* <a className="category" href="#">
                Design, Branding
              </a> */}
              <h2>
                <a onClick={() => navigate("/ozellikler/akilli-urun-ekleme")}>
                  Akıllı Ürün Ekleme
                </a>
              </h2>
              <p>
                İşletmenizin ürünleri pazaryerlerine hızlı ve kolay bir şekilde
                eklemenize olanak tanır. Bu özellik sayesinde, herhangi bir
                pazaryerinde gördüğünüz ürünün sadece linkini kopyalayıp
                yapıştırarak farklı pazaryerlerinde aynı anda satışa
                sunabilirsiniz.
              </p>
              <a
                onClick={() => navigate("/ozellikler/akilli-urun-ekleme")}
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              {/* <a className="category" href="#">
                Design, Branding
              </a> */}
              <h2>
                <a onClick={() => navigate("/ozellikler/esnek-raporlar")}>
                  Esnek Raporlar
                </a>
              </h2>
              <p>
                İşletmenizin pazaryerlerindeki performansını izlemek için
                özelleştirilebilir raporlar oluşturmanızı sağlar. Bu özellik
                sayesinde, satışlarınızı, envanter durumunuzu, müşteri
                davranışlarını ve diğer önemli verileri istediğiniz şekilde
                analiz edebilirsiniz.
              </p>
              <a
                onClick={() => navigate("/ozellikler/esnek-raporlar")}
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/4.png"
                alt="Project List"
                onClick={() => navigate("/ozellikler/esnek-raporlar")}
              />
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/5.png"
                alt="Project List"
                onClick={() => navigate("/ozellikler/stok-yonetimi")}
              />
            </div>
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              {/* <a className="category" href="#">
                Design, Branding
              </a> */}
              <h2>
                <a onClick={() => navigate("/ozellikler/stok-yonetimi")}>
                  Stok Yönetimi
                </a>
              </h2>
              <p>
                İşletmenizin çevrimiçi pazaryerlerindeki aynı ürün fakat farklı
                varyasyonlardaki ürünleri etkili bir şekilde izlemenizi ve
                yönetmenizi sağlar. Bu özellik sayesinde, stok seviyelerinizi
                gerçek zamanlı olarak takip edebilir, stok azaldığında otomatik
                uyarılar alabilir ve stok güncellemelerini pazaryerlerine anında
                yansıtabilirsiniz.
              </p>
              <a
                onClick={() => navigate("/ozellikler/stok-yonetimi")}
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <h2>
                <a onClick={() => navigate("/ozellikler/toplu-urun-aktarimi")}>
                  Toplu Ürün Aktarımı
                </a>
              </h2>
              <p>
                İşletmenizin birden fazla ürünü aynı anda çeşitli pazaryerlerine
                aktarmanızı sağlar. Bu özellik sayesinde, ürünlerinizi tek tek
                manuel olarak listeleme zahmetinden kurtulur ve zaman
                kazanırsınız.
              </p>
              <a
                onClick={() => navigate("/ozellikler/toplu-urun-aktarimi")}
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/6.png"
                alt="Project List"
                onClick={() => navigate("/ozellikler/toplu-urun-aktarimi")}
              />
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/7.png"
                alt="Project List"
                onClick={() => navigate("/ozellikler/erp-entegrasyonu")}
              />
            </div>
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <h2>
                <a onClick={() => navigate("/ozellikler/erp-entegrasyonu")}>
                  ERP Entegrasyonu
                </a>
              </h2>
              <p>
                İşletmenizin mevcut ERP sistemini pazaryerleriyle entegre
                etmenizi sağlar. Bu özellik sayesinde, siparişler, envanter
                güncellemeleri ve diğer işlemler otomatik olarak senkronize
                edilir, böylece işletmenizin verimliliği artar ve operasyonel
                süreçleriniz daha iyi yönetilir.
              </p>
              <a
                onClick={() => navigate("/ozellikler/erp-entegrasyonu")}
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <h2>
                <a onClick={() => navigate("/ozellikler/e-fatura")}>E-fatura</a>
              </h2>
              <p>
                İşletmenizin çevrimiçi pazaryerlerinde gerçekleşen satış
                işlemlerini hızlı ve otomatik bir şekilde faturalandırmanıza
                olanak tanıyan kullanışlı bir özelliktir.
              </p>
              <a
                onClick={() => navigate("/ozellikler/e-fatura")}
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/8.png"
                alt="Project List"
                onClick={() => navigate("/ozellikler/e-fatura")}
              />
            </div>
          </div>
          <div className="project-item style-two">
            <div
              className="image"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <img
                src="/assets/landingPage/featurePageLogos/9.png"
                alt="Project List"
                onClick={() =>
                  navigate("/ozellikler/tedarikci-xml-entegrasyonu")
                }
              />
            </div>
            <div
              className="content"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <h2>
                <a
                  onClick={() =>
                    navigate("/ozellikler/tedarikci-xml-entegrasyonu")
                  }
                >
                  Tedarikçi XML Entegrasyonu
                </a>
              </h2>
              <p>
                İşletmenizin çevrimiçi pazaryerlerine daha karmaşık ve
                özelleştirilmiş veri aktarımı sağlar. Bu özellik sayesinde, XML
                formatında veri alışverişi yaparak, işletmenizin
                pazaryerlerindeki entegrasyon süreçlerini daha esnek ve verimli
                hale getirebilirsiniz.
              </p>
              <a
                onClick={() =>
                  navigate("/ozellikler/tedarikci-xml-entegrasyonu")
                }
                className="details-btn"
              >
                <i className="far fa-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Project List Area End --> */}
    </>
  );
};

export default FeaturesPage;
