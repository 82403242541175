import { Col, Form, Row, Select } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { CloseCircleFilled } from "@ant-design/icons";

interface IOutTransferFormProps {
  form: any;
  transferErrors: string[];
  selectedKeys: any[];
}

const tranferServiceOptions = [
  {
    label: "Trendyol",
    value: "trendyol",
  },
  {
    label: "Dinazors",
    value: "dinazors",
  },
  //   {
  //     label: "Hepsiburada",
  //     value: "hepsiburada",
  //   },
  //   {
  //     label: "N11",
  //     value: "n11",
  //   },
  //   {
  //     label: "Çiçeksepeti",
  //     value: "ciceksepeti",
  //   },
];

const OutTransferForm = (props: IOutTransferFormProps) => {
  return (
    <>
      <Form form={props.form} layout="vertical">
        <Form.Item
          label="Gönderim Sağlanacak Servis"
          name="serviceName"
          rules={[required]}
        >
          <Select options={tranferServiceOptions} />
        </Form.Item>
        <Form.Item
          label="Gönderim Sağlanacak Ürünler"
          name="productIds"
          rules={[required]}
        >
          <Select
            options={[
              { label: "Tümünü Aktar", value: "all" },
              {
                label:
                  "Seçilenleri Aktar (" + props.selectedKeys.length + " Adet)",
                value: (props.selectedKeys ?? []).join(","),
              },
            ]}
          />
        </Form.Item>
      </Form>
      <Row>
        {props.transferErrors.map((transferError, index) => (
          <Col key={index} span={24}>
            <CloseCircleFilled style={{ color: "red" }} /> {transferError}
          </Col>
        ))}
      </Row>
    </>
  );
};

export default OutTransferForm;
