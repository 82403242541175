import { Col, Spin, Collapse, Space, Alert, Tag } from "antd";
import { useEffect, useState } from "react";
import { colors } from "../../theme";
import { GeneralApi, XmlApi } from "../../client/Api";
import { getConstantValue } from "../../helpers/UtilHelper";

const XmlImportRecords = () => {
  const [loading, setLoading] = useState(false);
  const [imports, setImports] = useState<any[]>([]);
  const [constants, setConstants] = useState<any>();

  useEffect(() => {
    GeneralApi.getConstants().then((response) => setConstants(response));
  }, []);

  useEffect(() => {
    setLoading(true);
    XmlApi.getXmlTracks()
      .then((response) =>
        setImports(
          response["hydra:member"].map((xpt: any, index: number) => ({
            key: index,
            label: (
              <Space>
                <Tag>{xpt.id}</Tag>
                <Tag>
                  {getConstantValue(
                    xpt.status,
                    constants?.xmlProcessTrackStatus
                  )}
                </Tag>
              </Space>
            ),
            children: (
              <Space direction="vertical">
                {xpt.logs.map((log: any, ind: number) => (
                  <Alert key={ind} message={log.message} type={log.type} />
                ))}
              </Space>
            ),
          }))
        )
      )
      .finally(() => setLoading(false));
  }, [constants]);

  return (
    <Col span={24} style={{ backgroundColor: colors.white, padding: 24 }}>
      {loading ? <Spin /> : <Collapse items={imports} />}
    </Col>
  );
};

export default XmlImportRecords;
