import { Navigate, Outlet } from "react-router-dom";
import { isLoggedIn } from "../../helpers/AuthHelper";

interface IPrivateRouteProps {
  loginPath?: string;
  layout?: any;
}

const PrivateRoute = (props: IPrivateRouteProps) => {
  return isLoggedIn() ? (
    props.layout ? (
      <props.layout>
        <Outlet />
      </props.layout>
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={props.loginPath ?? "/login"} />
  );
};

export default PrivateRoute;
