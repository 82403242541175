import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../helpers/AuthHelper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Grid } from "antd";

const { useBreakpoint } = Grid;

const HomePage = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const [selectedTab, setSelectedTab] = useState("tabFour1");

  const handleTabChange = (tabId: any) => {
    setSelectedTab(tabId);
  };

  const getImageSrc = () => {
    switch (selectedTab) {
      case "tabFour1":
        return "/assets/landingPage/homePageLogos/pazaryerleri-2.png";
      case "tabFour2":
        return "/assets/landingPage/homePageLogos/muhasebe.png";
      case "tabFour3":
        return "/assets/landingPage/homePageLogos/e-fatura.png";
      default:
        return "/assets/landingPage/homePageLogos/pazaryerleri-2.png";
    }
  };

  return (
    <>
      {/* <!-- Hero area start --> */}
      <section className="hero-area-five py-130 rpy-100 rel z-1">
        <div className="container">
          <div
            className="hero-content style-five text-center"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="50"
          >
            <h1
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              İşlerinizi Kolaylaştıracak Entegrasyonlarla
            </h1>
            <span
              className="subtitle-one mb-20"
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <i className="fas fa-rocket-launch"></i> Bir tık uzağınızdayız
            </span>
            <div
              className="row justify-content-center pt-5 rpt-0 pb-25"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500"
              data-aos-offset="50"
            ></div>
            {isLoggedIn() ? (
              <a onClick={() => navigate("/dashboard")} className="theme-btn">
                YÖNETİM PANELİ <i className="far fa-arrow-right"></i>
              </a>
            ) : (
              <a onClick={() => navigate("/kayit-ol")} className="theme-btn">
                ÜCRETSİZ DENE <i className="far fa-arrow-right"></i>
              </a>
            )}
          </div>
        </div>
        <div className="hero-bg-wave-shapes">
          <span className="circle"></span>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Hero area End --> */}

      {/* <!-- Hero Image Part End --> */}
      <div
        className="hero-image-area rel z-1"
        data-aos="zoom-in-up"
        data-aos-duration="1500"
        data-aos-offset="50"
      >
        <div className="container container-1370">
          <img src="/assets/landingPage/dashboardSS.png" alt="Hero" />
        </div>
      </div>
      {/* <!-- Hero Image Part End --> */}

      {/* <!-- Client Logos Area Start --> */}
      <section className="client-logo-area-five pt-125 rpt-105 rel z-1">
        <div className="container">
          <div
            className="section-title text-center mb-50"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="50"
          >
            <h4>
              Dinazors ve <span>+10</span> Firma İle Entegrasyonumuz Tamamlandı
            </h4>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-2 col-lg-3 col-md-4 col-6">
              <div
                className="client-logo-item"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <a href="#">
                  <img
                    src="/assets/landingPage/homePageLogos/dinazors_logo.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-6">
              <div
                className="client-logo-item"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <a href="#">
                  <img
                    src="/assets/landingPage/homePageLogos/trendyol_logo.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-6">
              <div
                className="client-logo-item"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <a href="#">
                  <img
                    src="/assets/landingPage/homePageLogos/amazon_logo.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-6">
              <div
                className="client-logo-item"
                data-aos="fade-up"
                data-aos-delay="150"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <a href="#">
                  <img
                    src="/assets/landingPage/homePageLogos/hepsiburada_logo.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-4 col-6">
              <div
                className="client-logo-item"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <a href="#">
                  <img
                    src="/assets/landingPage/homePageLogos/ciceksepeti_logo.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div>
            {/* <div className="col-xl-2 col-lg-3 col-md-4 col-6">
              <div
                className="client-logo-item"
                data-aos="fade-up"
                data-aos-delay="250"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <a href="#">
                  <img
                    src="/assets/landingPage/homePageLogos/trendyol.png"
                    alt="Client Logo"
                  />
                </a>
              </div>
            </div> */}
          </div>
        </div>

        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Client Logos Area End --> */}

      {/* <!-- Feature Image box Area Start --> */}
      <section className="feature-image-box-area pt-95 rpt-75 pb-70 rpb-50 rel z-1">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 col-md-11">
              <div
                className="section-title text-center mb-55"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <span className="subtitle-one style-two mb-20">
                  <i className="fas fa-rocket-launch"></i> En Güncel Entegratör
                  Hizmetleri
                </span>
                <h2>
                  Pazaryerlerindeki Varlığınızı Güçlendirin, Entekas'la Büyük
                  Kazançlar Elde Edin!
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div
                className="feature-image-box"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/homePageLogos/triple1.png"
                    alt="Feature"
                    style={{ borderRadius: 10 }}
                  />
                </div>
                <div className="content">
                  <h5 style={{ fontSize: 19 }}>
                    <a href="">Pazarlama Stratejilerini Güçlendirin:</a>
                  </h5>
                  <p>
                    Entegrasyonlarımız, pazarlama stratejilerinizi optimize
                    ederek daha etkili bir şekilde hedef kitleye ulaşmanızı
                    sağlar.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="feature-image-box"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/homePageLogos/triple2.png"
                    alt="Feature"
                    style={{ borderRadius: 10 }}
                  />
                </div>
                <div className="content">
                  <h5 style={{ fontSize: 19 }}>
                    <a href="">Veriye Dayalı Kararlar Alın:</a>
                  </h5>
                  <p>
                    Entegrasyonlarımız, işletmenizin performansını izlemenize ve
                    veriye dayalı kararlar almanıza yardımcı olur, böylece
                    satışlarınızı artırabilirsiniz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div
                className="feature-image-box"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="image">
                  <img
                    src="/assets/landingPage/homePageLogos/triple3.png"
                    alt="Feature"
                    style={{ borderRadius: 10 }}
                  />
                </div>
                <div className="content">
                  <h5 style={{ fontSize: 19 }}>
                    <a href="">Sistemleri Entegre Edin:</a>
                  </h5>
                  <p>
                    Entegrasyonlarımız, işletmenizin farklı sistemlerini
                    birleştirerek verimliliği artırır ve satışlarınızı
                    kolaylaştırır.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Feature Image box Area End --> */}

      {/* <!-- Tab Area Start --> */}
      <section className="tab-area-five bgc-black text-white pt-120 rpt-100 pb-80 rpb-60 rel z-1">
        <div className="container">
          <div className="row gap-100 align-items-center justify-content-center">
            <div className="col-lg-6">
              <div
                className="tap-five-content rmb-55"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title pt-10 mb-55">
                  <span className="subtitle-one style-three mb-20">
                    <i className="fas fa-rocket-launch"></i> Her Yerde Sat!{" "}
                  </span>
                  <h2>Pazaryeri Entegrasyonunuzun Yenilikçi Çözümü</h2>
                </div>

                <ul
                  className="nav advanced-tab style-four mb-40"
                  role="tablist"
                >
                  <li>
                    <button
                      className={`nav-link ${
                        selectedTab === "tabFour1" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("tabFour1")}
                    >
                      Pazaryerleri
                    </button>
                  </li>
                  <li>
                    <button
                      className={`nav-link ${
                        selectedTab === "tabFour2" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("tabFour2")}
                    >
                      Muhasebe
                    </button>
                  </li>
                  <li>
                    <button
                      className={`nav-link ${
                        selectedTab === "tabFour3" ? "active" : ""
                      }`}
                      onClick={() => handleTabChange("tabFour3")}
                    >
                      E-Fatura
                    </button>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className={`tab-pane fade ${
                      selectedTab === "tabFour1" ? "show active" : ""
                    }`}
                    id="tabFour1"
                  >
                    <p>
                      Kullanımı en kolay pazaryeri entegrasyon çözümü ile her
                      yerde satış yapın.
                    </p>
                    <div className="feature-icon-box text-white mt-30">
                      <div className="icon">
                        <i className="far fa-check"></i>
                      </div>
                      <div className="content">
                        <h5>Ürün yönetimi</h5>
                        <p>
                          Entekas, kullanıcıların birden fazla pazaryerindeki
                          ürünlerini tek bir platform üzerinden yönetmelerini
                          sağlar. Kullanıcılar, ürünlerini kolayca ekleyebilir,
                          güncelleyebilir ve kaldırabilirler. Böylece farklı
                          pazaryerlerindeki envanterlerini etkin bir şekilde
                          yönetebilirler.
                        </p>
                      </div>
                    </div>
                    <div className="feature-icon-box text-white">
                      <div className="icon">
                        <i className="far fa-check"></i>
                      </div>
                      <div className="content">
                        <h5>Sipariş yönetimi</h5>
                        <p>
                          Entekas, farklı pazaryerlerinden gelen siparişleri tek
                          bir yerden yönetmeyi sağlar. Kullanıcılar, siparişleri
                          görüntüleyebilir, işleyebilir ve takip edebilirler.
                          Ayrıca, sipariş durumlarını güncelleyebilir ve
                          müşterilere hızlı ve verimli bir şekilde yanıt
                          verebilirler. Bu, kullanıcıların pazaryerlerindeki
                          satışlarını kolaylıkla izlemelerini ve yönetmelerini
                          sağlar.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      selectedTab === "tabFour2" ? "show active" : ""
                    }`}
                    id="tabFour2"
                  >
                    <p>
                      Satış kanallarınızdan gelen siparişler Muhasebe/ERP
                      yazılımınıza otomatik aktarılsın.
                    </p>
                    <div className="feature-icon-box text-white mt-30">
                      <div className="icon">
                        <i className="far fa-check"></i>
                      </div>
                      <div className="content">
                        <h5>Fatura Otomasyonu</h5>
                        <p>
                          Entekas, satış yapılan pazaryerlerinden gelen fatura
                          bilgilerini otomatik olarak muhasebe sistemine
                          aktarır. Bu entegrasyon sayesinde manuel olarak fatura
                          bilgilerini girmek yerine, sistem doğrudan fatura
                          detaylarını alır ve muhasebe kayıtlarını oluşturur. Bu
                          işlem hataları azaltır, zaman kazandırır ve iş akışını
                          hızlandırır.
                        </p>
                      </div>
                    </div>
                    <div className="feature-icon-box text-white">
                      <div className="icon">
                        <i className="far fa-check"></i>
                      </div>
                      <div className="content">
                        <h5>Stok ve Gelir Takibi</h5>
                        <p>
                          Entekas, pazaryerlerindeki satışlara göre stok
                          hareketlerini ve gelirleri muhasebe sistemine entegre
                          eder. Satışlar gerçekleştiğinde, stok düşüşü otomatik
                          olarak kaydedilir ve gelirler ilgili hesaplara
                          yönlendirilir. Böylece işletme stok durumunu ve
                          finansal performansını daha kolay bir şekilde takip
                          edebilir, raporlayabilir ve analiz edebilir.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade ${
                      selectedTab === "tabFour3" ? "show active" : ""
                    }`}
                    id="tabFour3"
                  >
                    <p>
                      Tüm satış kanallarınızdan gelen siparişlerin e-fatura
                      süreçlerini kolaylıkla yönetin.
                    </p>
                    <div className="feature-icon-box text-white mt-30">
                      <div className="icon">
                        <i className="far fa-check"></i>
                      </div>
                      <div className="content">
                        <h5>
                          E-Fatura Entegrasyonu ile Kolay Fatura İşlemleri
                        </h5>
                        <p>
                          Entekas, pazaryeri satıcıları için E-Fatura
                          entegrasyonu sağlar. Bu entegrasyon sayesinde,
                          satıcılar pazaryerinde gerçekleştirdikleri satışlara
                          ait fatura işlemlerini kolaylıkla
                          gerçekleştirebilirler. Entekas'ın E-Fatura
                          entegrasyonu sayesinde fatura oluşturma, gönderme ve
                          saklama süreçleri otomatikleştirilir, böylece
                          işletmelerin zaman ve kaynak tasarrufu yapmalarına
                          olanak tanır.
                        </p>
                      </div>
                    </div>
                    <div className="feature-icon-box text-white">
                      <div className="icon">
                        <i className="far fa-check"></i>
                      </div>
                      <div className="content">
                        <h5>Muhasebe Entegrasyonu ile Entegre Fatura Takibi</h5>
                        <p>
                          Entekas'ın E-Fatura entegrasyonu, muhasebe
                          yazılımlarıyla entegre çalışabilir. Bu sayede,
                          pazaryerindeki satışlara ait fatura bilgileri doğrudan
                          muhasebe sistemine aktarılır. Bu entegrasyon
                          sayesinde, işletmeler ayrıca muhasebe süreçlerini de
                          kolaylıkla yönetebilirler. E-Fatura entegrasyonu ile
                          gelen fatura verileri, muhasebe yazılımlarıyla
                          senkronize edilerek doğru ve güncel muhasebe kayıtları
                          tutulabilir.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img src={getImageSrc()} alt="Management" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines line-white">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Tab Area End --> */}

      {/* <!-- Feature box Area Start --> */}
      <section
        className="feature-box-area pt-125 rpt-105 pb-90 rpb-70 rel z-1"
        style={{
          backgroundColor: "#F3F7FF",
          paddingRight: 24,
          paddingLeft: 24,
        }}
      >
        <div>
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 col-md-11">
              <div
                className="section-title text-center mb-55"
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <span className="subtitle-one style-two mb-20">
                  <i className="fas fa-rocket"></i> Entekas ile Pazaryeri
                  Entegrasyonunda Gücünü Keşfet!
                </span>
                <h2>En Güçlü Özellikler</h2>
              </div>
            </div>
          </div>

          <Swiper
            slidesPerView={screens.xl ? 4 : screens.lg ? 3 : screens.sm ? 2 : 1}
            spaceBetween={30}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
            style={{ height: "100%" }}
          >
            <SwiperSlide style={{ minHeight: 500 }}>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/1.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a
                        onClick={() =>
                          navigate("/ozellikler/akilli-urun-katalogu")
                        }
                      >
                        Akıllı Ürün Kataloğu
                      </a>
                    </h5>
                    <p>
                      Entekas'ın Akıllı Ürün Kataloğu özelliği, çevrimiçi
                      pazaryerlerindeki ürünlerinizi tek bir yerden kolayca
                      yönetmenize olanak tanır. Bu özellik sayesinde,
                      ürünlerinizi listelemek, stokları takip etmek ve fiyatları
                      güncellemek çok daha hızlı ve basit olur.
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/2.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a
                        onClick={() => navigate("/ozellikler/siparis-yonetimi")}
                      >
                        Sipariş Yönetimi
                      </a>
                    </h5>
                    <p>
                      Çeşitli çevrimiçi pazaryerlerinden gelen siparişleri tek
                      bir merkezi yerden yönetmenizi sağlayan kapsamlı bir
                      araçtır. Bu özellik sayesinde, işletmenizin
                      pazaryerlerinde gelen siparişleri izlemek, işlemek ve
                      yönetmek çok daha kolay hale gelir.
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/3.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a
                        onClick={() =>
                          navigate("/ozellikler/akilli-urun-ekleme")
                        }
                      >
                        Akıllı Ürün Ekleme
                      </a>
                    </h5>
                    <p>
                      İşletmenizin ürünleri pazaryerlerine hızlı ve kolay bir
                      şekilde eklemenize olanak tanır. Bu özellik sayesinde,
                      herhangi bir pazaryerinde gördüğünüz ürünün sadece linkini
                      kopyalayıp yapıştırarak farklı pazaryerlerinde aynı anda
                      satışa sunabilirsiniz.
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/4.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a onClick={() => navigate("/ozellikler/esnek-raporlar")}>
                        Esnek Raporlar
                      </a>
                    </h5>
                    <p>
                      İşletmenizin pazaryerlerindeki performansını izlemek için
                      özelleştirilebilir raporlar oluşturmanızı sağlar. Bu
                      özellik sayesinde, satışlarınızı, envanter durumunuzu,
                      müşteri davranışlarını ve diğer önemli verileri
                      istediğiniz şekilde analiz edebilirsiniz.{" "}
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/5.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a onClick={() => navigate("/ozellikler/stok-yonetimi")}>
                        Stok Yönetimi
                      </a>
                    </h5>
                    <p>
                      İşletmenizin pazaryerlerindeki aynı ürünün fakat farklı
                      varyasyonlarını takip etmenizi ve yönetmenizi sağlar.
                      Böylece stok seviyelerinizi gerçek zamanlı olarak takip
                      edebilir, stok azaldığında otomatik uyarılar alabilir ve
                      stok güncellemelerini pazaryerlerine anında
                      yansıtabilirsiniz.
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/6.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a
                        onClick={() =>
                          navigate("/ozellikler/toplu-urun-aktarimi")
                        }
                      >
                        Toplu Ürün Aktarımı
                      </a>
                    </h5>
                    <p>
                      İşletmenizin birden fazla ürünü aynı anda çeşitli
                      pazaryerlerine aktarmanızı sağlar. Bu özellik sayesinde,
                      ürünlerinizi tek tek manuel olarak listeleme zahmetinden
                      kurtulur ve zaman kazanırsınız.
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/7.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a
                        onClick={() => navigate("/ozellikler/erp-entegrasyonu")}
                      >
                        ERP Entegrasyonu
                      </a>
                    </h5>
                    <p>
                      İşletmenizin mevcut ERP sistemini pazaryerleriyle entegre
                      etmenizi sağlar. Bu özellik sayesinde, siparişler,
                      envanter güncellemeleri ve diğer işlemler otomatik olarak
                      senkronize edilir, böylece işletmenizin verimliliği artar
                      ve operasyonel süreçleriniz daha iyi yönetilir.
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/8.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a onClick={() => navigate("/ozellikler/e-fatura")}>
                        E-fatura
                      </a>
                    </h5>
                    <p>
                      İşletmenizin çevrimiçi pazaryerlerinde gerçekleşen satış
                      işlemlerini hızlı ve otomatik bir şekilde
                      faturalandırmanıza olanak tanıyan kullanışlı bir
                      özelliktir.
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  borderRadius: 14,
                }}
              >
                <div
                  className="iconic-box style-seven"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
                >
                  <div
                    className="icon"
                    style={{
                      backgroundColor: "white",
                      height: "auto",
                      width: 120,
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    }}
                  >
                    <img
                      src="/assets/landingPage/featurePageLogos/9.png"
                      alt="Shape"
                      style={{ borderRadius: 8 }}
                    />
                  </div>
                  <div className="content">
                    <h5>
                      <a
                        onClick={() =>
                          navigate("/ozellikler/tedarikci-xml-entegrasyonu")
                        }
                      >
                        Tedarikçi XML Entegrasyonu
                      </a>
                    </h5>
                    <p>
                      İşletmenizin çevrimiçi pazaryerlerine daha karmaşık ve
                      özelleştirilmiş veri aktarımı sağlar. Bu özellik
                      sayesinde, XML formatında veri alışverişi yaparak,
                      işletmenizin pazaryerlerindeki entegrasyon süreçlerini
                      daha esnek ve verimli hale getirebilirsiniz.
                    </p>
                  </div>
                  <div className="bg">
                    <img
                      src="/assets/landingPage/images/shapes/iconic-box-seven-bg.png"
                      alt="Shape"
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Feature box Area End --> */}

      {/* <!-- About Area Five Start --> */}
      <section className="about-area-five pb-120 rpb-100 rel z-1">
        <div className="container">
          <div className="row gap-40 align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div
                className="management-images float-center my-40"
                style={{
                  paddingLeft: 0,
                  display: "flex",
                  justifyContent: "center",
                }}
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <img
                  src="/assets/landingPage/homePageLogos/anasayfa-panel.png"
                  alt="Management"
                  style={{
                    height: "auto",
                    width: "80%",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="about-content-five mt-40 rmt-15"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Basitçe Yönetin
                  </span>
                  <h2>
                    Sade ve Anlaşılabilir Panelimiz Sayesinde E-ticaret
                    İşlemlerinizi Kolayca Yönetin
                  </h2>
                </div>
                <p>
                  Entekas olarak, müşterilerimizin işletmelerini daha etkili bir
                  şekilde yönetmelerini sağlamak için gelişmiş bir yönetici
                  paneli sunuyoruz. Bu güçlü araç, e-ticaret işletmelerinin tüm
                  operasyonlarını tek bir merkezi yerden kolayca yönetmelerine
                  olanak tanır.
                </p>
                <ul className="icon-list style-two mt-40 mb-40">
                  <li>
                    <i className="fal fa-check"></i> Ürün Yönetimi
                  </li>
                  <li>
                    <i className="fal fa-check"></i> Müşteri İlişkileri Yönetimi
                  </li>
                </ul>
                <a onClick={() => navigate("hakkimizda")} className="theme-btn">
                  Detaylı Bilgi <i className="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- About Area End --> */}

      {/* <!-- FeedbackArea Start --> */}
      <section className="feedback-area-five bgc-lighter pt-125 rpt-105 pb-80 rpb-60 rel z-1">
        <div className="container">
          <div
            className="section-title text-center mb-55"
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-offset="50"
          >
            <span className="subtitle-one style-two mb-20">
              <i className="fas fa-rocket-launch"></i> Müşteri Geri Dönüşleri
            </span>
            <h2>Müşterilerimizden Gelen Geri Dönüşler</h2>
          </div>
          <div className="row gap-50">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="testimonial-item style-two">
                <div className="author">
                  <div className="image">
                    <img src="/assets/woman-avatar.png" alt="Author" />
                  </div>
                  <div className="title">
                    {/* <b>Ayşe Yılmaz /</b> CEO &amp; Founder */}
                    <b>Ayşe Yılmaz </b>
                  </div>
                </div>
                <div className="author-text">
                  Entekas, işimizi büyük ölçüde kolaylaştırdı. Pazaryerlerindeki
                  ürünlerimizi yönetmek ve stokları güncellemek için mükemmel
                  bir araç. Ekibin sunduğu destek de harika.
                </div>
                <div className="testi-footer">
                  <div className="ratting">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <span className="text">
                    <span>4.8/5</span>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-left"
              data-aos-duration="1500"
              data-aos-offset="50"
            >
              <div className="testimonial-item style-two">
                <div className="author">
                  <div className="image">
                    <img src="/assets/man-avatar.png" alt="Author" />
                  </div>
                  <div className="title">
                    <b>Mehmet Kaya </b>
                  </div>
                </div>
                <div className="author-text">
                  Entekas sayesinde pazaryerlerindeki satışlarımızda büyük bir
                  artış gördük. Ürünlerimizi daha hızlı ve verimli bir şekilde
                  yönetebiliyoruz. Teşekkürler!
                </div>
                <div className="testi-footer">
                  <div className="ratting">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </div>
                  <span className="text">
                    <span>4.7/5</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="marquee-slider-right iconic-slider-right style-two pt-40 rpt-20">
          <div className="marquee-iconic-box">
            <div className="image">
              <img
                src="/assets/landingPage/images/marquee-box/member1.png"
                alt="Member"
              />
            </div>
            <div className="content">
              <h6 className="title">Ayşe Yılmaz</h6>
              <span className="subtitle">CEO Founder</span>
            </div>
          </div>
          <div className="marquee-iconic-box">
            <div className="image">
              <img
                src="/assets/landingPage/images/marquee-box/member2.png"
                alt="Member"
              />
            </div>
            <div className="content">
              <h6 className="title">Mehmet Kaya</h6>
              <span className="subtitle">Developer</span>
            </div>
          </div>
          <div className="marquee-iconic-box">
            <div className="image">
              <img
                src="/assets/landingPage/images/marquee-box/member3.png"
                alt="Member"
              />
            </div>
            <div className="content">
              <h6 className="title">Fatma Demir</h6>
              <span className="subtitle">Businessman</span>
            </div>
          </div>
          <div className="marquee-iconic-box">
            <div className="image">
              <img
                src="/assets/landingPage/images/marquee-box/member4.png"
                alt="Member"
              />
            </div>
            <div className="content">
              <h6 className="title">Ahmet Öztürk</h6>
              <span className="subtitle">Co-founder</span>
            </div>
          </div>
          <div className="marquee-iconic-box">
            <div className="image">
              <img
                src="/assets/landingPage/images/marquee-box/member5.png"
                alt="Member"
              />
            </div>
            <div className="content">
              <h6 className="title">Zeynep Aksoy</h6>
              <span className="subtitle">UI Designer</span>
            </div>
          </div>
          <div className="marquee-iconic-box">
            <div className="image">
              <img
                src="/assets/landingPage/images/marquee-box/member6.png"
                alt="Member"
              />
            </div>
            <div className="content">
              <h6 className="title">Mustafa Şahin</h6>
              <span className="subtitle">Marketer</span>
            </div>
          </div>
        </div> */}

        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- FeedbackArea End --> */}

      {/* <!-- Work Step Area Five Start --> */}
      <section className="work-step-area-five pt-120 rpt-100 pb-100 rpb-80 rel z-1">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-xl-5 col-lg-6">
              <div
                className="about-content-five mt-10 mb-20 rmb-55"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="section-title mb-30">
                  <span className="subtitle-one style-two mb-20">
                    <i className="fas fa-rocket-launch"></i> Neden Entekas
                  </span>
                  <h2>Entegrasyon Sistemine Kayıt Olmak Sadece 2 Dakika</h2>
                </div>
                <ul className="icon-list mt-40 mb-40">
                  <li>
                    <i className="fal fa-arrow-right"></i> Kredi Kartına Gerek
                    Yoktur.
                  </li>
                </ul>
                <a onClick={() => navigate("hakkimizda")} className="theme-btn">
                  Detaylı Bilgi <i className="far fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="numbered-boxes"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="50"
              >
                <div className="numbered-box style-two">
                  <div className="number">1</div>
                  <div className="content">
                    <h5>Hesabınızı Oluşturun </h5>
                    <p>
                      Siz de Entekas ayrıcalıklarından faydalanmak için ilk adım
                      hemen hesabınızı oluşturun.
                    </p>
                  </div>
                </div>
                <div className="numbered-box style-two">
                  <div className="number">2</div>
                  <div className="content">
                    <h5>Sisteminizi Entegre Edin</h5>
                    <p>
                      Entegrasyonlarımız ile diğer tüm sistemlerinizi entegre
                      ederek dilediğiniz ayrıcalıklara ulaşın
                    </p>
                  </div>
                </div>
                <div className="numbered-box style-two">
                  <div className="number">3</div>
                  <div className="content">
                    <h5>Ve Sonuç!</h5>
                    <p>
                      Entekas'ın sunduğu ayrıcalıklar ile tüm sisteminizi tek
                      bir panelden yönetmeye başlayın!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </section>
      {/* <!-- Work Step Area End --> */}

      {/* <!--End pagewrapper--> */}
    </>
  );
};

export default HomePage;
